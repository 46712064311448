.CheckoutComponent{
    background: #FFF;
    padding: 100px 150px;
}

 @media screen and (min-width:0px) and (max-width:600px) {

 }

 @media screen and (min-width:601px) and (max-width:900px) {
    .checkoutDiv{
        display: flex;
        flex-direction: column;
    }
 }

 
.mainSearch {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.searchTop{
    width: 100%;
}