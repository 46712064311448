.shipping {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.shippingTitile {
    color: #07080C;
    font-size: 28px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
}
.minWid{
    min-width: 170px;
    margin-right: 10px;
    /* margin-top: -10px !important; */
}

.billingShop,
.shippingAddress,
.payment {
    display: flex;
    text-align: left;
    /* width: 757px; */
    width: 100%;
    padding: 24px 0px 40px 8px;
    flex-direction: column;
    align-items: flex-start;
    border-top: 2px solid #07080C;
}
.orderDetailBillShopiing{
    border-top: unset;
    margin-top: 0px !important;
}
.orderDetailQuant{
    border: none;
}
.payment {
    /* border-bottom: 2px solid #07080C; */
}
.payOrder{
    /* border-bottom: none; */ 
    padding: 34px 0px 15px 8px;
}

.billingShop {

    margin-top: 14px;
}

.billTtile {
    color: #07080C;
    /* width: 757px; */
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-family: Montserrat;
    margin-bottom: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.5px;
    text-transform: uppercase;
}

.billTtile2 {
    color: #07080C;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 18px;
}

.billTtile3 {
    color: #07080C;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.paymetnOptions {
    display: flex;
    /* width: 500px; */
    margin-bottom: 8px;
    align-items: center;
    /* padding: 24px 0px; */
}

.paymetnOptions.addon{
    justify-content: space-between;
}

.paymetnOptions > div{
    display: flex;
    align-items: center;
    gap: 12px;
}

/* .header__input__base {
    border: 1px solid rgba(97, 94, 92, 0.15) !important;
} */

.paymetnOptions {
    display: flex;
    /* width: 500px; */
    align-items: center;
    width: 80%;
    gap: 20px;
    /* justify-content: space-between; */
}


.paymetnOptionsPO {
    display: flex;
    align-items: center;
    gap: 24px;
}

.visaCode {
    color: var(--brand-colors-black, #07080C);
    /* Text md/Medium */
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 18px;
    padding-right: 20px;
}
.purchase_line{
    width: 100%;
    height: 1px;
    margin: 15px 0px;
    opacity: 0.3;
    background: rgba(97, 94, 92, 0.15);
    color:#615E5C26
}

.personDetail {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
}

.ilmorButonGrey {
    color: white;
    font-size: 13px;
    background-color: #615E5C;
    letter-spacing: 1px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    /* Text sm/Bold */
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 18px;
}

.billAddLastComp {
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.billAddLastComp.addon {

    /* width: 80% !important; */
}


.shipMethod{
    width: 100%;
    margin-top: 14px;
}

.shipMethodDropdown{
    
    margin-right: 30px;
    max-width: 411px;
    border: 1px solid rgba(97, 94, 92, 0.15);
}

.shipMethodDomestic{
    flex-direction: column;
}

.otherNoteOrder{
    padding: 0px 6px 6px 6px;
}