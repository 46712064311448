.orderCompTitile {
    color: #07080C;
    /* Display md/Bold */
    font-size: 24px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 120%;
    padding-bottom: 32px !important;
    text-transform: uppercase;
}

.orderCom {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;

}

.orderCompThank {
    color: #000;
    /* Text lg/medium */
    font-size: 16px;
    border-top: 2px solid #07080C;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 24px;
    padding-top: 32px !important;
    text-align: left;
    border-top: 2px solid #07080C;
}

.orderCompThank1 {
    color: #000;
    /* Text lg/medium */
    font-size: 16px;
    /* padding-bottom: 24px !important; */
    /* padding-top: 32px; */
    font-family: Montserrat;
    font-weight: 500;
    /* line-height: 24px; */
    text-align: left;
}


.orderCompId {
    color: #000;
    /* Text lg/bold */
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: 24px !important;
    padding-bottom: 8px !important;
    line-height: 24px;
}

.orderCompSweet {
    color: #000;
    /* Text lg/medium */
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    border-bottom: 2px solid #07080C;
    line-height: 24px;
    text-align: left;
    padding-bottom: 32px !important;
    text-align: left;
    width: 757px;
}

.ilmorButon {
    color: white;
    font-size: 13px;
    background-color: #CE0E2D;
    letter-spacing: 1px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #FFF;
    text-align: center;
    /* Text sm/Bold */
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
}

.buttonGroupOrder {
    /* width: 70%; */
    display: flex;
    padding-top: 32px !important;
    gap: 8px;
    /* justify-content: space-between; */
}

@media screen and (min-width:601px) and (max-width:900px) {
    .orderCom {
        height: 461px;

    }

}