.browseCategory__main {
  background-color: rgb(234, 234, 234);
  border-bottom: 2px solid rgba(97, 94, 92, 0.07);
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.browseCategoryContainer {
  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  width: 10px;
  font-weight: 700;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

/* .browseCategoryLabel{
    display: flex;
    margin-top: 2%;
} */

.mainTileDiv.MuiGrid-item {
  display: flex;
  box-sizing: border-box;
}
.mainTileDiv.MuiGrid-item > div {
  background-color: white;
  margin: 0px;
  width: 100%;
  display: flex;
  height: 210px;
  padding: 25px 25.333px 12px 23px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(234, 234, 234);
}
.mainTileDiv:hover .image {
  scale: 1.1;
  transition: 0.5s;
}

.borno{
  border: none !important

}

.mainTileDiv:hover .browseCategorylabel {
  color: var(--ilmorRed);
}
.mainTileDiv:hover .hover {
  background-color: #F7F7F7 !important;
}

.image {
  /* background: transparent url("../Images/load.gif") center no-repeat; */
  width: 190px;
  height: 150px;
  flex-shrink: 0;
  /* background: url(<path-to-image>), lightgray 50% / contain no-repeat; */
  mix-blend-mode: multiply;
  object-fit: contain;
}

.mainTileDiv.mainTileDivBrowseCat.MuiGrid-item > div {
  display: flex;
  align-items: baseline;
  background: #ce0e2d;
  font-size: 16px;
}

.browseCategorylabel {
  color: #06070a;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 6%;
}
