.shopComp__mainDesktop {
  display: none;
  border: 1px solid black;
}

/*============================>MOBILE CSS<========================*/

.shopComp__mainMobile {
  display: block;
  /* padding-top: 8px; */
  display: flex;
  flex-direction: row;
  /* border: 1px solid black; */
  color: white;
  margin-bottom: 13px;
  background-color: black;
  /* border: 1px solid pink; */
  font-family: Montserrat;
  height: 89px;
  padding: 15px 9px  3px 20px;
}

.category__containerMobile {
  display: flex;
  flex-direction: column;
  /* border: 1px solid palegreen; */
  /* margin-left: 5%; */
  width: 50%;
  height: auto;
}

.categoryNameMobile {
  display: flex;
  /* margin-left: 1%; */
  letter-spacing: 2px;
  font-size: 60%;
  justify-content: left;
  margin-bottom: 4px;

  align-items: center;
  /* margin: 9px 9px 4px 5px; */
  /* margin-left: 12%; */
}

.shopComp__box1Mobile {
  justify-content: center;
  letter-spacing: 1px;
  /* background: rgb(17, 50, 68); */
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
}

.shopComp__box1Mobile [class*="-control"] {
  /* background-color: rgb(15, 37, 52);
    border: 1px solid white; */
  color: white;
  border-radius: 0px;
  box-shadow: none !important;
  border: none !important;
  width: 90%;
}

.shopComp__box1Mobile [class*="-control"] > div:first-child {
  font-weight: 600 !important;
  font-size: 12px !important;
}

.shopComp__box1Tablet [class*="-control"] > div:first-child {
  font-weight: 600 !important;
  font-size: 12px !important;
}

.shopComp__box1Mobile [class*="-control"] > div:first-child  > div:first-child{
  font-weight: 600 !important;
  font-size: 12px !important;
}

.shopComp__box1Mobile [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.shopComp__box1Mobile [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.shopComp__box1Mobile [class*="-placeholder"] {
  color: black;
  font-weight: 500;
  font-size: 12px;
  display: flex;
}

.shopComp__box1Mobile [class*="-indicatorSeparator"] {
  display: none;
}
.shopComp__box1Mobile [class*="-indicatorContainer"] {
  color: red;
}

.shopComp__box1Mobile [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.shopComp__box1Mobile [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.shopComp__box1Mobile [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
}
.subcontainer__containerMobile {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
}

.subcategoryNameMobile {
  display: flex;
  /* margin-left: 6%; */
  letter-spacing: 2px;
  /* font-size: 60%; */
  justify-content: left;
  align-items: center;
  /* margin: 9px; */
  margin-left: 5%;
  margin-top: 4%;
}

.shopComp__box2Mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  /* background: rgb(17, 50, 68); */
  color: rgb(255, 255, 255);
}



.shopComp__box2Mobile [class*="-control"] {
  /* background-color: rgb(15, 37, 52); */
  border: 1px solid white;
  /* color: white; */
  width: 90%;
  border-radius: 0px;
  font-weight: 600;
  box-shadow: none !important;
  margin-top: -3%;
}


.shopComp__box2Mobile [class*="-control"] > div:first-child {
  font-weight: 600;
}
.shopComp__box2Mobile [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
  width: 90%;
}

.shopComp__box2Mobile [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.shopComp__box2Mobile [class*="-placeholder"] {
  color: black;
  font-weight: 500;
  font-size: 12px;
  display: flex;
}

.shopComp__box2Mobile [class*="-indicatorSeparator"] {
  display: none;
}
.shopComp__box2Mobile [class*="-indicatorContainer"] {
  color: red;
}
.shopComp__box2Mobile [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.shopComp__box2Mobile [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.shopComp__box2Mobile [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
}

/*=========================Tablet Css ======================================*/

.shopComp__mainTablet {
    display: flex;
    padding: 18px 28px;
    gap: 35px;
    background: #07080C;

}
.category__containerTablet {
  display: flex;
  align-items: center;
  display: flex;
  width: 290px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
}

.categoryNameTablet {
  color: #FFF;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.shopComp__box1Tablet {
  display: flex;
  height: 42px;
  padding: 16px;
  align-items: center;
  /* gap: 34px; */
  flex: 1 0 0;
}

.shopComp__box1Tablet [class*="-control"] {
  color: white;
  border-radius: 0px;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}

.shopComp__box1Tablet [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.shopComp__box1Tablet [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.shopComp__box1Tablet [class*="-placeholder"] {
  display: flex;
  width: 128px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #06070A;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.shopComp__box1Tablet [class*="-indicatorSeparator"] {
  display: none;
}
.shopComp__box1Tablet [class*="-indicatorContainer"] {
  color: red;
}

.shopComp__box1Tablet [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
  width: 90%;
}

.shopComp__box1Tablet [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.shopComp__box1Tablet [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
}
.subcontainer__containerTablet {
  display: flex;
  width: 290px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
}

.subcategoryNameTablet {
  color: #FFF;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.shopComp__box2Tablet {
  display: flex;
  height: 42px;
  padding: 5px;
  align-items: center;
  flex: 1 0 0;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #FFF);
  margin-left: 10px;
}

.shopComp__box2Tablet [class*="-control"] {
  /* background-color: rgb(15, 37, 52); */
  border: 1px solid white;
  /* color: white; */
  width: 90%;
  border-radius: 0px;
  font-weight: 600;
  box-shadow: none !important;
  /* margin-top: -3%; */
}

.shopComp__box2Tablet [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
  width: 90%;
}

.shopComp__box2Tablet [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.shopComp__box2Tablet [class*="-placeholder"] {
  display: flex;
  width: 80px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #06070A;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.shopComp__box2Tablet [class*="-indicatorSeparator"] {
  display: none;
}
.shopComp__box2Tablet [class*="-indicatorContainer"] {
  color: red;
}
.shopComp__box2Tablet [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.shopComp__box2Tablet [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.shopComp__box2Tablet [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
}
