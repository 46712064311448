.Pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  padding: 13px 49px;
  background-color: #f7f7f7;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .Pagination {
    padding: 13px 22px;
  }
}

.pageView,
.pageSort {
  margin-right: 10px;
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
}

.pageSort {
  margin-left: 3%;
}

.paginationNumber {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  width: max-content;
}

.paginationNumberBox {
  color: black;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  padding: 8px 13px;
  /* margin-left: 4px; */
  background-color: rgba(209, 209, 208, 1);
  line-height: 120%;
}

.paginationNumberBox:hover > span {
  background-color: #900a1f !important;
  background-color: rgba(3, 4, 2, 1) !important;
}

.inactiveOption {
  cursor: pointer;
}

.activeOption .catogeryIcon rect {
  fill: #ce0e2d !important;
}

.activeOption .catogeryIcon path {
  fill: white !important;
}

.activeOption .listIcon rect {
  fill: #ce0e2d !important;
  opacity: 1;
}

.activeOption .listIcon path {
  fill: white !important;
}
.paginantionHeaderSelect [class*="-control"],
.header__select [class*="-control"]:hover,
.header__select [class*="-control"]:focus {
  border: 1px solid #e8e7e7 !important;
}
.catogeryIconWrapper:hover .catogeryIcon rect {
  fill: #900a1f !important;
}

.catogeryIconWrapper:hover .catogeryIcon path {
  fill: white !important;
}

.listIconWrapper:hover .listIcon rect {
  fill: #900a1f !important;
  opacity: 1;
}

.listIconWrapper:hover .listIcon path {
  fill: white !important;
}

.activeBox {
  color: #fff;
  background-color: #ce0e2d;
}
.activeBoxBig {
  outline: 2px solid black;
  margin-left: 1px;
}

.paginationLeftSide,
.paginationRightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leftMarginAuto {
  margin-left: auto;
}

.paginationNumberBoxWhole {
  margin-left: 40px;
  display: flex;
  gap: 4px;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .paginationLeftSide {
    display: none;
  }
}

.greay {
  background-color: var(--lightgray);
}

.paginationNumberBox:hover {
  background-color: var(--ilmorRedHover);
}

.paginationNumberBox:hover {
  color: white !important;
}
