.partsDetailMainComponent {
  display: flex;
  /* color: wheat; */
  background-color: white;
  /* flex-direction: column-reverse; */
}

.partsDetailCorousalComponent {
  /* width: 60%; */
}
.swiper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partDetailInputBoxes {
  gap: 10px;
  flex-wrap: wrap;
}
.pardetailEngineDeta {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: baseline;
}
.box {
  display: flex;
  flex-direction: column;
}
.partDetailEngineSerial {
  width: 174px;
}
.partDetailEngineHours {
  width: 108px;
}
.partDetailEngineStarts {
  width: 108px;
}
.InputFieldShippingAddressPayment {
  height: 42px;
  border: none;
  /* margin-top: .5%; */
  /* border: 1px solid #e8e7e7 !important; */
  border: 1px solid #e8e7e7;
  font-size: 15px;
  width: 100%;
  /* margin-top: 10px; */
  padding: 0px 15px;
}
.partDetailTitile {
  color: var(--brand-colors-black, #07080c);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  text-transform: uppercase;
}
.labelPartDetail {
  color: var(--brand-colors-black, #07080c);
  font-feature-settings: "clig" off, "liga" off;

  /* forms/label/Bold */
  font-family: Montserrat;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .partsDetailCorousalComponent {
    width: 100%;
  }
  .partsDetailMainComponent {
    display: flex;
    flex-direction: column-reverse;
  }
}
/*===============>partsDetailLeftSideComponent Desktop CSS <===============================*/

.partsDetailLeftSideComponent__mainDesktop {
  /* border: 1px solid black; */
  /* width: 40%; */
  background-color: rgb(247, 247, 247);
  font-family: Montserrat;
  height: 100%;
  padding: 48px;
}
.partsDe_container1Desktop {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 6px;
}
.partsDe_catalogLinkDesktop {
  color: #ce0e2d;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.partsDe_TransmissionLinkDesktop {
  color: #ce0e2d;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.partsDe_container2Desktop {
  /* border: 1px solid green; */
  /* color: black; */
  /* margin: -30px 0px; */
}
.partsDe_nameDesktop {
  color: #000;
  font-size: 49px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  /* text-align: justify; */
}

.partsDe_compatibilityMsgBoxDesktop {
  margin-bottom: 16px;
  margin-top: 6px;
  background-color: rgb(94, 170, 3);
  /* height: 25px; */
  display: flex;
  /* width: 480px; */ 
    padding: 3px 24px;
  align-items: center;
  gap: 12px;
}
.partsDe_compatibilityMsgDesktop {
  color: white;
  display: flex;
  padding-left: 14px;
  /* display: none; */
  /* top: 0px; */
  /* left: 24px; */
  /* position: relative; */
}

.partsDe_NOTcompatibilityMsgBox {
  display: flex;
  /* width: 480px; */
  padding: 0px 0px;
  align-items: center;
  gap: 12px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
}
.partsDe_exclamationMark {
  top: 0px;
  position: relative;
  left: 7px;
}
.partsDe_NOTcompatibilityMsg {
  color: var(--white, #fff);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}

.partsDe_checkMarkDesktop {
  /* top: 0px; */
  /* position: relative; */
  /* left: 14px; */
}
.partsDe_colorBoxDesktop {
  display: none;
}
.partsDe_colorLabelDesktop {
  display: none;
  letter-spacing: 2px;
  letter-spacing: 2px;
  display: flex;
  color: black;
}
.partsDe_colorSelectFieldDesktop {
  color: black;
  margin-top: -5%;
  display: none;
}
.parstsDe_PARTinfoDesktop {
  /* border: 1px solid grey;
  border-right-style: none;
  border-left-style: none; */
  display: flex;
  border-top: 1px solid rgba (97, 94, 92, 0.15);
  /* width: 480px; */
  /* padding: 16px 0px; */
  align-items: baseline;
  margin-top: 23px;
  gap: 20px;
  border-top: 1px solid rgba(97, 94, 92, 0.15);
  /* border-top: 1px solid rgba(97, 94, 92, 0.15); */
  /* border-bottom: 1px solid rgba(97, 94, 92, 0.15); */
}
.margInBottomLine {
  margin-bottom: 16px;
}
.partsDe_code1Desktop {
  color: black;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.partsDe_code2Desktop {
  color: #ce0e2d;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}
.partsDe_code2_mobDesktop {
  display: none;
}
.partsDe_code3_mobDesktop {
  display: none;
}
.partsDe_container3Desktop {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid rgba(97, 94, 92, 0.15);

  /* border-bottom: 1px solid rgba(97, 94, 92, 0.15); */
  padding-bottom: 20px;
}
.partsDe_container3msgDesktop {
  display: flex;
  flex-direction: row;
}
.partsDe_container3_msg1Desktop {
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.partsDe_container3_msg2Desktop {
  margin-top: 16px;
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-left: 4px;
}
.partsDe_container3_inputBoxPriceCartDesktop {
  /* width: 105%; */
  /* margin-top: 10px; */
  background-color: #f7f7f7;
  /* padding: 9px; */
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
}
.partsDe_container3_priceDesktop {
  font-size: 36px;
  color: black;
  margin-top: 0%;
}
.partsDe_inputBoxDesktop {
  width: 25%;
  font-size: larger;
  font-weight: 700;
  height: 40px;
  text-align: unset;
  outline: none;
  border: none;
  margin-left: 11%;
}
.partsDe_articleBoxDesktop {
  color: white;
  padding: 6px;
  background-color: #615e5c;
  height: fit-content;
}
.partsDe_outlineBoxDesktop {
  align-items: center;
  justify-content: center;
  display: flex;
}
.partsDe_shoppingBoxDesktop {
  color: white;
  margin-left: 3px;
  padding: 6px;
  background-color: #ce0e2d;
  height: fit-content;
}
.partsDe_container4Desktop {
  color: black;
  display: flex;
  flex-direction: column;
}
.partsDe_container4_msgDesktop {
  display: flex;
  border-right-style: none;
  border-left-style: none;
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.borderPart {
  border-top: 1px solid rgba(97, 94, 92, 0.15);
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
}
.partsDe_container4_engineNamesListDesktop {
  /* display: flex;
    flex-wrap: wrap;
    width: 90%;
    
    padding: 4px; */
}
.partsDe_container4_engineNameDesktop {
  color: #07080c;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  max-width: 100%;
  font-weight: 500;
  line-height: 30px;
  /* margin-left: -22px; */
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  display: flex;
  flex-wrap: wrap;
}

.partsDe_container4_engineNameDesktop li {
  flex: 1 0 100%;
}

@media screen and (min-width: 1025px) {
  .partsDe_container4_engineNameDesktop li {
    flex: 1 0 40%;
  }
}

@media screen and (min-width: 1600px) {
  .partsDe_container4_engineNameDesktop li {
    flex: 1 0 30%;
  }
}

.partsDe_container5Desktop {
}
.partsDe_container5MsgBoxDesktop {
  /* border-top: 1px solid rgba(97, 94, 92, 0.15); */
  color: black;
  display: flex;
}
.partsDe_warningIconDesktop {
  color: rgb(251, 238, 54);
  height: -moz-fit-content;

  margin-top: 12px;
}
.partsDe_warningIconOutlinedDesktop {
  position: relative;
  top: 5px;
}

.partsDe_lineBreakDesktop {
  /* width: 480px; */
  height: 1px;
  background: rgba(97, 94, 92, 0.15);
}
.partsDe_descriptionDesktop{
  display: flex;
  padding: 24px 0px 24px 0px;
  flex-direction: column;
}
.partsDe_descriptionLabelDesktop {
  margin-bottom: 8px;
  color: #07080c;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  text-transform: uppercase;
}

.partsDe_footerDescripDesktop {
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  display: flex;
  width: 100%;
  /* height: 119px; */
  flex-direction: column;
  flex-shrink: 0;
}
.partsDe_container5MsgDesktop {
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  padding-top: 20px;
  line-height: 20px;
  text-align: left;
}

.partsDe_container5LinkDesktop {
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: -13px 0px;
  text-decoration: underline;
  cursor: pointer;
}

.partDetails_InputFieldDesktop {
  border: none;
  /* max-width: 84px; */
  /* max-width: 134px; */
  outline: none;
  /* width: 50%;
    text-align: center; */
  padding: 10px 11px;
  /* min-width: 108px; */
  border-radius: 0px !important;
  padding: 12px;
  /* border: 1px solid #e8e7e7 !important; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 36px;
  /* identical to box height, or 257% */

  text-align: center;
  text-transform: uppercase;
}

/*==============================>MOBILE CSS<======================================*/

.partsDetailLeftSideComponent__mainMobile {
  /* border: 1px solid black; */
  /* width: 90%; */
  background-color: rgb(247, 247, 247);
  font-family: Montserrat;
  padding: 5%;
}
.partsDe_container1Mobile {
  display: flex;
  align-items: center;
  gap: 6px;
}
.partsDe_catalogLinkMobile {
  color: #ce0e2d;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.partsDe_TransmissionLinkMobile {
  color: #ce0e2d;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.partsDe_container2Mobile {
  /* border: 1px solid green; */
  /* color: black; */
  margin: 12px 0px;
}
.partsDe_nameMobile {
  color: #000;
  font-size: 35px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  text-align: left;
}

.partsDe_compatibilityMsgBoxMobile {
  background-color: rgb(94, 170, 3);
  display: flex;
  padding: 0px 12px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.checkMark_partDeMobile {
  color: var(--white, #fff);
  font-size: 20px;
  font-family: Font Awesome 5 Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.partsDe_compatibilityMsgMobile {
  color: white;
}
.partsDe_colorBoxMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.partsDe_colorLabelMobile {
  display: flex;
  color: black;
  color: var(--brand-colors-black, #07080c);
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.partsDe_colorSelectFieldMobile {
  color: black;
  margin-top: -5%;
  width: 100%;
  text-align: left;
  border: none;
}

.partsDe_colorSelectFieldMobile svg {
  color: red;
}
.partsDe_colorSelectFieldMobile [class*="-placeholder"] {
  color: black;
}
.partsDe_colorSelectFieldMobile [class*="-indicatorSeparator"] {
  display: none;
}

.parstsDe_PARTinfoMobile {
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: baseline;
  align-self: stretch;
}
.partsDe_code1Mobile {
  color: black;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.partsDe_code2Mobile {
  color: #ce0e2d;;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  display: none;
}
.partsDe_code2_mobMobile {
  color: #07080c;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}
.partsDe_code3_mobMobile {
  color: #615e5c;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
}
.partsDe_container3Mobile {
  display: flex;
  flex-direction: column;
}
.partsDe_container3msgMobile {
  display: flex;
  flex-direction: column;
}
.partsDe_container3_msg1Mobile {
  color: black;
  display: flex;
}
.partsDe_container3_msg2Mobile {
  color: black;
  margin-top: -5%;
  display: flex;
}
.partsDe_container3_inputBoxPriceCartMobile {
  width: 100%;
  margin-top: 10px;
  background-color: #f7f7f7;
  padding: 9px;
  display: flex;
  flex-direction: row;
}
.partsDe_container3_priceMobile {
  font-size: xx-large;
  color: black;
  margin-top: 0%;
}
.partsDe_inputBoxMobile {
  width: 25%;
  font-size: larger;
  font-weight: 700;
  height: 40px;
  text-align: unset;
  outline: none;
  border: none;
  margin-left: 11%;
}
.partsDe_articleBoxMobile {
  color: white;
  padding: 6px;
  background-color: #615e5c;
  height: fit-content;
}
.partsDe_outlineBoxMobile {
  align-items: center;
  justify-content: center;
  display: flex;
}
.partsDe_shoppingBoxMobile {
  color: white;
  margin-left: 3px;
  padding: 6px;
  background-color: #ce0e2d;
  height: fit-content;
}
.partsDe_container4Mobile {
  color: black;
  display: flex;
  flex-direction: column;
}
.partsDe_container4_msgMobile {
  display: flex;

  border-right-style: none;
  border-left-style: none;
  width: 100%;
  font-size: 14px;
}
.partsDe_container4_engineNamesListMobile {
  /* display: flex;
    flex-wrap: wrap;
    padding: 4px; */
}
.partsDe_container4_engineNameMobile {
  color: #07080c;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* margin-left: -22px; */
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
}
.partsDe_lineBreakMobile {
  width: 334px;
  height: 1px;
  background: rgba(97, 94, 92, 0.15);
}

.partsDe_descriptionMobile {
  display: flex;
  width: 334px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 26px 0px;
}

.partsDe_descriptionLabelMobile {
  display: flex;
  width: 350.769px;
  flex-direction: column;
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  text-align: left;
}

.partsDe_descriptionMsgMobile {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.partsDe_container5Mobile {
}
.partsDe_footerDescripMobile {
  display: flex;
  width: 334px;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px 0px;
}
.partsDe_container5MsgBoxMobile {
  color: black;
  display: flex;
}
.partsDe_warningIconMobile {
}
.partsDe_warningIconOutlinedMobile {
  position: relative;
  top: 5px;
}

.partsDe_container5MsgMobile {
  /* display: flex; */
  text-align: left;
  color: #07080c;
  font-size: 13px;
}
.partsDe_container5LinkMobile {
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: -13px 0px;
  text-decoration: underline;
  word-break: break-word;
}
.partDetails_InputFieldMobile {
  border: none;
  /* max-width: 84px; */
  /* max-width: 134px; */
  outline: none;
  width: 50%;
  text-align: center;
  padding: 10px 11px;
  min-width: 108px;
  border-radius: 0px !important;
  padding: 12px;
  /* border: 1px solid #e8e7e7 !important; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 36px;
  /* identical to box height, or 257% */

  text-align: center;
  text-transform: uppercase;
}

/*===========================> Tablet CSS <=======================================*/
.partsDetailLeftSideComponent__mainTablet {
  display: flex;
  width: 768px;
  padding: 40px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.partsDe_container1Tablet {
  display: flex;
  /* border: 1px solid black; */
  color: black;
}
.partsDe_catalogLinkTablet {
  color: #ce0e2d;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.partsDe_TransmissionLinkTablet {
  color: #ce0e2d;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.partsDe_container2Tablet {
  /* border: 1px solid green; */
  color: black;
  margin: -15px 0px;
}
.partsDe_nameTablet {
  font-size: 301%;
  text-align: left;
}

.partsDe_compatibilityMsgBoxTablet {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: #40a900;
  width: 100%;
}

.partsDe_compatibilityMsgTablet {
  color: var(--white, #fff);
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.partsDe_colorBoxTablet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 93%;
}
.partsDe_colorLabelTablet {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.partsDe_colorSelectFieldTablet {
  color: black;
  /* margin-top: -5%; */
  width: 107%;
  text-align: left;
  border: none;
}

.partsDe_colorSelectFieldTablet svg {
  color: red;
}
.partsDe_colorSelectFieldTablet [class*="-placeholder"] {
  color: black;
  display: flex;
  height: 40px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.partsDe_colorSelectFieldTablet [class*="-indicatorSeparator"] {
  display: none;
}

.parstsDe_PARTinfoTablet {
  display: flex;
  width: 100%;
  align-items: baseline;
  gap: 20px;
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
  border-top: 1px solid rgba(97, 94, 92, 0.15);
}
.partsDe_code1Tablet {
  color: black;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.partsDe_code2Tablet {
  color: #ce0e2d;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
}

.partsDe_code3_mobTablet {
  color: #615e5c;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-left: 30%;
}
.partsDe_container3Tablet {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.partsDe_container3msgTablet {
  display: flex;
  flex-direction: row;
  /* width: 712px;
    border: 1px solid;
    justify-content: space-between; */
  align-items: center;
}
.partsDe_container3_msg1Tablet {
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.partsDe_container3_msg2Tablet {
  /* margin-top: 5%; */
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.partsDe_container3_inputBoxPriceCartTablet {
  display: flex;
  flex-direction: row;
}
.partsDe_container3_priceTablet {
  font-size: xx-large;
  color: black;
  margin-top: 0%;
}
.partsDe_inputBoxTablet {
  width: 50%;
  font-size: larger;
  font-weight: 700;
  height: 40px;
  text-align: unset;
  outline: none;
  border: none;
  margin-left: 11%;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: #fff;
}
.partsDe_articleBoxTablet {
  color: white;
  padding: 6px;
  background-color: #615e5c;
  height: fit-content;
}
.partsDe_outlineBoxTablet {
  align-items: center;
  justify-content: center;
  display: flex;
}
.partsDe_shoppingBoxTablet {
  color: white;
  margin-left: 3px;
  padding: 6px;
  background-color: #ce0e2d;
  height: fit-content;
}
.partsDe_container4Tablet {
  color: black;
  display: flex;
  flex-direction: column;
}
.partsDe_container4_msgTablet {
  display: flex;
  border-right-style: none;
  border-left-style: none;
  color: #07080c;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.partsDe_container4_engineNamesListTablet {
  /* display: flex;
    flex-wrap: wrap;
    width: 90%;
     padding: 4px; */
}
.partsDe_container4_engineNameTablet {
  color: #07080c;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* margin-left: -22px; */
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
}
.partsDe_lineBreakTablet {
  width: 700px;
  height: 1px;
  background: rgba(97, 94, 92, 0.15);
}

.partsDe_descriptionTablet {
  display: flex;
  width: 712px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.partsDe_descriptionLabelTablet {
  display: flex;
  /* width: 350.769px; */
  flex-direction: column;
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.partsDe_descriptionMsgTablet {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.partsDe_container5Tablet {
}
.partsDe_container5MsgBoxTablet {
  color: black;
  display: flex;
}
.partsDe_footerDescripTablet {
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  width: 712px;
  height: 119px;
  flex-direction: column;
  flex-shrink: 0;
}
.partsDe_container5MsgTablet {
  text-align: left;
}
.partsDe_container5LinkTablet {
  color: #07080c;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: -13px 0px;
  text-decoration: underline;
}

.partDetails_InputFieldTablet {
  border: none;
  /* max-width: 84px; */
  /* max-width: 134px; */
  outline: none;
  width: 50%;
  text-align: center;
  padding: 10px 11px;
  min-width: 108px;
  border-radius: 0px !important;
  padding: 12px;
  /* border: 1px solid #e8e7e7 !important; */
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 36px;
  /* identical to box height, or 257% */

  text-align: center;
  text-transform: uppercase;
}

.partsDe_warningIconOutlinedTablet {
  position: relative;
  top: 6%;
}

[class^="partsDe_container3_inputBoxPriceCart"] .react-numeric-input {
  position: relative !important;
  display: inline-flex !important;
  /* margin: 0 5px; */
  /* text-align: right; */
  height: 42px;
  align-items: stretch;
  justify-content: flex-end;
}

[class^="partsDe_container3_inputBoxPriceCart"] .react-numeric-input input {
  width: 71%;
}

[class^="partsDe_container3_inputBoxPriceCart"]
  .react-numeric-input
  :nth-child(2),
[class^="partsDe_container3_inputBoxPriceCart"]
  .react-numeric-input
  :nth-child(3) {
  width: 20px;
  height: 20px;
  margin-top: 0;
}

[class^="partsDe_container3_inputBoxPriceCart"]
  .react-numeric-input
  :nth-child(3) {
  margin-top: -1px;
}

.partsDe_container3_inputBoxPriceCartDesktop .center .react-numeric-input {
  min-width: 108px;
}
ul.thumbs {
  /* flex-wrap: wrap; */
  padding: 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .partsDetailCorousalComponent {
    width: 100%;
  }

  .partsDetailMainComponent {
    display: flex;
    flex-direction: column-reverse;
  }
  .swiper-slide img {
    width: 90% !important;
  }
  .partsDetailLeftSideComponent__mainDesktop {
    padding: 40px 20px;
  }
  .partsDe_catalogLinkDesktop {
    font-size: 12px;
  }
  .partsDe_nameDesktop {
    font-size: 36px;
  }
}

.partDetails_InputFieldDesktop {
  color: #07080c;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 36px !important;
  text-transform: uppercase;
}
