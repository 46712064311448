.shop_cart {
    /* background-color: white;
    height: 188px;
    color: black; */
    width: 180px;
    height: 160px;
    text-align: left;
    padding-left: 16px;
    background-color: white;
    position: absolute;
    top: 8px;
    min-width: 176px;
    border-radius: 2px;
    right: -14px;
    font-size: 12px;
    z-index: 2;
    font-weight: 600;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    color: black;
    border-radius: 5px;
    box-shadow: 1px 4px 6px 0px rgba(0, 0, 0,0.5);
}

.numberOfItems {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: #07080C;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    padding-top: 25px;
    margin: 0;
}

.totalAmount {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: #07080C;
    font-size: 24px;
    font-family: Montserrat;
    line-height: 120%;
    padding: 0;
    margin: 0;
    font-weight: lighter;
    text-align: left;
    padding-top: 20px;
}
.shrinkAmt{
    font-size: 18px;
}

.btnViewCart {
    background-color: var(--ilmorRed);
    color: white;
    font-size: 13px;
    margin-bottom: 24px;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    /* gap: 16px; */
    align-self: stretch;
    font-family: Montserrat;
    font-weight: 700;
    height: 55px;
    width: 145px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
}

.btnViewCart:hover{
    background-color: #900a1f;    
    }