.navigation {
  display: flex;
  padding: 25px 50px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #EAEAEA;
}

.ilmorButon {
  color: white;
  font-size: 13px;
  background-color: #CE0E2D;
  letter-spacing: 1px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #FFF;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}

.box1,
.box2,
.box3 {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  border-radius: 20px;
  color: black;
  text-align: center;
  /* Display xs/Bold */
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
}

.ActiveBox1 {
  color: var(--white, #FFF);
  background: #CE0E2D;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.rightSide {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  background-color: white;
  border-radius: 20px;
}
.width{
  width: unset;
}
@media screen and (min-width:0px) and (max-width:600px) {
  .leftSide {
    display: none;
  }

  .navigation {
    justify-content: center;
  }

  .CheckoutComponent {
    padding: 32px 20px;
    /* height: 747px; */
  }

  .shippingTitile {
    font-size: 24px;

  }

  .billTtile {
    font-size: 16px;
  }

  .billTtile2 {
    font-size: 14px;
  }

  .billingShop,
  .orderTitile,
  .shippingAddress,
  .payment {
    /* width: 335px !important; */
  }

  .billAddLastComp,
  .paymetnOptions {
    width: 100%;
  }

  .paymetnOptions,
  .paymetnOptionsPO {
    flex-wrap: wrap;
  }

  .paymetnOptionsPO {
    margin-top: 10px;
  }

  .padRight {
    padding-right: 21px;
    /* margin-top: 10px; */
  }

  .maxWidth {
    width: 100%;
    border: 1px solid rgba(97, 94, 92, 0.15) !important;
  }

  .shoppingListTableHeadDesktop {
    display: none;
  }

  .orderCompThank,
  .orderCompThank,
  .orderCompSweet {
    width: 350px;
  }

  .buttonGroupOrder {
    flex-direction: column;
    width: 47%;
    padding-top: 32px !important;
    gap: 7px;
  }
}

@media screen and (min-width:601px) and (max-width:900px) {
  .leftSide {
    display: none;
  }

  .navigation {
    justify-content: center;
    width: 100%;
  }

  .CheckoutComponent {
    padding: 70px 40px;
    width: 100%;
    /* display: flex; */
  }

  .shippingTitile {
    font-size: 24px;
    margin-left: 8px;
  }

  .drkjgdrkhjgb{
    margin-left: 8px !important;
  }
  .ShippingPaymentComponentTop {
    flex-direction: column;
  }

  .billingShop,
  .orderTitile,
  .shippingAddress,
  .payment {
    /* width: 671px !important; */
  }

  .orderTitile {
    /* width: 687px !important; */
  }
}