.ForgetPw__mainDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
}

.ForgetPw_ContainerDesktop {
  width: 550px;
  height: 632px;

  margin-top: 120px;
  margin-bottom: 120px;
}
.ForgetPw_logoDesktop {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ForgetPw_title-imageDesktop {
  height: auto;
  width: 30%;
  padding: 10px;
}

.ForgetPw_info__mainDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 70px;
  gap: 40px;
  justify-content: center;
  /* width: 358px; */
  /* height: 335px; */
  background: #f7f7f7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ForgetPw_headingDesktop {
  width: 375px;
  height: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
}

.passwordRecoveryMsgDesktop {
  width: 374px;
  height: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #000000;
}
.ForgetPw_lineBreak__mainDesktop {
  width: 374px;
  height: 0px;

  /* transparent gray
  
  form field outlines and separator lines
  */
  border: 1px solid rgba(97, 94, 92, 0.15);
}
.ForgetPw_formDesktop {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -50px;
  width: 100%;
  margin-left: 38px;
}
.ForgetPw_emailLabelDesktop {
}
.ForgetPw_labelDesktop {
  width: 155px;
  height: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 6%;
  color: #07080c;
}
.ForgetPw_usernameInputDesktop {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-radius: 0px  !important;
  gap: 12px;
  width: 374px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(97, 94, 92, 0.15);
}

.ForgetPw_usernameInputDesktop:focus-visible {
  /* border: none; */
  outline: none;
}

.ResetPwBtnBoxDesktop {
  margin-top: 5%;
}

.ResetPwDesktop:hover {
  background-color: darkred;
  transition: 0.5s;
}
.ResetPwDesktop {
  display: flex;
  padding: 12px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
}

.ResetPwLabelDesktop {
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}

/*=====================================> Mobile Css <====================================================*/

.ForgetPw__mainMobile {
  height: 100vh;
  display: flex;
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: center;
}

.ForgetPw_ContainerMobile {
  /* margin-top: 150px;
  margin-left: 20px; */
}
.ForgetPw_logoMobile {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
}
.ForgetPw_title-imageMobile {
  height: auto;
  width: 45%;
  padding: 10px;
}
.ForgetPw_info__mainMobile {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
  gap: 30px;
  width: 350px;
  height: 422px;
  background: #f7f7f7;
}

.ForgetPw_headingMobile {
  width: 378px;
  height: 29px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
}

.passwordRecoveryMsgMobile {
  /* width: 305px; */
  padding: 0px 36px 0px 37px;
  height: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  /* margin-left: 36px; */
}

.ForgetPw_lineBreak__mainMobile {
  width: 302px;
  height: 0px;

  /* transparent gray

form field outlines and separator lines
*/
  border: 1px solid rgba(97, 94, 92, 0.15);
}
.ForgetPw_formMobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -30px;
  width: 100%;
}
.ForgetPw_emailLabelMobile {
}
.ForgetPw_labelMobile {
  width: 155px;
  height: 12px;

  /* forms/label/Bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Brand Colors/Black */

  color: #07080c;
}
.ForgetPw_usernameInputMobile {
  box-sizing: border-box;
  border-radius: 0px !important;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 12px;

  width: 300px;
  height: 42px;
  margin-top: -4%;

  /* White */

  background: #ffffff;
  border: 1px solid rgba(97, 94, 92, 0.15);
}
.ForgetPw_usernameInputMobile:focus-visible {
  /* border: none; */
  outline: none;
}

.ResetPwBtnBoxMobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 28px;
}

.ResetPwMobile:hover {
  background-color: darkred;
  transition: 0.5s;
}

.ResetPwMobile {
  display: flex;
  padding: 12px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
}
.ResetPwLabelMobile {
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}

/*====================================> Tablet CSS <=================================================*/
.ForgetPw__mainTablet {
  height: 100vh;
  display: flex;
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: center;
}

.ForgetPw_ContainerTablet {
  /* margin-top: 175px;
  margin-bottom: 175px; */
}
.ForgetPw_logoTablet {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  margin-top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ForgetPw_title-imageTablet {
  height: auto;
  width: 38%;
  padding: 10px;
}

.ForgetPw_info__mainTablet {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 96px;
  gap: 30px;
  justify-content: center;
  width: 546px;
  height: 375px;

  background: #f7f7f7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ForgetPw_headingTablet {
  width: 375px;
  height: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
}

.passwordRecoveryMsgTablet {
  width: 374px;
  height: 32px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #000000;
}
.ForgetPw_lineBreak__mainTablet {
  width: 340px;
  height: 1px;
  display: flex;

  border: none;
  background-color: red;
  -webkit-writing-mode: vertical-lr;
}
.ForgetPw_formTablet {
  display: flex;
  margin-top: 5px;
  width: 438px;
  padding-top: 15px;
  flex-direction: column;
  border-top: 1px solid rgba(97, 94, 92, 0.15);
  flex-wrap: wrap;
  /* margin-top: -30px;
    width: 100%; */
}
.ForgetPw_emailLabelTablet {
}
.ForgetPw_labelTablet {
  width: 155px;
  height: 12px;

  /* forms/label/Bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Brand Colors/Black */

  color: #07080c;
}
.ForgetPw_usernameInputTablet {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 12px;
  /* width: 334px; */
  height: 42px;

  /* White */

  background: #ffffff;
  border: 1px solid rgba(97, 94, 92, 0.15);
  margin-top: -4%;
}

.ForgetPw_usernameInputTablet:focus-visible {
  /* border: none; */
  outline: none;
}
.ResetPwBtnBoxTablet {
  margin-top: 5%;
}
.ResetPwTablet:hover {
  background-color: darkred;
  transition: 0.5s;
}

.ResetPwTablet {
  display: flex;
  padding: 12px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
}

.ResetPwLabelTablet {
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}

.FogetPw_usernameInputwarning {
  border: 1px solid #ce0e2d;
}
