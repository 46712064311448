.partsCat__mainDesktop {
  width: 100%;
  background: #eaeaea;
  display: flex;
  font-family: Montserrat;
  /* flex-wrap: wrap; */
  padding: 18.5px 48px;
  /* gap: 44px; */
  /* height: 160px; */
  /* justify-content: space-around; */
}
@media screen and (min-width: 1326px) {
  .partsCat__mainDesktop {
    flex-wrap: nowrap;
  }
}

.partsCat__title1Desktop {
  display: flex;
  width: 20%; 
  min-width: 377px;
  /* border: 1px solid gold; */
  flex-wrap: wrap;
  /* padding-left: 48px; */
  /* padding-right: 48px; */
}

.partsCat__bDesktop {
  font-size: 35px;
  letter-spacing: 1px;
  display: flex;
  min-width: 368px;
  padding-right: 24px;
  align-items: center;
  justify-content: center;
}

.partsCat__findpartsDesktop {
  /* padding-left: 45px; */
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-between;
  /* align-items: center; */ 
}
.partsCat__boxesDesktop > div{
  width:172px;
  background-color:rgba(255, 255, 255, 0.25);
}

.partsCat__title2Desktop {
  display: flex;
  justify-content: flex-start;
  /* margin-left: -52%; */
}

.partsCat__h2Desktop {
  letter-spacing: 2px;
  font-size: 17px;
}

.partsCat__boxesDesktop {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  font-weight: 700;
  /* padding: 3%; */
}

.partsCat__boxDesktop {
  /* min-width: 100px; */
  /* margin-top: -4%; */
  /* text-align: center; */
  /* margin-right: 20px; */
  /* width: 100%; */
  border: none;
  display: flex;
}

.partsCat__boxDesktop [class*="-control"] {
  border: 1px solid white;
  color: white;
  min-width: 175px;
  border-radius: 0px;
  font-weight: 600;
  box-shadow: none !important;
}

.partsCat__boxDesktop [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.partsCat__boxDesktop [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  outline: none;
}

.partsCat__boxDesktop [class*="-placeholder"] {
  color: black;
  font-weight: 600;
  font-size: 13px;
}

.partsCat__boxDesktop [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.partsCat__boxDesktop [class*="-menu"] [class*="option"] {
  background-color: transparent;
  color: white !important ;
}

.partsCat__boxDesktop [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
  width: 174px;
}

.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.partsCat__boxDesktop [class*="-indicatorSeparator"] {
  display: none;
}

.partsCat__boxDesktop [class*="-indicatorContainer"] {
  color: #ce0e2d;
}

.partsCat__boxDesktop [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.partsCat__boxDesktop [class*="-singleValue"] {
  font-size: 13px;
  font-weight: 600;
}

.partsCat__boxDesktop [class*="-control"] {
  min-width: 193px;
  width: 100%;
}

.partsCat__btnDesktop {
  /* margin-top: -4%; */
  /* height: auto; */
  /* width: 180px; */
  text-align: center;
  min-width: 162px;
  letter-spacing: 1px;
  background: #ce0e2d;
  border: none;
  color: rgb(255, 255, 255);
  padding: 7px 0px 7px 19px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-weight: 500;
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  cursor: pointer;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}

.partsCat__findpartsCollapseExpDesktop {
  display: none;
}

/*=========> MOBILE VIEW CSS<============*/

.partsCat__mainMobile {
  width: 100%;
  background-color: rgb(228, 228, 228);
  display: flex;
  flex-direction: column;
  padding: 24px 20px 0px 20px;
  font-family: Montserrat;
}

.partsCat__title1Mobile {
  display: flex;
  height: 40px;
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
  justify-content: flex-start;
  /* border-bottom-style: groove; */
}

.partsCat__bMobile {
  color: #07080c;
  padding: ;
  /* Display md/Bold */
  font-family: Montserrat;
  /* padding: 24px 20px; */
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  text-transform: uppercase;
  /* letter-spacing: 3px; */
}

.partsCat__findpartsMobile {
  /* border: 1px solid pink; */
  display: none;
}

.partsCat__title2Mobile {
  font-size: 40%;
  letter-spacing: 3px;
}

.partsCat__h2Mobile {
  /* margin-top: -2%; */
}

.partsCat__findpartsCollapseExpMobile {
  display: block;
  /* letter-spacing: 3px; */
  /* border: 1px solid purple; */
}

.partsCat__findpartsCollapseExph2Mobile {
  /* letter-spacing: 3px; */
  color: var(--brand-colors-black, #07080c) !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-style: normal !important;
  padding: 12px 0px;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: 2px !important;
  text-transform: uppercase !important;
}

.partsCat__boxesMobile {
  display: flex;
  gap: 7px;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.partsCat__boxMobile {
  /* width: 365px; */
  /* margin-top: 1%; */
}

.partsCat__boxMobile [class*="-control"] {
  /* background-color: rgb(15, 37, 52); */
  /* border: 1px solid white; */
  /* color: white;
  min-width: 175px;
  border-radius: 0px;
  font-weight: 600; */
  box-shadow: none !important;
}

.partsCat__boxMobile [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.partsCat__boxMobile [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  outline: none;
}

.partsCat__boxMobile [class*="-placeholder"] {
  /* color: white; */
  font-weight: 600;
  display: flex;
  font-size: 13px;
}

.partsCat__boxMobile [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.partsCat__boxMobile [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.partsCat__boxMobile [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
  width: 174px;
}

.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.partsCat__boxMobile [class*="-indicatorSeparator"] {
  display: none;
}

.partsCat__boxMobile [class*="-indicatorContainer"] {
  color: red;
}

.partsCat__boxMobile [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.partsCat__boxMobile [class*="-singleValue"] {
  color: white;
}

.partsCat__btnMobile {
  /* width: 365px; */
  /* margin-top: 1%; */
  justify-content: center;
  align-items: center;
  border: none;
  display: flex;
  height: auto;
  height: 40px;
  background: #ce0e2d;
  color: rgb(255, 255, 255);
  font-size: 13px;
  margin-bottom: 40px;
  gap: 16px;
}

.options1Mobile {
  width: 5vw;
  color: blueviolet;
}

.viewPartsActive{
  stroke: #fff;
} 

/*========> TABLET CSS CODE <==========*/

.partsCat__mainTablet {
  width: 100%;
  background: #eaeaea;
  display: flex;
  padding: 24px;
  font-family: Montserrat;
  align-items: flex-start;
  text-align: left;
  /* height: 160px; */
  justify-content: space-between;
}

.partsCat__title1Tablet {
  display: flex;
  /* border: 1px solid gold; */
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  /* width: 26%; */
}

.partsCat__bTablet {
  font-size: 25px;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #07080c;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.partsCat__findpartsTablet {
  /* width: 74%; */
}

.partsCat__title2Tablet {
  /* width: 60vw;
  /* border: 1px solid red; */
  /* height: 90px;  */

  /* margin-left: -134px; */
}

.partsCat__h2Tablet {
  /* margin-top: 5%; */
  /* margin-left: -8%; */
  letter-spacing: 2px;
  /* font-size: 120%; */
  color: var(--brand-colors-black, #07080c);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.partsCat__boxesTablet {
  display: flex;
  gap: 8px;
  /* margin-left: -3%; */
  /* padding: 3%; */
  /* margin-top: 1%; */
  box-sizing: border-box;
}

.partsCat__boxTablet {
  /* margin-top: -5%; */
  width: 100%;
  /* margin-right: 12px; */
}

.partsCat__boxTablet [class*="-control"] {
  /* background-color: transparent;  */
  border: 1px solid white;
  border-radius: 0px;
  box-shadow: none !important;
  width: max-content;
}

.partsCat__boxTablet [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
  width: max-content;
  border-radius: 0px;
}

.partsCat__boxTablet [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  outline: none;
}

.partsCat__boxTablet [class*="-placeholder"] {
  /* color: black; */
  font-weight: 600;
  width: max-content;
  font-size: 12px;
}

.partsCat__boxTablet [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.partsCat__boxTablet [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.partsCat__boxTablet [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
  width: 174px;
}

.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.partsCat__boxTablet [class*="-indicatorSeparator"] {
  display: none;
}

.partsCat__boxTablet [class*="-indicatorContainer"] {
  color: red;
}

.partsCat__boxTablet [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.partsCat__boxTablet [class*="-singleValue"] {
  color: black;
}

.partsCat__btnTablet {
  /* margin-top: -5%;
  height: auto;
  width: 180px;
  text-align: center;
  letter-spacing: 1px;
  background: #CE0E2D;
  border: none;
  color: rgb(255, 255, 255);
  padding: 1.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500; */
}

.partsCat__btnTablet {
  /* margin-top: -5%; */
  /* height: auto; */
  /* width: max-content; */
  background: #ce0e2d;
  border: none;
  color: rgb(255, 255, 255);
  /* padding: 1.5%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-weight: 500; */
}

.partsCat__findpartsCollapseExpTablet {
  display: none;
}

/*=======================Disabled Css =========================*/

.partsCatBoxdisabled[class*="-control"] {
  border: 1px solid gray;
  background-color: transparent;
}

/* .partsCatBoxdisabled[class*="-placeholder"] {
  color: gray;
  font-weight: 600;
} */
.partsCatBoxdisabled [class*="-placeholder"] {
  color: gray;
  font-weight: 600;
  font-size: 12px;
}

/* .partsCatBoxdisabled[class*="-indicatorContainer"] {
  color: gray;
} */
.partsCatBoxdisabled [class*="-indicatorContainer"] {
  color: gray;
}

.partsCatBtndisabled {
  background-color: hsla(0,2%,82%,.25);
  color: gray;
  font-size: 12px;
  font-weight: 600;
  gap: 16px;
  height: 40px;
  padding: 21px 0px 21px 21px;
}

.viewPartsTextTablet {
  margin-right: 15px;
  margin-left: 11px;
  font-family: 'Montserrat';
  font-weight: 700;
}

.currentImagewidth {
  width: 120px;
}

.currentEngine {
  display: flex;
  padding: 10px 0px;
  flex-direction: row;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  /* max-width: 886px; */
  /* padding-left: 48px; */
  align-items: center;
}

.currentShopDetail {
  display: flex;
  flex-direction: column;
  max-width: 235px;
  align-items: baseline;
  gap: 4px;
}

.currentRedText {
  color: #ce0e2d;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.currEngineName {
  color: #07080c;
  font-family: Montserrat;
  font-size: 20px;
  /* word-break: break-all; */
  /* width: max-content; */
  overflow-wrap: break-word;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  /* text-transform: uppercase; */
}

.currButonText {
  color: #07080c;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 8px;
}

.currentShopButtons {
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
  flex-wrap: wrap
}

.currentShopButtonDiv {
  background: rgba(97, 94, 92, 0.15);
  display: flex;
  /* width: 94px; */
  padding: 8px 16px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  /* gap: 8px; */
}

.margiCurr {
  margin-left: 60px;
}

.shopbyTab {
  display: none !important;
}
.shopbyDesk {
  display: none;
}

.shopbyMob {
  display: none;
}
.MobilwComp {
  display: none;
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .shopbyTab {
    display: unset !important;
  }

  .shopbyDesk,
  .MobilwComp {
    display: none !important;
  }

  .currentEngine {
    padding-left: 0px;
  }

  .partsCatCurrenrt {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .shopbyTab {
    display: none !important;
  }

  .shopbyDesk {
    display: none !important;
  }

  .currentImagewidth {
    width: 77px;
  }
  .MobilwComp {
    display: flex;
  }

  .currentEngine {
    /* padding-left: 16px; */
  }
  .currentImage {
    padding: 0px 9px;
  }
  .currEngineName {
    text-align: left;
  }
  .margiCurr {
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 22px;
  }
}
