.header {
  /* height: 70vh; */
  /* background-image: url("../Images/Blacksilk.jpg"); */
  background-size: cover;
  color: white;
  background-size: cover;
  color: white;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.myAccountHeader {
  color: white;

  position: inherit;
  background-color: black;
}
.accountFlexDiv {
  display: flex;
}

.headerCatalog {
  background-color: black;
  color: white;
  width: 100%;
}

.yourCart {
  color: white;

  position: inherit;
  background-color: black;
}

.headercatalogPage {
  color: white;

  position: inherit;
  background-color: black;
}

.header__account__icon {
  /* margin-left: 3%; */
}

.top__bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.top__barMobile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header__image {
  height: auto;
  width: 261px;
  margin-top: 8px;
  cursor: pointer;
}

.header__imageMobile {
  height: 62px;
  width: 172px;
  cursor: pointer;
  margin-left: -10px;
}

.select__header {
  border: none;
  outline: none;
  padding: 1px 15px;
}

.header___MobileTopMenu {
  display: flex;
  width: 150px;
  justify-content: space-around;
  align-items: center;
}

.headerGrid {
  padding: 10px;
}

/* header should be full width
 .headerGridMobile {
  padding: 3px;
} */
.myAccountHeaderGrid {
  width: 100%;
}

.header__Mobile__topMenuButtons {
  font-weight: 500;
  font-size: 15px;
}

.header__white__section {
  background-color: white;
  /* padding: 1px; */
  position: relative;
  display: inline-block;
  /* width: 122px; */
  align-items: center;
}

.header__select {
  /* width: 150px; */
  font-size: 11px;
  border: none;
  display: flex;
  margin-right: 5px;
}

.header__select [class*="-control"],
.header__select [class*="-control"]:hover,
.header__select [class*="-control"]:focus {
  border-radius: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.header__select [class*="-menu"] {
  background-color: #474545;
  /* background: rgba(97, 94, 92, 0.8) !important; */
}

.header__select__bar {
  width: 2px;
  height: 20px;
  background-color: #979797;
}

.header__select [class*="-menu"] [class*="option"] {
   background-color: transparent; 
  margin-top: -8px;
  text-align: left;
  cursor: pointer;
  color: white;
}

.header__select [class*="-menu"] [class*="option"]:hover {
 
  color: #ce0e2d;
}

.header__select [class*="-menu"] [class*="option"]:active {
 
  color: #ce0e2d;
}


.header__select [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  max-height: fit-content;
}

.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.header__select [class*="-control"] [class*="-indicatorSeparator"] {
  display: none !important;
}

.header__select [class*="-control"] [class*="-ValueContainer"] {
  min-width: 108px;
  font-size: 13px;
  /* text-align: center; */
  font-weight: 600;
}

.header__select [class*="-control"] [class*="css-hlgwow"] {
  min-width: 108px;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
}

.header__select
  [class*="-control"]
  [class*="-ValueContainer"]
  [class*="-placeholder"] {
  color: black;
}

.header__select [class*="-indicatorContainer"] {
  color: #ce0e2d;
}

.header__select [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.header__input__base {
  min-width: 27vw !important;
  line-height: 15px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0.05em !important;
}
.header__input__base.border {
  border: 1px solid #eee !important;
  min-width: 133px ;
  max-width: 100% !important;
  /* width: 170px; */
}

.header__input__baseMobile {
  /* min-width: 20vw!important; */
  width: 100%;
  line-height: 15px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0.05em !important;
}

.inputBaseDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: white;
  margin-top: 9px;
}
.minWidth{
  min-width: 16vw !important;
}
.header__rightside__options {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  width: 27vw;
  margin-left: auto;
  gap: 3%;
  cursor: pointer;
  margin-right: 20px;
  justify-content: flex-end;
}

.header__rightside__optionsMobile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  cursor: pointer;
  margin-bottom: -2px;
  padding: 10px 24px;
  margin-right: 0px;
  background-image: linear-gradient(
    to right,
    rgb(17, 26, 39) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
}

.header__rightside__optionsTablet {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 12px;
  padding: 8px;
  margin-right: 31px;
  gap: 13px;
}

.header__text {
  font-weight: 600;
  font-size: 13px;
  /* margin-right: 24px; */
  /* padding-right: 24px; */
  margin-right: 0%;
  /* padding-left: 2%; */
  /* padding-right: 3%; */
  /* border-right: 1px solid #979797; */
}

.header__textMobile {
  font-weight: 500;
  font-size: 15px;
  /* margin-left: 13px; */
  margin-right: 10px;
  padding-right: 11px;
  border-right: 1px solid #979797;
}

.header__text__menuMobile {
  /* padding-right: 11px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.left {
  margin-left: 23%;
}

.right {
  margin-right: 21%;
}

.noMargin {
  border-right: 0px solid white;
}

.header__leftside__options {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.header__leftside__optionsMobile {
  display: flex;
  align-items: center;
  min-width: 100vw;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header__leftside__optionsTablet {
  display: flex;
  align-items: center;
  min-width: 100vw;
  justify-content: space-between;
}

.header__shoppingList {
  display: flex !important;
  align-items: center;
  /* border-right: 1px solid #979797; */
  /* padding-right: 5%; */
  /* margin-right: 5%; */
  /* margin-right: 1%; */
  /* padding-right: 3%; */
  /* padding-left: 3%; */
  justify-content: center;
}

.header__shoppingListMobile {
  display: flex !important;
  align-items: center;
  /* border-right: 1px solid #979797; */
  /* padding-right: 5%; */
  /* margin-right: 5%; */
  /* margin-right: 1%; */
  /* padding-right: 3%; */
  justify-content: center;
}

.header___MobileTopMenu {
  margin-right: 11px;
}

.main__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:root {
  --boxShadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.5);
}

.accountMenuOpenDesktop {
  width: fit-content;
  background-color: white;
  position: absolute;
  top: 85px;
  min-width: 176px;
  border-radius: 6px;
  right: 26px;
  font-size: 12px;
  z-index: 2;
  box-shadow: var(--boxShadow);
  font-weight: 600;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  color: black;
}

.accountMenuOptions {
  /* margin-top: 4px; */
  padding: 0px 9px;
}

.accountMenuOptionsDiv:hover {
  /* font-size: 12.5px; */
  color: #ce0e2d;
  background-color: #f7f7f7;
}
.shopCart__arrow {
  background-color: white;
  z-index: 1;
  height: 28px;
  rotate: 45deg;
  top: 5px;
  right: 0px;
  position: absolute;
  width: 34px;
}

.ShopCartMenu {
  position: relative;
  right: -10px;
  top: 0px;
}

.arrow {
  background-color: white;
  z-index: 1;
  height: 28px;
  rotate: 45deg;
  top: 80px;
  right: 32px;
  position: absolute;
  width: 28px;
}

.accountMenuOptionsDiv {
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(97, 94, 92, 0.07);
  user-select: none;
  border-radius: 6px;
  /* background-color: #f7f7f7; */
}

.accountPopMenu {
  position: absolute;
  top: -5px;
  right: -1px;
}

.accountPopMenuSearch {
  position: relative;
  top: -68px;
  right: -74px;
}

.fullWidthSearc {
  width: 100%;
}

.accountMenuOpenMobile {
  width: fit-content;
  background-color: white;
  position: absolute;
  top: 83px;
  /* min-width: 370px; */
  /* border-radius: 2px; */
  right: -50px;
  font-size: 12px;
  z-index: 2;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  box-shadow: var(--boxShadow);
  color: black;
  background: #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.searchDiv {
  /* padding: 7px 9px; */
  display: flex;
  height: 20px;
  justify-content: center;
  /* border-right: 1px solid #979797; */
  /* border-width: 5px 0 5px 0; */
  align-items: center;
}

.backGrey {
  background: #e4e4e4;
}

.accountPopMenuSearch {
  position: absolute;
  top: -25px;
  right: 72px;
}

.fullWidthSearc {
  width: 100%;
}

.accountMenuOpenMobile {
  width: fit-content;
  background-color: white;
  position: absolute;
  top: 75px;
  min-width: 370px;
  /* border-radius: 2px; */
  right: -50px;
  font-size: 12px;
  z-index: 2;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  color: black;
  background: #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.searchDiv {
  /* padding: 7px 9px; */
  display: flex;
  height: 20px;
  /* border-right: 1px solid #979797; */
  justify-content: center;
  align-items: center;
}

.backGrey {
  background: #e4e4e4;
}

.paginantionHeaderSelect {
  width: 235px;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .accountPopMenu {
    position: absolute;
    top: -30px;
    right: -21px;
  }
  .accountPopMenuSearch {
    position: absolute;
    top: -33px;
    right: 51px;
  }
  .accountMenuOpenMobile {
    width: 100vw;
    top: 74px;
    min-width: auto;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .accountPopMenu {
    position: absolute;
    top: -15px;
    right: -13px;
  }
  .fullWidthSearc {
    width: 0%;
  }
  .tabletviewMinWidth {
  }
  .accountPopMenuSearch {
    position: absolute;
    top: -13px;
    right: 72px;
  }

  .paginantionHeaderSelect {
    min-width: 268px;
  }
  /* .searchRed {
        display: none;
    } */
}

@media screen and (min-width: 901px) {
  /* .searchGrey{
        display: none;
    } */
}

.hwSearch {
  height: 36px;
  width: 27px;
}
.hwTabletSearch {
  height: 42px;
  width: 29px;
}
