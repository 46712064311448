.myAccountHeader{
    display: flex;
}
.myAccountBody{
    display: flex !important;
    min-height: calc(100% - 353px);
    /* height: 100%; */
    /* height:100vh */
}
.just{
    justify-content: space-between;
    max-width: 70px;
}