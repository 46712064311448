.quickOrderoutDesktop {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    min-width: 53%;
  }
  
  .quickOrderFormComponent {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background-color: white;
    padding: 40px 8px;
    min-height: calc(100% - 437px);
  }
  
  .quickOrderoutSpan1Desktop {
    margin-top: 6px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #07080c;
  }
  .minWid {
    min-width: 150px;
    width: max-content;
  }
  
  .quickOrderTableHeadingDesktop {
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
  }
  
  .paddRight {
    padding-right: 20px;
  }
  
  .quickOrderTableDesktop {
    margin-top: 60px;
    /* width: 100%; */
    font-size: 11px;
    letter-spacing: 1px;
    padding-top: 14px;
    padding-bottom: 7px;
    border-top: 1px solid var(--transparent-gray, rgba(97, 94, 92, 0.15));
    margin-bottom: 1%;
    border-bottom: 1px solid var(--transparent-gray, rgba(97, 94, 92, 0.15));
  }
  .quickOrder__buttonDesktop {
    color: white;
    letter-spacing: 0.8px;
    min-width: fit-content;
    background-color: #ce0e2d;
    padding: 11px 20px;
    margin-right: 7px;
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */
  
    display: flex;
    align-items: center;
    text-align: center;
  
    color: #ffffff;
  }
  .quickOrder__buttonDesktop :h {
    background-color: darkred;
    transition: 0.5s;
  }
  
  .quickOrder__buttonGreyDesktop {
    color: white;
    font-size: 10px;
    letter-spacing: 0.8px;
    font-weight: 600;
    min-width: fit-content;
    background-color: #615e5c;
    padding: 11px 20px;
    margin-right: 7px;
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .quickOrder__buttonGreyMobile {
    width: 100%;
  }
  .quickOrder__buttonGreyDesktop:hover {
    background-color: #111926;
  }
  
  .quickOrder__buttonWhiteDesktop {
    color: rgb(0, 0, 0);
    font-size: 13px;
    cursor: pointer;
    font-weight: 700;
    margin-left: -1%;
    font-size: 13px;
  }
  

  .quickOrder__buttonWhitePlusDesktop {
    color: #ce0e2d;
    font-size: 30px;
    margin-right: 20px;
    align-items: center;
    display: flex;
    margin-bottom: 0%;
    padding-right: 0%;
    /* margin-left: 1%; */
    justify-content: center;
  }
  
  .quickOrderButtonAddDesktop {
    display: flex;
    cursor: pointer;
    margin-top: 1%;
    margin-bottom: 2%;
    margin-left: 3%;
    align-items: center;
  }
  
  .quickOrderButtonSectionDesktop {
    width: 100%;
  }
  
  .quickOrderTableRightButtonsDesktop {
    display: flex;
  }
  
  .quickOrderBottomButtonsDesktop {
    display: flex;
    border-top: 2px solid black;
    padding: 6px 0px;
    padding-bottom: 75px;
    justify-content: space-between;
  }
  
  .quickBorderDesktop {
    border-bottom: 2px solid black;
    width: 252%;
    margin-top: 4%;
  }
  
  .quickOrderTableInputDesktop {
    padding: 5px 9px;
    border: none;
    outline: transparent;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    height: 40px;
    /* width: 90%; */
    display: flex;
    align-items: center;
  }
  
  .quickOrderTableQuantityDivDesktop span {
    /* border: 1px solid #e8e7e7; */
  }
  .react-numeric-input {
    /* width: 100%; */
  }
  .someBorder{
    border: 1px solid #DBDADA;
  }
  .noRightMargin{
    margin-right: unset;
  }
  
  .quickOrderTableQuantityDesktop {
    border: none;
    /* max-width: 84px; */
    /* max-width: 134px; */
    outline: none;
    width: 100%;
    text-align: center;
    padding: 11px 11px;
    min-width: 105px;
    border-radius: 0px !important;
    /* padding: 12px; */
    /* border: 1px solid #e8e7e7 !important; */
    font-style: normal;
    font-weight: 600 ; 
    /* font weigh amd size issue in shop engine */
    font-size: 14px ;
    line-height: 36px;
    /* identical to box height, or 257% */
    color: var(--Brand-Colors-Black, #07080C);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    border: 1px solid #e8e7e7;
    font-weight: 400;
    line-height: 36px; /* 150% */
    text-transform: uppercase;
    text-align: center;
    text-transform: uppercase;
  }
  .fontForQuickorder{
    color: var(--Brand-Colors-Black, #07080C) !important;
text-align: center !important;
font-family: Montserrat !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 500 !important;
line-height: 36px !important;
text-transform: uppercase !important;
  }
  
  .updateQuant {
    border: 2px solid var(--ilmorRed) !important;
  }
  .updateQuantTool {
    font-size: 15px;
    width: fit-content;
  }
  .quickOrderMobileQuant {
    border: 1px solid #e8e7e7;
  }
  /* @media only screen and (min-width:901px) { */
  
  .react-numeric-input :nth-child(2),
  .react-numeric-input :nth-child(3) {
    border-radius: 0px !important;
    border: none !important;
    box-shadow: none !important;
    right: 1px !important;
    margin-top: 14px;
    background-color: white !important;
    width: 2.26ex !important;
    /* top: 2px !important;
      bottom: 21% !important;*/
    font-size: 25px !important;
    /* border: 1px solid red !important; */
  }
  
  .react-numeric-input :nth-child(3) {
    margin-top: 2px;
  }
  /* } */
  
  .react-numeric-input-btn {
    font-family: "FontAwesome" !important;
    font-size: 12px !important;
  }
  
  .react-numeric-input-btn-up:before,
  .react-numeric-input-btn-down:before {
    content: "" !important;
  }
  
  .react-numeric-input-btn-up:before {
    content: "\f077" !important;
  }
  
  .react-numeric-input-btn-down:before {
    content: "\f078" !important;
  }
  
  /* @media screen and (min-width:0px) and (max-width:600px) {
      .accountPopMenu {
          position: relative;
          top: -59px;
          right: -22px;
      }
  }
  @media screen and (min-width:601px) and (max-width:900px) {
      .accountPopMenu{
          position: relative;
          top: -59px;
          right: -1px;
      }
  } */
  .quickOrderTableRowDesktopEvenRow {
    background-color: rgba(247, 247, 247, 1);
  }
  
  .quickOrderTableDesktop td {
    padding: 7px 8px;
  }
  .shopPrice{
    /* padding-top: 15px; */
  }
  .quickOrderTableDesktop th {
    text-align: left !important;
    padding: 14px 8px;
    /* width: 25%; */
  }
  .quickPriceHeading {
    padding-left: 25px !important;
  }
  
  td {
    text-align: left;
    /* padding: 6px; */
    /* width: 25%; */
  }
  
  th {
    /* padding-bottom: 2%; */
    border-bottom: 2px solid #000 !important;
  }
  .headBorder {
    border-top: 1px solid rgba(97, 94, 92, 0.15);
  }
  .shoppingListTableHeadRowDesktop th {
    padding: 8px 0px;
  }
  td:nth-child(2) {
    /* padding-right: 60px; */
  }
  
  table {
    border-spacing: 0px;
  }
  
  tbody {
    text-align: initial;
  }
  
  .quickOrderTablePriceDesktop {
    font-size: 13px;
    font-weight: 700;
  }
  
  .quickOrderTableStatusDesktop {
    font-size: 11px;
    font-weight: 700;
  }
  
  .quickOrderoutMobile {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  
  .quickOrderoutSpan1Mobile {
    padding: 4px 17px;
    text-align: left;
  }
  
  .quickOrderBottomButtonsMobile {
    border: none;
  }
  
  .quickOrderBottomTopButtonsMobile {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  
  .quickOrder__buttonMobile,
  .quickOrder__buttonGreyMobile {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quickOrder__buttonGreyMobile{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  .quickOrderTableRightButtonsMobile {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .textLeft {
    text-align: left !important;
  }
  .quickOrderButtonAddMobile {
    margin-top: 4%;
    margin-bottom: 5%;
    padding-left: 3%;
  }
  
  .quickOrderTablePriceMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;
  }
  
  .quickOrderCheckAvailMobile {
    width: 100%;color: #FFF!important;
    text-align: center!important;
    /* Text sm/Bold */
    font-family: Montserrat !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important;
  }
  
  .quickCross {
    cursor: pointer;
    width: 25px;
  }
  .marBot {
    /* margin-bottom: 14px; */
  }
  .partNUmberBox {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #e8e7e7;
    padding-right: 25px;
  }
  .notAvailable {
    border: 1px solid var(--ilmorRed);
  }
  .notAvailableText {
    color:  #EB1648 !important;
  }
  .shoppingListTablepriceMessage {
    color: #EB1648!important;
    font-family: Montserrat !important;
    font-size: 12px !important;
    font-style: italic !important;
    font-weight: 500 !important;
    line-height: 120% !important;
  }
  .redCartDiv {
    width: 17px;
    height: 16px;
    background: linear-gradient(
      to top left,
      transparent calc(50% - 1px),
      var(--ilmorRed) calc(50% - 1px),
      var(--ilmorRed) calc(50% + 1px),
      transparent calc(50% + 1px)
    );
  }
  
  .quickOrderTableInputDivMobile {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: baseline;
  }
  .quickOrderTableQuantityMobile {
    min-width: 78px;
  }
  