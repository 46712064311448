.carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8% 0%;
}

.control-arrow{
    opacity: 1!important;
    background: rgba(0,0,0,0.2)!important;
}

.control-arrow {
    background: none!important;
}
.carousel.carousel-slider .control-arrow:hover {
    background: none!important;
}
li.thumb {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    /* width: 90px!important; */
}
ul.thumbs {
    display: flex;
    justify-content: center;
}
.carousel .thumb {
    cursor: pointer;
    padding: 10px!important;
    border: 1px solid var(--transparent-gray, rgba(97, 94, 92, 0.15))!important;
}
.carousel .thumb.selected{
    border: 2px solid var(--brand-colors-ilmor-red, #CE0E2D)!important;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
    .carousel .thumb img {
        vertical-align: top;
        width: auto !important;
        height: 40px !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .carousel .thumb {
        white-space: nowrap;
        width: 90px !important;
        height: 70px !important;
        overflow: visible !important;
    }
    .carousel .thumb img {
        vertical-align: top;
        width: 71px !important;
        height: auto px !important;
    }
    .axis-vertical{
        background: rgb(255,255,255);
        background: linear-gradient(253deg, rgba(255,255,255,1) 11%, rgba(255,255,255,0) 59%);
    }

    .carousel .thumbs::-webkit-scrollbar {
      width: 12px;
      background-color: white;
    }
    
    .carousel .thumbs::-webkit-scrollbar-track {
      background: white;
    }
    
    .carousel .thumbs::-webkit-scrollbar-thumb {
      background: white;
    }

    .carousel .thumbs::-webkit-scrollbar:horizontal {
      background-color: white;
      height: 12px;
    }
    
}
.carousel .control-next.control-arrow:before {
    /* border-left: 8px solid #CE0E2D!important; */
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #CE0E2D!important;
}


.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
    border:0 !important;
    background: url('../Images/back.svg') no-repeat;
    background-size: 100%;
    width: 50px;
    height: 50px;
    opacity: 0.6;
}


.carousel .control-arrow.control-next:before, .carousel.carousel-slider .control-arrow.control-next:before{
   transform: rotate(180deg);
}
.carousel .control-arrow:hover:before, .carousel.carousel-slider .control-arrow:hover:before{
    opacity: 1
}

