.main {
  /* width: 1000px !important; */
  padding: 10px 40px 60px 40px;
}

.Txtarea{
  width:100%;
  /* width:668px; */
  height: 180px;
  padding: 16px;
  border: none;
  resize: none;
}
.hrLineBreak{
  display: flex;
  margin-top: 30px;
  border-color: #d1d1d033;
}
.exemption{
  margin: 0;
  padding-top: 30px;
  height: 100px;
  margin-bottom: -50px !important;
}
.btn_cancel{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  margin-top: 20px;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 0.5px solid  #58585733;
  margin-right: 15px;
}

.TE_button{
  margin: 20px;
  border: none;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* background-color: #615E5C; */
  background-color: transparent;
}
.TE_button:hover {
  color:var(--ilmorRedHover);
  cursor: pointer;
}

.uploadDoc{
  margin-bottom: 10px;
}
.dropzone {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.uploadButton {
  display: flex;
  padding: 12px 20px !important;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--medium-gray, #615E5C) !important;
  border-radius: 0 !important;
}

.uploadButtonText{
  color: #FFF !important;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: capitalize !important;
}
.uploadButton:hover {
  background-color: rgb(5, 5, 5) !important;
}

.datebg{
  border: none;
  position: relative;
  margin-left: 0;
  /* margin-right: 20px; */
  /* width: 230px; */
  /* background: white; */
  /* padding: 10px; */
}

.datebg svg{
  position: absolute;
    top: 0;
    /* z-index: 2; */
    bottom: 0;
    margin: auto;
    left: 13px;
}

.datebg .react-datepicker-wrapper{
  padding: 10px 20px;
  background-color: #fff;
  width: 100%;
  margin-right: 1%;
}

.dateHeading{
  margin-bottom: 10px;
  /* font-size: 10px; */
  width: 100%;
}

.datebg .react-datepicker-wrapper input{
  border: 0 !important;
  background: transparent
}
@media screen and (min-width:0px) and (max-width:600px){
  .dateHeading {
    font-size: 8px;
  }
  .main {
    /* width: 1000px !important; */
    padding: 10px 0px 60px 0px !important;
  }
  .ilmorButon {
    font-size: 13px;
  }
  .ilmorButon.addTax {
    margin-right: -15px;
  }
  .ilmorButon.setPassword {
    font-size: 13px;
    margin-right: -12px;
  }
}

.svgClose {
  width: 14px;
  height: 20px;
  margin-left: 10px;
}

.svgClose path{
  stroke: #ce0e2d;
  fill: #ce0e2d;
}

.error {
  color: #ce0e2d;
}