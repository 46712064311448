.taxexmp_main {
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    /* padding-right: 20px; */
    /* width: 100vw; */
  }
  
  .taxexmp_centerComponent {
    display: flex;
    width: 829px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    background-color: rgb(216, 212, 212);
    /* padding-left: 20px; */ 
    padding: 0px 24px 24px 24px;
    /* shadow-l */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12),
      0px 1px 20px 0px rgba(0, 0, 0, 0.35), 0px 1px 16px 0px rgba(0, 0, 0, 0.16);
  }
  
  .taxexmp_header {
    display: flex;
    /* padding: 40px; */
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 24px 0px 24px 0px;
    position: relative;
    border-bottom: 0.5px solid #31313033;;
  }
  .taxexmp_headerTitle {
    color: #07080c;
    font-size: 24px;
    padding-bottom: 0px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  /* .taxexmp_closeBtn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .taxexmp_closeBtnIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: var(--brand-colors-ilmor-red, #ce0e2d);
  } */
  .taxexmp_body {
    display: flex;
    /* height: 520px; */
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: auto;
    overflow:visible !important;
  }
  @media screen and (min-width:391px) and (max-width:768px){
    .addToCart_main{
      padding: 0px 25px;
    }
  }
  
  @media screen and (min-width:0px) and (max-width:600px) {
    .addToCart_header{
      padding: 45px 4px;
    }
    .addToCart_footer{
      padding: 10px 0px;
    }
    .addToCart_estimateCartTotal{
      font-size: 12px;
    }
    .addToCart_continueShopBtn{
      padding: 5px 0px;
    }
    .addToCart_viewCartBtn{
      padding: 5px 5px;
    }
    .addToCart_viewCartLabel{
      font-size: 14px;
    }
    .addToCart_shoppingList{
      width: 100%;
    }
    .addToCart_main{
      padding: 0px 10px;
    }
  }

  .hrLineBreak{
    display: flex;
    margin-top: 30px;
    border-color: #68686433;
  }
  

  .taxexmp_Txtarea{
    height: 180px;
    padding: 16px;
    border: 1px;
    /* margin-right: 30px; */
    width: 100%;
    resize: none;
  }
  
  .taxexmp_exemption{
    margin: 0;
    height: 100px;
    margin-top: 0px;
    margin-bottom: -80px !important;
  }
  .taxexmp_btn_cancel{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    justify-content: end;
    /* margin-top: 20px; */
    /* justify-content: flex-end; */
    /* padding-left: 50%; */
    /* padding-top: 20px; */
    border-top: 0.5px solid  #31313033;
  }
  .taxexmp_dropzone {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .taxexmp_can_button{
    margin: 20px;
    border: none;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    background-color: transparent;
    border-top: 0.5px solid  #d1d1d033;
  }
  .taxexmp_can_button:hover {
    color: #ce0e2d;
    cursor: pointer;
  }
  .taxexmp_uploadDoc{
    margin-bottom: 10px;
  }
  .taxexmp_uploadButton{
    display: flex;
    padding: 12px 20px !important;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: var(--medium-gray, #615E5C) !important;
    border-radius: 0 !important;
    /* width: 150px;
    color: white !important;
    border-radius: 0 !important;
    background-Color:rgb(87, 85, 85) !important; */
  }

  .taxexmp_uploadButtonText{
    color: #FFF !important;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize !important;
  }
  .taxexmp_uploadButton:hover {
    background-color: rgb(5, 5, 5) !important;
  }
  .taxexmp_svgClose{
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .taxexmp_svgClose path{
    stroke: red;
    fill: red;
  }
  .taxexmp_datebg{
    border: none;
    position: relative;
    margin-left: 0;
    width: 30px;
}
  .taxexmp_datebg svg{
    position: absolute;
      top: 0;
      /* z-index: 2; */
      bottom: 0;
      margin: auto;
      left: 5px;
  }
  
  .taxexmp_datebg .react-datepicker-wrapper{
    padding: 10px 20px;
    background-color: #fff;
    margin-right: 1%;
  }
  
  .taxexmp_dateHeading{
    margin-bottom: 0;
    font-size: 10px;
    /* width: 100%; */
  }
  
  .taxexmp_datebg .react-datepicker-wrapper input{
    border: 0 !important;
    background: transparent
  }
  @media screen and (min-width:0px) and (max-width:390px){
    .taxexmp_dateHeading{
      font-size: 8px;
    }
  }