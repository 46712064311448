/*=============>DESKTOP CSS  <=================*/

.findParts__mainDesktop {
  /* height: 20vh; */
  /* width: 100vw; */
  background-image: linear-gradient(
    to right,
    rgb(17, 26, 39) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
  /* background-color: rgb(17,50,68); */
  display: flex;
  font-family: Montserrat;
  flex-wrap: wrap;
}

.findParts__findpartsDesktop {
  /* width: 60vw; */
  /* border: 1px solid pink; */
  display: flex;
  /* padding: 24px !important; */
  padding-left: 145px !important;
  flex-direction: column;
  padding: 10px 0px 17px 0px;
}

.findParts__title2Desktop {
  width: 60vw;
  /* border: 1px solid red; */
  /* height: 8vh; */
  display: flex;
  flex-direction: row;
  /* margin-left: 20%; */
}

.findParts__h2Desktop {
  letter-spacing: 2px;
  font-size: 16px;
  color: rgb(255, 255, 255);
}

.findParts__h2bDesktop {
  letter-spacing: 2px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-left: 1%;
}

.findParts__boxesDesktop {
  display: flex;
  /* margin-left: 17%; */
  align-items: center;
  gap: 18px;
  flex-wrap: wrap;
  /* padding: 3%; */
  /* margin-top: 1%; */
}

.findParts__boxDesktop {
  min-width: 100px;
  /* margin-top: -5%; */
  text-align: center;
  height: 46px;
  /* margin-right: 10px; */
  border: none;
  display: flex;
}

.findParts__boxDesktop [class*="-control"] {
  background-color: rgb(15, 37, 52);
  border: 1px solid white;
  color: white;
  min-width: 175px;
  border-radius: 0px;
  min-width: 214px;
  font-weight: 600;
  box-shadow: none !important;
}

.findParts__boxDesktop [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.findParts__boxDesktop [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  outline: none;
}

.findParts__boxDesktop [class*="-placeholder"] {
  color: white !important;
  text-align: left;
  /* width: 167px; */
  font-weight: 700;
}
.findParts__boxDesktop [class*="-ValueContainer"] {
  /* width: 103px; */

  min-width: 135px;
}
.findParts__boxDesktop [class*="-Input"] {
  /* width: 103px; */

  /* min-width: 155px; */
}

.findParts__boxDesktop [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.findParts__boxDesktop [class*="-menu"] [class*="option"] {
  background-color: transparent;
  color: white;
}

.findParts__boxDesktop [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;

  background-color: transparent;
  color: white;
  max-height: fit-content;
  /* width: 174px; */
  text-align: center;
}
.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.findParts__boxDesktop [class*="-indicatorSeparator"] {
  display: none;
}

.findParts__boxDesktop [class*="-indicatorContainer"] {
  color: white;
}

.findParts__boxDesktop [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.findParts__boxDesktop [class*="-singleValue"] {
  color: white;
  /* min-width: 167px; */
  text-align: left;
}

.findParts__btnDesktop {
  /* margin-top: -5%; */
  height: auto;
  width: 155px;
  text-align: center;
  letter-spacing: 1px;
  background: #ce0e2d;
  border: none;
  color: rgb(255, 255, 255);
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}

.viewPartsActive {
  stroke: #fff;
  line-height: 18px !important;
  font-size: 13px !important;
  font-family: Montserrat !important;
  font-weight: 600 !important;
}

/*=============> MOBILE CSSS<==========================*/

.findParts__mainMobile {
  /* height: 350px; */
  /* width: 100vw; */
  /* background-color: linear-gradient(
    to right,
    rgb(17,26,39) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  ); */
  background-color: rgb(17, 50, 68);
  padding: 9px 0px 80px 0px;
  font-family: Montserrat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.findParts__findpartsMobile {
  width: 100%;
  /* width: 100vw; */
  /* border: 1px solid pink; */
  /* display: flex;
  flex-direction: column; */
}

.findParts__title2Mobile {
  padding-left: 20px;
  /* width: 80vw;

  margin-left: -14%; */
  /* border: 1px solid red; */
  /* height: 8vh; */
}

.findParts__h2Mobile {
  letter-spacing: 2px;
  font-size: 16px;
  /* margin-left: -3%; */
  color: rgb(255, 255, 255);
}

.findParts__h2bMobile {
  letter-spacing: 2px;
  font-size: 16px;
  margin-top: -3%;
  /* margin-left: 6%; */
  color: rgb(255, 255, 255);
}

.findParts__boxesMobile {
  display: flex;
  flex-direction: column;
  /* margin-left: 18%; */ 
  padding: 0px 20px;
  gap: 9px;
  justify-content: center;
  align-items: center;
}

.findParts__boxMobile {
  height: 50px;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  background: rgb(17, 50, 68);
  color: rgb(255, 255, 255);
}

.findParts__boxMobile [class*="-control"] {
  background-color: rgb(15, 37, 52);
  border: 1px solid white;
  color: white;
  min-width: 175px;
  border-radius: 0px;
  font-weight: 600;
  box-shadow: none !important;
}

.findParts__boxMobile [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.findParts__boxMobile [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
}

.findParts__boxMobile [class*="-placeholder"] {
  color: white;
  font-weight: 600;
  justify-self: left;
}

.findParts__boxMobile [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}

.findParts__boxMobile [class*="-menu"] [class*="option"] {
  background-color: transparent;
}

.findParts__boxMobile [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
}
.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.findParts__boxMobile [class*="-indicatorSeparator"] {
  display: none;
}

.findParts__boxMobile [class*="-indicatorContainer"] {
  color: white;
}

.findParts__boxMobile [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.findParts__boxMobile [class*="-singleValue"] {
  color: white;
}

.findParts__btnMobile {
  /* margin-top: 5%; */
  height: 40px;
  /* width: 350px;
   */
  width: 100%;
  border: none;
  text-align: center;
  letter-spacing: 1px;
  background: #ce0e2d;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 15px;
  gap: 16px;
}

/*==================>> TABLET CSS<======================================*/

.findParts__mainTablet {
  height: 100px;
  /* width: 100vw; */
  /* background-color: linear-gradient(
    to right,
    rgb(17,26,39) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  ); */
  background-color: rgb(17, 50, 68);
  display: flex;
  font-family: Montserrat;
}

.findParts__findpartsTablet {
  /* width: 60vw; */
  width: 92%;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.findParts__title2Tablet {
  /* width: 60vw; */
  display: flex;
  flex-direction: row;
  margin-left: 7%;
}

.findParts__h2Tablet {
  letter-spacing: 2px;
  font-size: 15px;
  color: rgb(255, 255, 255);
}

.findParts__h2bTablet {
  letter-spacing: 2px;
  font-size: 15px;
  color: rgb(255, 255, 255);
  margin-left: 1%;
}

.findParts__boxesTablet {
  display: flex;
  margin-left: 7%;
  margin-top: -15px;
  flex-direction: row;
}

.findParts__boxTablet {
  margin-top: -4%;
  margin-left: 5px;
  /* height: 4vh; */
  /* width: 18vw; */
  text-align: center;
  /* letter-spacing: 1px; */
  background: rgb(17, 50, 68);
  color: rgb(255, 255, 255);
  width: 25%;
}

.findParts__boxTablet [class*="-control"] {
  background-color: rgb(15, 37, 52);
  border: 1px solid white;
  color: white;
  width: 100%;
  border-radius: 0px;
  box-shadow: none !important;
}

.findParts__boxTablet [class*="-control"]:hover {
  border: 1px solid white;
  box-shadow: none !important;
}

.findParts__boxTablet [class*="-control"]:focus {
  color: white;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  font-weight: 600;
}

.findParts__boxTablet [class*="-placeholder"] {
  color: white;
  font-weight: 600;
  font-size: 13px;
}

.findParts__boxTablet [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
  font-weight: 600;
}

.findParts__boxTablet [class*="-menu"] [class*="option"] {
  background-color: transparent;
  font-weight: 600;
}

.findParts__boxTablet [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  background-color: grey;
  color: white;
  max-height: fit-content;
  font-weight: 600;
}
.header__select [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.findParts__boxTablet [class*="-indicatorSeparator"] {
  display: none;
}

.findParts__boxTablet [class*="-indicatorContainer"] {
  color: white;
}

.findParts__boxTablet [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

.findParts__boxTablet [class*="-singleValue"] {
  color: white;
  font-size: 13px;
}
.findParts__btnTablet {
  margin-left: 0px;
  /* margin-top: -7px; */
  text-align: center;
  letter-spacing: 1px;
  border: none;
  background: #ce0e2d;
  color: rgb(255, 255, 255);
  width: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*===========> Disable Css=========================*/
.findPartsBoxdisabled {
  cursor: not-allowed !important;
  /* opacity: 0.25; */
}
.findPartsBoxdisabled [class*="-control"] {
  /* border: 1px solid gray; */
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border: 1px solid white;
}
.findPartsBoxdisabled [class*="-placeholder"] {
  color: gray;
  font-weight: 600;
}
.findPartsBoxdisabled [class*="-indicatorContainer"] {
  color: gray;
}
.findPartsBtndisabled {
  background: rgba(97, 94, 92, 0.2);
  color: var(--medium-gray, #615e5c);
  cursor: not-allowed;
  border: none;
}

@media screen and (max-width: 600px) {
  .findParts__boxesMobile > div {
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }
  .findParts__btnMobile {
    width: 100%;
    margin-top: 5px;
  }
}

[class*="findParts__"] > div [class*="-control"] {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  width: 100%;
  border-radius: 0px;
  box-shadow: none !important;
}
[class*="findParts__"] > div [class*="-placeholder"] {
  color: #fff !important;
  font-weight: 600;
}

[class*="findParts__"] > div.findPartsBoxdisabled [class*="-placeholder"] {
  color: hsl(0, 0%, 50%) !important;
}
[class*="findParts__"] > div.findPartsBoxdisabled [class*="-control"] {
  border: 2px solid hsl(0, 0%, 50%);
}

[class*="findParts__"]
  > div.findPartsBoxdisabled
  [class*="-control"]
  [class*="-singleValue"],
[class*="findParts__"] > div.findPartsBoxdisabled [class*="-control"] > *,
[class*="findParts__"] > div.findPartsBoxdisabled svg {
  color: hsl(0, 0%, 50%);
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .findParts__boxesTablet {
    gap: 10px;
  }
}
@media screen and (min-width: 1024px) {
  [class*="findParts__"] > div [class*="-control"] {
    min-width: 172px;
    max-width: 200px;
  }
}

[class*="findParts__"] > div [class*="-control"] [class*="-singleValue"],
[class*="findParts__"] > div [class*="-control"] > *,
[class*="findParts__"] > div svg {
  color: #fff;
}
