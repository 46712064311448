.ShippingAddressbackGround {

  display: flex;

  background: #eaeaea;
  padding: 33px 50px;
  width: 100%;
}

@media screen and (min-width:601px) and (max-width:900px) {
  .ShippingAddressbackGround {
    padding: 40px;
  }
}

@media screen and (min-width:0px) and (max-width:600px) {
  .ShippingAddressbackGround {
    padding: 20px;
  }

}

.ShippingouterContainer {

  height: auto;
  width: 100%;

  
}


.buttonGroup .MuiButton-root:not(:last-child)::after{
  content: "";
    background-color: #07080C;
    margin: 0 0px 0 8px;
    width: 1px;
    height: 13px;
    display: inline-block;
    margin-top: 2px;
}

