/* =================>Desktop CSS<================================*/

.loginPage__mainDesktop {
  display: flex;
  font-family: Montserrat;
  align-items: center;
  height: 100vh;
  justify-content: center;
  /* margin-top: 150px;
 margin-bottom: 150px; */
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
}

.loginContainerDesktop {
  padding: 2%;
  width: 566px;
  transform: scale(0.9);
  /* height: 632px; */
}

.logoDesktop {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-imageDesktop {
  height: auto;
  width: 30%;
  padding: 10px;
}

.info__mainDesktop {
  background-color: rgb(247, 247, 247);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  margin-top: -2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headingBoxDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 84%;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border-bottom-color: rgb(247, 247, 247);
}

.headingDesktop {
  margin-top: 60px;
  /* letter-spacing: 2px; */
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #07080c;
}

.formDesktop {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -30px;
  width: 75%;
}

.emailLabelDesktop {
  /* width: 20%; */
}

.upBtnDesktop {
  margin-bottom: 60px;
  margin-top: 12px;
}

.labelDesktop {
  margin-bottom: 0%;
  letter-spacing: 2px;
  font-size: 10px;
  display: flex;
}

.usernameWarningContainerDesktop {
  color: #ce0e2d;
  display: flex;
  flex-wrap: wrap;
}

.warningDesktop {
  display: flex;
  flex-wrap: wrap;
  font-size: 60%;
}

.passwordLabelDesktop {
}

.passwordWarningDesktop {
  margin-top: 2%;
  color: #ce0e2d;
}

.usernameInputDesktop {
  width: 100%;
  height: 42px;
  margin-top: 1%;
  font-size: 15px;
  padding: 0px 15px;
  border: none;
}
.usernameInputDesktop:focus-visible {
  /* border: none; */
  outline: none;
}

/* CSS for checkbox */
.checkBoxDesktop {
  display: flex;
  align-items: center;

  margin-left: -12px;
  margin-top: 4px;
  /* display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px; */
}

/* Hide the browser's default checkbox */
.checkBoxDesktop input {
  /* position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; */
}
/* Create a custom checkbox */
.checkmark {
  /* position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff; */
  /* border: 2px black solid; */
}

/* On mouse-over, add a grey background color */
.checkBoxDesktop:hover input ~ .checkmark {
  /* background-color: #fff; */
}

/* When the checkbox is checked, add a White background */
.checkBoxDesktop input:checked ~ .checkmark {
  /* background-color: #fff; */
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  /* content: "";
  position: absolute;
  display: none; */
}
/* Show the checkmark when checked */
.checkBoxDesktop input:checked ~ .checkmark:after {
  /* display: block; */
}

/* Style the checkmark/indicator */
.checkBoxDesktop .checkmark:after {
  /* left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg); */
}

.rememberMeDesktop {
  /* margin-top: 2.7%; */
  margin-left: 2%;
  letter-spacing: 0.5;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #06070a;
}

.btnFrgtPwDesktop {
  display: flex;
  flex-direction: row;
  height: 60%;
  margin-top: 3%;
  justify-content: space-between;
}

.signInBtnDesktop {
  width: 88px;
  height: 42px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 100%;
  min-width: 100px;
  background: #ce0e2d;
  color: rgb(247, 247, 247);
  letter-spacing: 1px;
  border: none;
}
.signInBtnDesktop:hover {
  background-color: darkred;
  transition: 0.5s;
}

.sinDealer {
  margin-top: 16px;
  width: 156px;
}
.sinDealerDiv {
  justify-content: center;
}

.forgotPasswordLabelDesktop {
  /* margin-left: 53%; */
}

.forgetPwDesktop {
  text-decoration: none;
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  width: 116px;
  height: 18px;
  display: flex;
}
.forgetPwDesktop:hover {
  color: var(--ilmorRedHover);
}
.lineBreak__mainDesktop {
  width: 425px;
  height: 0px;
  border: 1px solid rgba(97, 94, 92, 0.15);
  margin-top: 18px;
}

.footerInfo_Desktop {
  padding-bottom: 35px;
  width: 75%;
}

.infoDesktop {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.contactIlmoreDesktop {
  text-decoration: underline;
  color: black;
}

.retailPartsStoreDesktop {
  text-decoration: underline;
  color: black;
}

.invalidCredMessageDesktop {
  color: #ce0e2d;
  font-size: 15px;
  margin-bottom: 7%;
  padding: 0px 15px;
}

/*===========> Mobile Css===================*/

.loginPage__mainMobile {
  display: flex;
  font-family: Montserrat;
  align-items: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  /* margin-top: 111px; */
  /* margin-bottom: 111px; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
}

.loginContainerMobile {
  padding: 6%;
  /* width: 100%;  */
  /* margin-top: 15%; */
}

.logoMobile {
  background-color: white;
  width: 100%;
  padding: 25px 25px 28px 25px;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-imageMobile {
  height: auto;
  width: 50%;
}

.info__mainMobile {
  background-color: rgb(247, 247, 247);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headingBoxMobile {
  /* border: 1px solid white; */
  border-bottom-color: rgb(247, 247, 247);
}

.headingMobile {
  /* letter-spacing: 2px; */
  width: 350px;
  margin-top: 50px;
  height: 29px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
}

.formMobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* margin-top: -30px; */
  width: 90%;
}

.emailLabelMobile {
  width: 155px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #07080c;
  margin-bottom: 4px;
}

.labelMobile {
  margin-bottom: 0%;
  letter-spacing: 2px;
  font-size: 80%;
  display: flex;
}

.usernameWarningContainerMobile {
  color: #ce0e2d;
  display: flex;
  flex-wrap: wrap;
}

.warningMobile {
  display: flex;
  flex-wrap: wrap;
  font-size: 20%;
}

.emailMobile {
}

.passwordLabelMobile {
  width: 155px;
  /* height: 12px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #07080c;
  margin-bottom: 2px;
  border: none;
  outline: none;
}

.usernameInputMobile {
  height: 42px;
  width: 100%;
  border: 1px;
  border-radius: 0px !important;
  padding: 0px 15px;
}
.usernameInputMobile.setPassword {
  width: 310px;
}
.usernameInputMobile:focus-visible {
  /* border: none; */
  outline: none;
}

.checkBoxMobile {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: -12px;
}

.rememberMeMobile {
  /* margin-top: 4%;
  margin-left: 2%;
  margin-top: 1%;
  margin-left: 2%; */
  width: 280px;
  height: 17px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #06070a;
}

.btnFrgtPwMobile {
  display: flex;
  flex-direction: row;
  margin-top: 8%;
  justify-content: space-between;
}

.signInBtnMobile {
  height: 40px;
  cursor: pointer;
  padding: 2%;
  font-size: 100%;
  background-color: #ce0e2d;
  color: rgb(247, 247, 247);
  letter-spacing: 1px;
  border: none;
}
.signInBtnMobile:hover {
  background-color: darkred;
  transition: 0.5s;
}

.forgotPasswordLabelMobile {
  margin-left: 30%;
}

.forgetPwMobile {
  text-decoration: none;
  color: black;
  margin-top: 8%;
  width: 116px;
  height: 18px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #07080c;
}
.lineBreak__mainMobile {
  width: 302px;
  height: 0px;
  border: 1px solid rgba(97, 94, 92, 0.15);
  margin-top: 20px;
}

.footerInfo_Mobile {
  padding-bottom: 35px;
}

.infoMobile {
  width: 308px;
  height: 64px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  /* text-decoration-line: underline; */
  color: #000000;
}

.contactIlmoreMobile {
  text-decoration: underline;
  color: black;
}

.retailPartsStoreMobile {
  text-decoration: underline;
  color: black;
}

/*=============> TABLET CSS <=======================*/

.loginPage__mainTablet {
  display: flex;
  font-family: Montserrat;
  align-items: center;
  height: 100vh;
  justify-content: center;
  /* margin-top: 120px;
  margin-bottom: 120px;
  margin-left: 390px;
  margin-right: 437px; */
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
}

.loginContainerTablet {
  width: 553px;
  height: auto;
  margin-top: 190px;
  margin-bottom: 190px;
}

.logoTablet {
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-imageTablet {
  height: auto;
  width: 30%;
  padding: 10px;
}

.info__mainTablet {
  background-color: rgb(247, 247, 247);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  margin-top: -1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headingBoxTablet {
  /* border: 1px solid white; */
  margin-bottom: -12px;
  border-bottom-color: rgb(247, 247, 247);
  /* height: 28%; */
}
.headingBoxTablet.setPassword {
  border: 0 !important;
}
.headingTablet {
  /* border: 1px solid sienna; */
  margin-top: 60px;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  color: #07080c;
  color: #07080c;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.formTablet {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* margin-top: -30px; */
  width: 75%;
}

.emailLabelTablet {
  /* margin-left: 9%; */
  /* margin-top: 1%; */
  width: 100%;
  display: flex;
  /* border: 1px solid brown; */
}

.emailTablet {
  /* margin-top: 5%; */
}

.passwordLabelTablet {
  /* margin-left: 13%; */
  /* margin-top: 1%; */
  width: 20%;
  /* border: 1px solid brown; */
}

.usernameInputTablet {
  border: 1px solid rgba(97, 94, 92, 0.15) !important;
  width: 100%;
  border: 1px;
  height: 42px;
  margin-top: -4%;
  border-radius: 0px !important;
  font-size: 15px;
  padding: 0px 15px;
}
.usernameInputTablet:focus-visible {
  /* border: none; */
  outline: none;
}

.checkBoxTablet {
  display: flex;

  align-items: center;
  margin-left: -10px;
  /* margin-left: 13%;
  margin-top: 1%; */
}

.rememberMeTablet {
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #06070a;
}

.btnFrgtPwTablet {
  display: flex;
  flex-direction: row;
  height: 60%;
  margin-top: 3%;
  justify-content: space-between;
}

.signInBtnTablet {
  width: 88px;
  height: 42px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 100%;
  min-width: 100px;
  background: #ce0e2d;
  color: rgb(247, 247, 247);
  letter-spacing: 1px;
  border: none;
}
.signInBtnTablet:hover {
  background-color: darkred;
  transition: 0.5s;
}

.forgotPasswordLabelTablet {
  /* margin-left: 30%; */
}

.forgetPwTablet {
  text-decoration: none;
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  width: 116px;
  height: 18px;
  display: flex;
}
.lineBreak__mainTablet {
  width: 425px;
  height: 0px;
  border: 1px solid rgba(97, 94, 92, 0.15);
  margin-top: 24px;
}

.footerInfo_Tablet {
  /* border: 1px solid thistle; */
  padding-bottom: 35px;
  width: 78%;
}

.infoTablet {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.contactIlmoreTablet {
  text-decoration: underline;
  color: black;
}

.retailPartsStoreTablet {
  text-decoration: underline;
  color: black;
}

.invalidCredMessageTablet {
  color: #ce0e2d;
  font-size: 15px;
  padding: 0px 64px;
  color: #ce0e2d;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.usernameInputwarning {
  border: 1px solid #ce0e2d !important;
}

.invalidCredMessageMobile {
  color: #ce0e2d;
  padding: 0px 30px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}
