.card__innerdivDesktop {
  display: flex;
  /* align-items: baseline; */
  flex-direction: column;
  /* user-select: none; */
  width: 100%;
  height: 100%;
  padding: 0px 20px;
}

.cardDesktop {
  background-color: #ce0e2d;
  user-select: text;
  overflow: hidden;
}
.opacityCard {
  min-height: 53px !important;
  margin-top: -25px;
}

.card__imageDesktop {
  height: auto;
  width: 200px;
  max-width: 184px;
  max-height: 109px;
  object-fit: contain;
  /* min-height: 118px; */
  margin: auto;
}
.inputBoxNUmber .react-numeric-input {
  width: 100%;
}
.dpHidden {
  height: 10px;
  visibility: hidden;
}

/* .cardDesktop {
  height: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background: #fff;
  box-sizing: border-box;
} */

.cardDesktop {
  /* width: 268.9px; */
  min-height: 362px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 2px; */
  padding: 16px 0 0 0px;
  border: 1px solid #E8E7E7;
  border-radius: 0px;
  background: #fff;
  box-sizing: border-box;
  justify-content: end;
  /* border: 1px solid red; */
  align-items: stretch;
  height: 100%;
}

.card__imageDivDesktop {
  padding: 10px 0px;
  margin-top: 15px;
  margin-bottom: 20px;
  transition: margin 0.5s;
}

.bottomBoxDesktop {
  opacity: 0;
  height: 0%;
  display: none;
  width: 105%;
  margin-top: -5px;
  background-color: #f7f7f7;
  border: 1px solid white;
  padding: 7px 12px;
  border-width: 0px 2px 4px 2px;
  display: flex;
  align-items: center;
  width: 100%;
  transition: height 0.5s;
  justify-content: center;
}
.opacityCard:hover{
  background: var(--ilmorRedHover) !important;
}

.bottomBoxDesktop .MuiFormControl-root .MuiInputBase-formControl {
  border-radius: 0px;
  height: 43px;
}

.bottomBoxDesktop .MuiFormControl-root .MuiInputBase-formControl:hover {
  pointer-events: none;
}

.outlineBox {
  align-items: center;
  justify-content: center;
  display: flex;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

.cardParentDesktop {
  cursor: pointer;
  min-width: 256px;
  flex-grow: 1;
  flex-basis: calc(22%);
  max-width: 33.33%;
  /* margin: 10px;  */
}

@media screen and (min-width: 1307px){
  .cardParentDesktop {
    max-width: 25%;
    /* margin: 10px;  */
  }
}

@media screen and (max-width: 1051px) {
  .cardParentDesktop {
    cursor: pointer;
    min-width: 33.33%;
    /* min-width: auto !important; */
    /* max-width:unset; */
    /* flex-basis: calc(16%); */
    /* flex-grow: 1; */
    /* max-width: calc(33% - -2px); */
  }
}

.cardParentDesktop:hover .bottomBoxDesktop {
  opacity: 1;
  height: 58px;
  display: flex;
}

.inputBoxDesktop {
  width: 70%;
  font-size: larger;
  font-weight: 700;
  height: 34px;
  text-align: unset;
  outline: none;
  border: none;
}

.articleBoxDesktop {
  color: white;
  padding: 6px;
  background-color: #615e5c;
}

.shoppingBoxDesktop {
  color: white;
  margin-left: 3px;
  padding: 6px;
  background-color: #ce0e2d;
}

.cardParentDesktop:hover .card__imageDivDesktop {
  margin-top: 0px;
  margin-bottom: 0px;
}

.card__newTextDesktop {
  background-color: var(--ilmorOrange);
  border-radius: 12px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  color: var(--white, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  max-width: 40px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 6px;
}

/* ----------------------Tablet CSS ------------------------*/

.card__innerdivTablet {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  /* user-select: none; */
  width: 200px;
}

.cardTablet {
  background-color: #ce0e2d;
}

.card__imageTablet {
  height: auto;
  width: 185px;
  object-fit: contain;
  height: auto;
  width: 101px;
  min-height: 107px;
}

.cardTablet {
  width: 256px;
  height: 313px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 2px; */
  padding: 16px 0px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background: #fff;
  box-sizing: border-box;
}

.card__imageDivTablet {
  padding: 10px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: margin 0.5s;
}

.inputBoxNUmber {
}

.inputBoxNUmber.inputWidth {
  /* margin-bottom: 4px; */
  width: 100%;
  /* width: 1300px; */
}

.bottomBoxTablet {
  align-items: center;
  opacity: 1;
  display: flex;
  width: 100%;
  margin-top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  padding: 11px 12px 8px 12px;
  border: 2px solid white;
}

.outlineBox {
  align-items: center;
  justify-content: center;
  display: flex;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

/* .cardParentTablet:hover .bottomBoxTablet {
  opacity: 1;
  display: flex;
} */
.inputBoxTablet {
  width: 70%;
  font-size: larger;
  font-weight: 700;
  height: 34px;
  text-align: unset;
  outline: none;
  border: none;
}

.articleBoxTablet {
  color: white;
  padding: 4px;
  background-color: #615e5c;
}

.shoppingBoxTablet {
  color: white;
  margin-left: 3px;
  padding: 4px;
  background-color: #ce0e2d;
}

/* .cardParentTablet:hover .card__imageDivTablet {
  margin-top: 0px;
  margin-bottom: 0px;
} */

.card__newTextTablet {
  background-color: #ce0e2d;
  font-size: 10px;
  color: white;
  padding: 2px 6px;
  background-color: var(--ilmorOrange);
  border-radius: 12px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  color: var(--white, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 6px;
}

/* ````````````````````````````Mobile Css ```````````````````````````````````*/

.card__innerdivMobile {
  display: flex;
  /* align-items: baseline; */
  padding: 0px 8px;
  width: 100%;
  flex-direction: column;
  /* user-select: none; */
}

.bgForCart {
  height: 42px;
  min-width: 42px;
  background: var(--medium-gray, #615e5c);
  margin-right: 2px;
}

.backgrounDArkRemove{
  height: 42px;
  min-width: 42px;
  /* background: var(--medium-gray, #615e5c); */
  margin-right: 2px;
}

.bgForCartRed {
  height: 42px;
  min-width: 42px;
  background: var(--ilmorRedHover, #615e5c);
  margin-right: 2px;
}

.cardMobile {
  background-color: #ce0e2d;
}

.card__imageMobile {
  height: auto;
  width: 133px;
  min-height: 111px;
  margin-left: 60px;
}

.cardMobile {
  width: 390px;
  height: 313px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 2px; */
  padding: 16px 0px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background: #fff;
  box-sizing: border-box;
}

.card__imageDivMobile {
  /* padding: 3px 60px; */
  margin-top: 0px;
  margin-bottom: 0px;
  transition: margin 0.5s;
}

.bottomBoxMobile {
  align-items: center;
  opacity: 1;
  display: flex;
  width: 100%;
  margin-top: 7px;
  background-color: #f7f7f7;
  padding: 9px;
}

.outlineBox {
  align-items: center;
  justify-content: center;
  display: flex;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

/* .cardParentMobile:hover .bottomBoxMobile {
  opacity: 1;
  display: flex;
} */
.inputBoxMobile {
  width: 80%;
  font-size: larger;
  font-weight: 700;
  height: 34px;
  text-align: unset;
  outline: none;
  border: none;
}

.articleBoxMobile {
  color: white;
  padding: 4px;
  background-color: #615e5c;
}

.shoppingBoxMobile {
  color: white;
  margin-left: 3px;
  padding: 4px;
  background-color: #ce0e2d;
}

/* .cardParentMobile:hover .card__imageDivMobile {
  margin-top: 0px;
  margin-bottom: 0px;
} */

.card__newTextMobile {
  background-color: var(--ilmorOrange);
  border-radius: 12px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  color: var(--white, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  max-width: 41px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 6px;
}

.headingText {
  font-size: 13px;
  font-weight: 700;
  color: #615e5c;
}

.subheadingText {
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.backordered {
  color: #ce0e2d;
  font-size: 13px;
  font-weight: 700;
}

.price {
  font-weight: 800;
  font-size: 24px;
}

.discountedPrice {
  font-size: 17px;
  margin-left: 6px;
  text-decoration: line-through;
}

.headigSubText {
  font-size: 12px;
  margin-left: 8px;
  font-weight: 100;
  color: #615e5c;
  color: var(--medium-gray, #615e5c);
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.mt {
  margin-top: 3px;
}

.mt5 {
  margin-top: 5px;
}

.cardMinHeightDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: text;
  min-height: 116px;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .inputBoxNUmber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
  }
  .cardParentDesktop {
    min-width: unset !important;
    flex-direction: unset !important;
    max-width: 50% !important;
    width: 50% !important;
    flex-basis: 50% !important;
  }
  .quickOrderTableQuantityDesktop {
    max-width: unset !important;
    width: 100%;
  }
  .svgBox{
    width: unset;
  }
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .cardParentDesktop {
    min-width: unset !important;
    flex-direction: unset !important;
    max-width: 50% !important;
    width: 50% !important;
    flex-basis: 50% !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 428px) {
  .cardParentDesktop {
    min-width: unset !important;
    flex-direction: unset !important;
    max-width: 100% !important;
    width: 100% !important;
    flex-basis: 100% !important;
  }
}
