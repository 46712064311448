.shoppingListoutDesktop {
  display: flex;
  flex-direction: column;
  /* border-top: 2px solid #07080C; */
  align-items: baseline;
  /* align-items: flex-end; */
  min-width: 53%;
  width: 100%;
  max-width: 1060px;
  /* align-items: flex-end; */
}
.carMobile {
  border-top: 2px solid black;
}
.cartButon {
  width: 100%;
}
.shopListRow1Image {
  width: 100px;
  margin-right: 16px;
  /* background: transparent url('../Images/load.gif') center no-repeat; */
  min-width: 100px;
  object-fit: contain;
  max-width: 125px;
  mix-blend-mode: multiply;
  height: 82px;
}

.shopRow1 {
  display: flex;
  align-items: center;
  min-height: 85px;
}

.shopRow1Cart {
  margin-top: 10px;
  display: flex;
  /* border-bottom: 1px solid var(--Light-Gray-2, #eaeaea); */
  align-items: center;
}

.shoppingListTableHeadRowDesktop {
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
}

.leftSideShop,
.rightSide {
  align-items: center;
  display: flex;
}
.ordeDetailWhiteRow {
  background-color: white;
}

.iLmorborder {
  border: 1px solid rgba(97, 94, 92, 0.15) !important;
  width: 251px;
}

.sortText {
  color: var(--brand-colors-black, #07080c);
  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  margin-right: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ilmorButon {
  color: white;
  font-size: 13px;
  font-weight: 700;
  background-color: #ce0e2d;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.shoppingListTableHeadingDesktop {
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  /* text-align: center; */
}
.shoppingListTableHeadingDesktop.part {
  text-align: left !important;
}

.selectlistTopDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.marginTop {
  margin-top: 16px;
  padding-left: 8px;
}

.shoppingListTableDesktop {
  margin-top: 5px;
  /* width: 100%; */
  font-size: 11px;
  letter-spacing: 1px;
  /* display: flex; */
  flex-direction: column;
  padding-top: 14px;
  width: 100%;
  padding-bottom: 7px;

  /* table-layout: fixed; */
  /* word-break: break-word; */
  /* border-top: 1px solid grey; */
  /* margin-bottom: 1%; */
  /* border-bottom: 2px solid grey; */
}

.widthForTable {
  /* width: 67%; */
  border: none;
}

/* .widthForTableAtc{
      width: 100%;
      border: none;
  } */

.shoppingList__buttonDesktop {
  color: white;
  letter-spacing: 0.8px;
  min-width: fit-content;
  background-color: #ce0e2d;
  padding: 11px 20px;
  margin-right: 7px;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.shoppingList__buttonGreyDesktop {
  color: white;
  font-size: 10px;
  letter-spacing: 0.8px;
  font-weight: 600;
  min-width: fit-content;
  background-color: #615e5c;
  padding: 11px 20px;
  margin-right: 7px;
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
}

.shopListRow1RightSide {
  display: flex;
  width: 100%;
  padding: 14px 0px;
  flex-direction: column;
  
}
.diplayTag {
  display: flex;
  height: 16px;
  gap: 8px;
}
.shoppingListdateTotaldiv {
  min-width: 90px;
  /* text-align: center; */
}

.shoppingListTableprice {
  color: #07080c;
  text-align: left;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}
.fontforTotoal {
  color: #07080c;
  text-align: left;
  /* Text sm/Bold */
  font-size: 18px !important;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}

table {
  border-collapse: collapse;
}
tr {
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
}

.shoppingListTablestatus,
.shoppingListaddedBy,
.shoppingListTableaddedBy {
  /* color: red; */
  text-align: center;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 120%;
}
.shoppingListTabledate {
  color: #07080c;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 15.6px */
}

.shoppingListSmallTitile {
  color: #615e5c;
  /* Text xs/Bold */
  font-size: 12px;
  font-family: Montserrat;
  text-align: left;
  width: fit-content;
  font-weight: 700;
  line-height: 16px;
  width: 187px;
}

.shoppingListInput {
  padding: 21px 9px;
  border: 1px solid #e8e7e7;
  outline: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
}
.orderDetailQuant .react-numeric-input {
  width: unset;
}
.numericInputOrderDetailDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopListLow {
  display: flex;
  margin-top: 4px;
  /* padding-bottom: 16px; */
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .shoppingListTablestatus {
    /* color: red; */
    text-align: left;
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 120%;
  }

  .shopListLow {
    padding-bottom: 16px;
    padding-left: 12px;
    padding-top: 10px;
  }
}

.shopLiRemove {
  /* padding-right: 10px; */
  cursor: pointer;
  /* border-right: 1px solid black; */
}
.shopLiRemoveCart {
  padding-right: 10px;
  cursor: pointer;
}

.moveCart {
  padding-right: 10px;
  cursor: pointer;
}

.orderRemove {
  border: none;
}

.sepBarDiv {
  margin: 0px 7px;
  color: black;
  font-weight: 600;
  font-size: 14px;
}

.shopLiRemove,
.shopLiMovToCart,
.shopLiRemoveCart {
  color: var(--brand-colors-ilmor-red, #ce0e2d);
  text-align: center;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
}

.shoppingListTitle {
  color: #07080c;
  /* Text sm/Bold */
  font-size: 13px;
  margin-bottom: 2px;
  font-family: Montserrat;
  text-align: left;
  font-weight: 700;
  line-height: 18px;
}

.shoppingListButtonSectionDesktop {
  width: 100%;
}

.shoppingListTableRightButtonsDesktop {
  display: flex;
}

.shoppingListBottomButtonsDesktop {
  display: flex;
  /* border-top: 2px solid black; */
  padding: 16px 0;
  justify-content: space-between;
  /* justify-content: end; */
}

.quickBorderDesktop {
  border-bottom: 2px solid black;
  width: 252%;
  margin-top: 4%;
}

.shoppingListTableInputDesktop {
  padding: 5px 9px;
  border: 1px solid #e8e7e7;
  outline: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  height: 28px;
  width: 90%;
  display: flex;
  align-items: center;
}

.shoppingListTableQuantityDivDesktop span {
  /* border: 1px solid #e8e7e7; */
}
.backOrderedLabel {
  color: var(--brand-colors-ilmor-red, #ce0e2d);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  margin-top: 2px;
  font-weight: 700;
  line-height: 120%; /* 14.4px */
}

.shoppingListTableQuantityDesktop {
  /* border: none !important; */
  /* max-width: 84px; */
  max-width: 76px;
  outline: none;
  text-align: center;
  padding: 10px 11px;
  border-radius: 0px !important;
  padding: 11px;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 36px;
  /* identical to box height, or 257% */

  text-align: center;
  text-transform: uppercase;
}
.lighBorder {
  border: 1px solid #e8e7e7 !important;
}
.shoppingListTableRowDesktopEvenRowaddToCard {
  background: transparent;
}
.shoppingListTableRowDesktop {
  /* cursor: pointer; */

  /* border-bottom: 10px solid red !important; */
}
tr {
  /* border: 1px solid black; */
}
.shoppingListprice {
  /* flex-direction: column; */
  /* display: flex; */
  /* margin-top: 46%; */
  /* text-align: center; */
}
.shopPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shopPriceStatus {
  /* align-items: baseline; */
  text-align: left;
}
.shopPricQuick {
  display: flex;
  align-items: baseline;
  margin-left: 18px;
  flex-direction: column;
}
/* @media only screen and (min-width:901px) { */

.react-numeric-input :nth-child(2),
.react-numeric-input :nth-child(3) {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: none !important;
  right: 1px !important;
  margin-top: 4px;
  background-color: white !important;
  width: 2.26ex !important;
  /* top: 2px !important;
      bottom: 21% !important;*/
  font-size: 25px !important;
  /* border: 1px solid red !important; */
  margin-right: 2px;
}

.react-numeric-input :nth-child(3) {
  margin-top: 0px;
}

/* } */

.react-numeric-input-btn {
  font-family: "FontAwesome" !important;
  font-size: 12px !important;
}

.react-numeric-input-btn-up:before,
.react-numeric-input-btn-down:before {
  content: "" !important;
}

.react-numeric-input-btn-up:before {
  content: "\f077" !important;
}

.react-numeric-input-btn-down:before {
  content: "\f078" !important;
}

/* @media screen and (min-width:0px) and (max-width:600px) {
      .accountPopMenu {
          position: relative;
          top: -59px;
          right: -22px;
      }
  }
  @media screen and (min-width:601px) and (max-width:900px) {
      .accountPopMenu{
          position: relative;
          top: -59px;
          right: -1px;
      }
  } */
.shoppingListTableRowDesktopEvenRow {
  /* align-items: center; */
}
.EvenRow {
  background-color: rgba(247, 247, 247, 1);
}

.shoppingListTableDesktop td {
  /* padding: 17px 22px;W */
}

.orderHisTableRow th {
  text-align: left;
  /* padding: 6px;W */
  /* width: 25%; */
}
.shopListRow1LeftSide {
  cursor: pointer;
}

td {
  /* text-align: left; */
  /* padding: 6px; */
  /* width: 25%; */
}

.orderHisTableRow th {
  padding-bottom: 16px;
  padding-top: 16px;
  /* padding-left: 60px; */
  border-bottom: 2px solid #000;
}

td:nth-child(2) {
  /* padding-right: 60px; */
}

table {
  border-spacing: 0px;
}

tbody {
  text-align: initial;
}

.shoppingListTablePriceDesktop {
  font-size: 13px;
  font-weight: 700;
}

.shoppingListTableStatusDesktop {
  font-size: 11px;
  font-weight: 700;
}

.shoppingListoutMobile {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.shoppingListoutSpan1Mobile {
  padding: 4px 17px;
  text-align: left;
}

.shoppingListBottomButtonsMobile {
  border: none;
}

.shoppingListBottomTopButtonsMobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.shoppingList__buttonMobile,
.shoppingList__buttonGreyMobile {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShoppingListFormComponent {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 60%;
  background-color: white;
  padding: 42px 42px;
}

.shoppingListTableRightButtonsMobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.shoppingListButtonAddMobile {
  margin-top: 4%;
  margin-bottom: 5%;
  padding-left: 8%;
}

.shoppingListTablePriceMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none !important;
}

.shoppingListCheckAvailMobile {
  width: 100%;
}

.shopTableLabel {
  margin-right: 7px;
  color: #07080c;
  text-align: left;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}
.shopTableLabelCart {
  margin-right: 7px;
  color: #07080c;
  margin-bottom: 14px;
  text-align: left;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}

.shopListTabDiv {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.shopListTabDivCart {
  display: flex;
  flex-direction: column;
}

.shopListDetailTablet {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 12px;
  justify-content: space-between;
}

.shoppingListTabledateTotal {
  color: #07080c;
  text-align: right;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 24px;
  /* word-break: break-all; */
}

.shoppingListTablepriceCross {
  color: #615e5c;
}

.removeLabel {
  /* color: var(--ilmorRed) !important;
    text-decoration: underline;
    font-size: 12px; */

  color: var(--brand-colors-ilmor-red, #ce0e2d);
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  text-decoration-line: underline;
  cursor: pointer;
}
.removeLabelDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.requiredInfo_Label {
  color: #000;

  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 200px;
  margin-left: 16%;
}

.requiredInfo_InputBoxes {
  display: flex;
  padding: 8px 0px 16px 130px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.engineSerialBox {
  display: flex;
  width: 255px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 2px;
}

.engineSerialLabel {
  display: flex;
  width: 155px;
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.engineSerialInput {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
}

.hoursBox {
  display: flex;
  width: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 2px;
}

.hoursLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.hoursInput {
  display: flex;
  height: 42px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
}

.startsBox {
  display: flex;
  width: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 2px;
}
.returnOrderCheckBox {
  margin-right: 9px;
}
.startsLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.orderDetailTable {
  border-top: unset;
}
.startsInput {
  display: flex;
  height: 42px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .selectlistTopDiv {
    padding: 24px 0px 9px 0px;
  }
  .headerSelectSortByTablet {
    width: 271px;
  }
  .ShoppingListFormComponent {
    padding: 29px 27px 1px 27px;
  }

  .noborderShop {
    /* border-top: none; */
  }

  .shopListTabDetailsSecMain {
    display: flex;
    justify-content: space-between;
  }

  .shoppingListoutDesktop {
    min-width: 96%;
  }

  .react-numeric-input :nth-child(2),
  .react-numeric-input :nth-child(3) {
    margin-top: 7px;
    /* right: 35px !important; */
    bottom: 27px !important;
  }

  .shopListRow1RightSide {
    width: 100%;
  }

  td:nth-child(2) {
    /* padding-right: 26px; */
  }

  .widthForTable {
    width: 100%;
  }

  .shoppingListInput {
    width: 100%;
  }

  .cardList__newTextDesktop {
    /* margin-right: 6px; */
    background-color: var(--ilmorOrange);
    border-radius: 12px;
    font-size: 10px;
    text-transform: uppercase;
    color: white;
    color: var(--white, #fff);
    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    max-width: 40px;
    line-height: 120%;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 2px 6px;
  }

  .addToCart_footerText {
    gap: 16px;
  }

  .shoppingListTableDesktop {
    width: 100%;
    /* display: unset; */
  }
}

.cartEmptyMessage {
  color: var(--brand-colors-black, #07080c);

  width: 100%;
  /* Display sm/Bold */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding: 27px 25px 14px 15px;
  text-align: center;
  line-height: 120%; /* 19.2px */
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

.carem {
  display: flex;
  min-width: 298px;
}

.shoppingListTablepriceDiscounted {
  color: #615e5c;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 138.462% */
  text-decoration: line-through;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .leftSideShop,
  .rightSide {
    align-items: flex-start;
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .shopListTabDiv {
    display: flex;
    margin-left: 4px;
    align-items: baseline;
    /* gap: 8px; cauing issue in return order*/
  }
  .justifyGrid {
    margin-top: 9px !important;
  }
  .shopTableLabel {
    margin-right: 7px;
    color: #07080c;
    margin-bottom: 8px; /*for return a order */
    text-align: center !important;
  }
  .shoppingListTableprice {
    color: #07080c;
    text-align: center;
    /* margin-top:8px; */
  }
  .sortText {
    margin-bottom: 2px;
  }

  .shoppingListTableDesktop {
    margin-top: 0%;
  }
  .sdfdsfdsfs {
    padding: 1px 4px 1px 9px;
  }

  .shopListRow1Image {
    margin-right: 12px;
  }

  .shoppingListTableDesktop td {
    padding: 0px 5px;
  }

  .shopListRow1LeftSide {
    display: flex;
  }

  .shopListTabDetailsSecMain {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .shopListTabDetailsSec2 {
    margin-top: 8px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    gap: 9px;
  }
  .shopListTabDetailsSec2Cart {
    display: flex;
    align-items: center;
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .shoppingListoutDesktop {
    min-width: 0%;
  }

  .header__select iLmorborder {
    width: 100%;
  }

  .rightSide {
    width: 100%;
  }

  .cardList__newTextDesktop {
    margin-right: 8px;
  }
  .part_align {
    /* margin-right: -11px !important; */
  }
  .shoppingListoutDesktop,
  .shoppingListTableDesktop {
    width: 100%;
  }
  .headerSortByMobile {
    width: 100%;
  }

  .ShoppingListFormComponent {
    padding: 17px 15px;
  }

  .shoppingListTableDesktop {
    border: none;
  }

  .shoppingListTableDesktop {
    padding-top: 0;
  }

  .rightSide,
  .leftSideShop {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
  }

  .shoppingListTableQuantityDesktop {
    max-width: 89px;
  }

  .shoppingListBottomButtonsDesktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    /* padding-right: 15px; */
  }

  .shoppingList__buttonGreyDesktop {
    justify-content: center;
  }

  .shoppingListTableRightButtonsDesktop {
    /* border-top: 2px solid black; */
    padding-top: 16px;
    width: 100%;
  }

  .shoppingList__buttonGreyDesktop {
    width: 100%;
  }

  .widthForTableAtc {
    /* padding: 0px; */
    width: 100%;
  }

  .shopListDetailTablet {
    /* review ordere needs this 16px on top paddding */
    padding: 16px 14px 0px 6px;
    border-top: 1px solid var(--Light-Gray-2, #eaeaea);
  }
  .shopPriceStatus {
    padding-left: 3%;
  }

  .rightSide {
    flex-direction: column;
    align-items: baseline !important;
  }
  .navigationBar {
    flex-direction: row;
  }
  .quickOrderTableDesktop td {
    padding: 7px 7px 7px 7px;
  }
}

.yourCartBtn {
  margin-left: 83%;
}

.yourCartshoppingListBtn {
  margin-right: 0px;
  background-color: #ce0e2d;
}

.sltOfAddToCart {
  margin-top: 0%;
}

.sltOfYourCart {
  margin-top: 0%;
}

.widthForTableAtc {
  /* width: 107%; */
}

.yourCartUpdateCartBtn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
}
.updateCartLabel {
  color: #fff;
  text-align: center;

  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}
.yourCartUpdateCartBtn:hover {
  background: darkred;
}

.requiredINfo {
  color: var(--brand-colors-black, #07080c);
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  margin-bottom: 6px;
  margin-top: 11px;
  font-style: normal;
  display: inline;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}

.requiredINfoClass {
  display: flex;
  align-items: center;
  gap: 2px;
}

.imp {
  font-size: 20px;
  font-weight: 400;
  color: #ce0e2d;
}
