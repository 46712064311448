.yourCart_mainContainer {
  display: flex;
  flex-wrap: wrap;
}
.yourCart_headerContainer {
  /* display: flex; */
  /* flex-direction: column; */
}
.yourCart_bodyContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: white; */
  padding: 5% 8%;
}
.shoppingList_yourCart {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  /* height:calc(100%-300px) */
  /* padding: 5% 7%; */

  /* margin-left: 60px */
}
.orderSummary_yourCart {
  /* padding: 5% 5%; */
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .yourCart_bodyContainer {
    padding: 17px 12px;
    margin-left: -22px !important;
  }
  .yuyu{
   margin: 0px !important; 
    padding: 16px !important
  }
  .yuyu >div {
    margin: 0px !important; 
     padding: 0px !important
   }
  .shoppingList_yourCart {
    /* padding: 0px !important; */
  }
}
/*
@media screen and (min-width:601px) and (max-width:900px) {
    .yourCart_bodyContainer{
        display: flex;
        flex-direction: column;
        background-color: white;
    }
} */
