.addToCart_main {
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  /* width: 100vw; */
}

.addToCart_centerComponent {
  display: flex;
  width: 829px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #fff;

  /* shadow-l */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12),
    0px 1px 20px 0px rgba(0, 0, 0, 0.35), 0px 1px 16px 0px rgba(0, 0, 0, 0.16);
}

.addToCart_header {
  display: flex;
  padding: 40px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  position: relative;
  /* border-bottom: 2px solid var(--brand-colors-black, #07080c); */
}
.addToCart_headerTitle {
  color: #07080c;
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.addToCart_closeBtn {
  width: 40px;
  height: 40px;
  position: absolute;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  right: 0px;
  border-radius: 0px 8px 0px 0px;
  top: 0px;
}
.addToCart_closeBtnIcon {
  width: 37px;
  height: 16px;
  flex-shrink: 0;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
}
.addToCart_body {
  display: flex;
  overflow: scroll;
  /* height: 350px; */
  max-height: 350px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: auto;
}

.addToCart_shoppingList {
  width: 100%;
}

.addToCart_footer {
  display: flex;
  padding: 22px 24px 20px 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  align-self: stretch;
  border-top: 2px solid var(--brand-colors-black, #07080c);
  background: var(--white, #fff);
}

.addToCart_footerText {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addToCart_estimateCartTotal {
  color: #07080c;
  /* Text md/Bold */
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}

.addToCart_totalPrice {
  color: #07080c;
  text-align: right;
  /* Text xl/Bold */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  padding-left: 5px;
  line-height: 120%;
}

.addToCart_footerBtns {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.addToCart_continueShopBtn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--medium-gray, #615e5c);
  border: none;
  margin-left: 20px;
}
.addToCart_continueShopLabel {
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  padding: 0px 8px;
  font-weight: 700;
  line-height: 18px;
}

.addToCart_viewCartBtn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
}
.addToCart_viewCartLabel {
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .addToCart_main {
    padding: 0px 25px;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .addToCart_header {
    padding: 45px 28px;
  }
  .addToCart_footer {
    padding: 10px 12px;
  }
  .addToCart_estimateCartTotal {
    font-size: 12px;
  }
  .addToCart_continueShopBtn {
    padding: 5px 0px;
  }
  .addToCart_viewCartBtn {
    padding: 5px 5px;
  }
  .addToCart_viewCartLabel {
    font-size: 14px;
  }
  .addToCart_shoppingList {
    width: 300px;
  }
  .addToCart_main {
    padding: 0px 10px;
  }
}
