		body {
			/* background-image: url('home.jpg'); */
			/* background-position: top center; */
			/* background-repeat:no-repeat; */
			/* background-color:#000; */
      /* overflow: hidden; */
		}

  body.loading {
      overflow: hidden;
  }

		#loading-wrapper {
			position:fixed;
			top:0;
			left:0;
			height:100vh;
      z-index: 999;
			width:100vw;
			background-color: rgba(0,0,0,0.9);
		}
		#loading-contents {
			width:150px;
			height:150px;
			position:relative;
		}
		#loading-wrapper, #loading-contents {
			display:flex;
			justify-content:center;
			align-items:center;
		}
		#loading-text {
			color:#FFF;
			font-family:'Montserrat', sans-serif;
			font-size:12px;
			text-transform:uppercase;
			text-align:center;
			font-weight:800;
			letter-spacing: 3px;
	    animation-name: textBounce;
	    animation-duration: 4s;
	    animation-iteration-count: infinite;
	    animation-timing-function: cubic-bezier(0.5, 2, 1, 1);
		}

		.ripple {
			position:absolute;
			border-radius: 100px;
			top:50%;
			left:50%;
			width:100px;
			height:100px;
			margin: -50px 0 0 -50px;
			border:3px solid #FFF;
		    animation-name: ripple;
		    animation-duration: 4s;
		    animation-iteration-count: infinite;
		    animation-timing-function: cubic-bezier(0.5, 2, 1, 1.2);
		    opacity:0;
		}
		#ripple1 {
			animation-delay:1s;
		}
		#ripple2 {
			animation-delay:1.3s;
		}
		#ripple3 {
			animation-delay:1.6s;
		}

		/* Animation Keyframes*/
		@keyframes textBounce {
		    0% { transform: scale(1); }
		    20% { transform: scale(1.1); }
		    30% { transform: scale(1); }
		}
		@keyframes ripple {
		    0% { transform: scale(0.1); opacity:0.5; border-width:3px; }
		    90% { transform: scale(1.5); opacity:0.0; border-width:2px; }
		    100% { transform: scale(1.5); opacity:0.0; border-width:2px; }
		}