/* .cart {
    width: 300px;
    height: 160px;
    text-align: left;
    padding-left: 20px;
    background-color: white;
    position: absolute;
    top: 17px;
    min-width: 176px;
    border-radius: 2px;
    right: -14px;
    font-size: 12px;
    z-index: 2;
    font-weight: 600;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    color: black;
    text-align: center;
} */


.cart{
    display: flex;
    width: 400px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 20px 0px rgba(0, 0, 0, 0.35), 0px 1px 16px 0px rgba(0, 0, 0, 0.16);

}

.text{

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    text-align: center;
}
.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15%;
}

.btnCancel{
    color: black;
    background-color: white;
    /* margin-right: 15px;
    width: 130px; */
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}
.btnDelete{
    color: white;
    background-color: red;
    /* width: 130px; */
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
}