.App {
  /* text-align: center; */
  height: 100vh;
}
* {
  box-sizing: border-box;
}
.fdgh467{
  margin-left : 40px;
  margin-top : 28px;
  font-size:20px;
  font-weight:600
}
.liClas{
  margin: 12px 0px;
}
.center344{
  display: flex;
  align-items: center;
}
.ndfgjhhdfj67{
  color: #FFF !important;
  font-family: Montserrat;
  background-color: #EE9209;
  font-size: 12px;
  width: 66px;
  height: 21px;
  border-radius: 17px;
  padding: 46px;
  letter-spacing: 0.02857em;
  padding: 2px 12px !important;
  font-weight: 700;
}
.fedm12n{
  width: 100%;
}
.fdgh467 > div {
  margin-bottom: -76px !important;
}
.jkrteg65{
  
  margin-top: 20px;
  margin-bottom: 20px;
}
.sdfd8633s{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sfs34du53{
  min-width: 42px !important;
}
.dfg473{
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;}
.dfgr4546s{
  right: -24px !important;
}
.dfglkjuji7675{
  margin-top: 48px;
}
.Toastify__close-button{
  margin-top: 14px !important;
}
.personDetail232{
  font-size: 13px;
  overflow-wrap: break-word;
  font-weight: 500;
  line-height: 18px;
  /* font-style: italic; */
  text-transform: none;
  letter-spacing: 0;
}
.personDetail2321{
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 24px;
  overflow-wrap: anywhere;
  line-height: 18px;
  /* font-style: italic; */
  text-transform: none;
  letter-spacing: 0;
}

.seldivrad > div {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.Toastify__close-button {
  margin-top: 14px !important;
}
.labelfont {
  color: var(--Brand-Colors-Black, #07080c);
  font-feature-settings: "clig" off, "liga" off;
  /* forms/label/Bold */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.labelfonttitle {
  color: var(--Brand-Colors-Black, #07080c) !important;

  /* Text sm/Bold */
  font-family: Montserrat !important;
  margin-bottom: 10px !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px; /* 138.462% */
}

.noborlight{
  border: none !important;
}

.labelfonttitle::after {
  content: "*";
  color: red;
}
body {
  font-family: "Montserrat", sans-serif !important;
  font-family: "Roboto", sans-serif;
  font-family: "Ubuntu", sans-serif;
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
  overflow-x: hidden;
}
#svg {
  max-width: 100%;
}
.minwdthSop {
  min-width: 209px;
}
.inputBoxNUmberEngine {
  margin: 0px 5px 0px 20px;
}
.marImage {
  margin: 0px 23px;
}
.backSvg {
  color: var(--brand-colors-black, #07080c);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}
.disabledHeader {
  pointer-events: none;
}
.svgEngineDiv {
  background: white;
}
.noFlex {
  display: flex !important;
}
.backEngineDiv {
  display: flex;
  cursor: pointer;
  gap: 8px;
  margin-top: 17px;
  align-items: center;
}
.topline{
  border-top: 2px solid black;
  padding-top: 24px;
}
.hgjy676{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.noClick{
  cursor:not-allowed !important;
}
.noClick3{
  cursor : default;
}
.hgjy676{
  margin-left: 8px;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .backEngineDiv {
    padding-left: 18px;
    margin-top: 11px;
  }
  .xcfxff23{
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 12px 8px;
  }

  .dggy56dr45{
    margin-left: 21px;
  }
  .sdfds232323de{
    height: calc(100vh -411px) !important;
  }
  .header__leftside__optionsMobile{
    background-color: black;
  }
  .control-arrow , .control-prev{
    margin-left: -18px !important;
    margin-right: -18px !important;
  }
  .carous > img {
    width: 86% !important;
  }
  .accountMenuOpenMobile > div {
    width: 100%;
  }
  .formMobile {
    margin-top: -18px;
  }
  .tabpadorder {
    border-bottom: 2px solid black;
  }
  .Toastify__toast {
    top: 3px !important;
  }
  .jgyu676 {
    padding: 14px 46px 14px 32px !important;
  }
  .partsDetailCorousalComponent .carousel:nth-child(2) {
    max-width: 100vw !important;
  }
  .jdfhgdhjf454 {
    width: 350px;
  }
  .paymetnOptionsPO {
    gap: 14px  !important;
  }
  .xfgth {
    align-items: center !important;
  }
  .dividerclass {
    margin: 28px 0px 20px 0px !important;
  }
  .sdfsdfwegghh {
    margin-top: 7px !important;
  }
  .paymentethodStatus button {
    margin-right: 10px;
  }
  .mainTileDiv.MuiGrid-item > div {
    height: 179px;
    padding: 0px 20px 0px 20px;
  }
  .borno {
    padding: 0px 20px 0px 20px !important;
  }
  .image {
    width: 182px;
    height: auto;
    margin-bottom: 7px;
  }
  .browseCategorylabel {
    margin-top: none !important;
  }
  .mobilebot > div {
    box-shadow: none !important;
  }
  .coupandiscounfont {
  }
  .mediaqueryEstmatedTotalMob {
    color: #07080c !important;
    text-align: right !important;

    /* Text sm/Medium */
    font-family: Montserrat !;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important; /* 138.462% */
  }
  .addToliStMobileCenterr {
    padding-left: 3px !important;
  }
  .addToCartMobileCenterr {
    padding-left: 9px !important;
  }
  .mobileNewDiVPAdding {
    padding-left: 3px !important;
  }
  .mobiletopPddingQuicOrder {
    padding-top: 6px !important;
  }

  .mobiletopPddingQuicOrderLeftInStock {
    padding-top: 4px !important;
  }
}
.marginleftarro {
  margin-left: 16px !important;
}
.revers {
  transform: rotate(180deg);
}
.findPartsBtndisabled > svg > path {
  fill: #615e5c !important;
}
.react-datepicker__input-container > input {
  width: 100%;
}
.partsCatBtndisabled > svg > path {
  fill: #615e5c !important;
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .backEngineDiv {
    padding-left: 43px !important;
    margin-top: 28px;
  }
  .taxexmp_main{
    padding: 24px;
  }
  .maxWidthbut {
    max-width: 187px !important;
    margin-right: 20px !important;
  }
  .rtrt3 {
    margin-right: unset !important;
  }
  .sdfsdf33634c{
    margin-top: 104px !important;
  }
  .sdfsdf782{
    position :absolute !important;
  }
  .werwer23 {
    width: 172px;
    margin-left: 16px;
  }
  .somprop {
    /* margin-left: -386px !important; */
  }
  .accountMenuOpenMobile > div {
    width: 100%;
  }
  .accountMenuOpenMobile  {
    box-shadow: 1px 6px 6px 0px rgba(0, 0, 0, 0.5) !important;
  }
  

  .formTablet {
    margin-top: 0px !important;
  }
  .forgetPwTablet {
    margin-top: -6px !important;
  }
  .ForgetPw_info__mainTablet {
    padding: 60px 96px 110px 96px !important;
  }
  .quickOrderTableDesktop th {
    padding: 15px 8px 14px 20px !important;
  }
  .inactiveOption {
    margin-right: 4px;
    margin-left: 4px;
  }
  [class^="partsDe_container3_inputBoxPriceCart"] .react-numeric-input input {
    width: 31% !important;
  }
  .dividerclass {
    margin: 44px 0px !important;
  }
  .tabPaddinless {
    padding: 0px !important;
  }
  .paddingLeftForReturn {
    padding-left: 50px !important;
  }
  .paddingfforshipadrestitle {
    padding-top: 4px !important;
    margin-left: 5px !important;
    padding-bottom: 13px !important;
  }

  .passwordRecLastStepTablet {
    margin-bottom: 2px;
  }
  .editInputFieldMArginTAblet {
    margin-top: 10px !important;
  }

  .orderDetailOrderNumberTabletBottom {
    margin-bottom: 9px;
  }
  .marginBottomShippingButtonsTablet {
    margin-bottom: 29px !important;
  }
  .chakUpdateEmailEditClickSignInBtnTablet {
    margin-top: -40px !important;
  }
  .tabletChooseanewPasswordBottomCheck {
    margin-bottom: 19px !important;
  }
}
.backSvg:hover {
  color: var(--ilmorRedHover);
}
:root {
  --boxShadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.5);
  --ilmorRed: #ce0e2d;
  --ilmorRedHover: #900a1f;
  --ilmorOrange: #40a900;
  --lightgray: #d1d1d0;
  --lightGrayBG: #eaeaea;
}

.iconWrapper:hover .icon rect {
  fill: var(--ilmorRedHover) !important;
}

.iconWrapperRed {
  color: white;
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  padding: 12px 14px 12px 0px;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  background-color: var(--ilmorRed);
  display: flex;
  cursor: pointer;
  align-items: center;
}

.iconWrapperGrey {
  color: white;
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  padding: 12px 14px 12px 0px;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  background-color: #615e5c;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.disabledGreyButton {
  opacity: 0.20000000298023224;
  cursor: not-allowed;
  pointer-events: none;
  background: var(--medium-gray, #615e5c) !important;

  letter-spacing: 1px;

  border: none;
  color: #fff;
  text-align: center;
  padding: 0px 0px 0px 0px;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
}
.redGreyButtonBtnQuick {
  padding: none !important;

  background-color: #ce0e2d;
  letter-spacing: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;

  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}
.iconWrapperGrey:hover {
  background-color: #111926 !important;
}

.iconWrapperGrey:hover .icon rect {
  fill: #111926 !important;
}
.iconWrapper {
  color: white;
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  padding: 0px 0px 0px 0px;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  background-color: var(--ilmorRed);
  display: flex;
  cursor: pointer;
  align-items: center;
}
.seqNum {
  background: black;
  color: white;
  scale: 0.8;
  display: flex;
  width: fit-content;
  min-height: 43px;
  font-size: 21px;
  height: 38px;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  padding: 11px;
  border-radius: 32px;
}
.numberSeq {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
}
.carousel .thumbs {
  transform: none !important;
}@media screen and (min-width: 0px) and (max-width: 600px) {
  .carousel .thumbs {
    flex-wrap: wrap !important;
  }
  .thumbs li{
margin-bottom: 2px;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .carousel .thumbs {
    overflow: hidden !important; 
    width: 90vw !important;
    overflow-x: visible !important;
  }
  .someAboc .carousel .thumbs {
    overflow: hidden !important; 
    width: 90vw !important;
    flex-wrap: nowrap !important;
    overflow-x: visible !important;
    justify-content: flex-start !important;
  }
}
.carousel .control-next.control-arrow {
  /* display: none !important; */
}
.carousel .control-prev.control-arrow {
  /* display: none !important; */
}

.partsDetailCorousalComponent
  .carousel:nth-child(2)
  .control-prev.control-arrow {
  display: none !important;
}
.partsDetailCorousalComponent
  .carousel:nth-child(2)
  .control-next.control-arrow {
  display: none !important;
}
.partsDetailCorousalComponent .carousel:nth-child(2) ul {
  /* flex-wrap: nowrap; */
}
.innerdicseq {
  min-width: 22px;
  text-align: center;
}
.iconWrapper:hover {
  background-color: var(--ilmorRedHover) !important;
}
.iconWrapper:hover .icon path {
  fill: white !important;
}

.inputBoxDiv {
  padding-right: 3px;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
  color: var(--brand-colors-black, white);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.Toastify__toast-theme--dark {
  background-color: black !important;
  border-radius: 0 !important;
  box-shadow: var(--boxShadow);
  border: 1px solid var(--ilmorRedHover);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ilmorButon {
  color: white;
  font-size: 13px;
  background-color: #ce0e2d;
  letter-spacing: 1px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}

.ilmorButonGrey {
  color: white;
  font-size: 13px;
  background-color: #615e5c;
  letter-spacing: 1px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}
.ilmorButonGrey:disabled {
  opacity: 0.20000000298023224;
  cursor: not-allowed;
  pointer-events: none;
  background: var(--medium-gray, #615e5c);
}
.allowevent {
  pointer-events: all !important;
}
.ilmorButon:disabled {
  opacity: 0.20000000298023224;
  cursor: not-allowed;
  pointer-events: none;
  background: var(--medium-gray, #615e5c);
}

.ilmorButonWhite {
  color: black !important;
  font-size: 13px;
  background-color: white;
  letter-spacing: 1px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
}

.ilmorButonWhite:hover {
  transition-duration: 500ms;
  color: #900a1f !important;
}
.ilmorRoundGreenButton {
  color: var(--white, #fff) !important;
  text-align: center !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  border-radius: 12px !important;
  background: var(--success, #40a900);
  padding: 2px 12px !important;
  border: none;
}

.ilmorRoundRedButton {
  color: var(--white, #fff) !important;
  text-align: center !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  border-radius: 12px !important;
  background: var(--success, #ce0e2d);
  padding: 2px 12px !important;
  border: none;
}

.ilmorRoundOrangeButton {
  color: var(--white, #fff) !important;
  text-align: center !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  border-radius: 12px !important;
  background: var(--success, #ee9209);
  padding: 2px 12px !important;
  border: none;
}

.ilmorRoundGreyCancelButton {
  color: var(--medium-gray, #615e5c);
  text-align: center !important;
  font-family: Montserrat !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  border-radius: 12px !important;
  background: var(--light-gray-2, #eaeaea);
  padding: 2px 12px !important;
  border: none;
}

.redUnderLinedText {
  color: var(--brand-colors-ilmor-red, #ce0e2d);
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  text-decoration: underline;
}
/* .select [class *='-control']:active,
.select [class *='-control']:focus,
.select [class *='-control']:target,
.select [class *='-control']:focus-visible,
.select [class *='-control']:hover{
  border-color: red !important; 
  box-shadow: none !important;
  outline: none !important;
} */

.labelCheckboxGlobal {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.inputCheckboxGlobal {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

input[type="checkbox"] {
  /* border:1px  solid black !important;  */
  outline: 0px solid red;
  accent-color: white;
  border-radius: 0 !important;
}

input[type="checkbox"]:checked {
  outline: 0px solid black;
  accent-color: white;
  border-radius: 0 !important;
}

input[type="checkbox"]:checked {
  outline: 1px solid black;
  accent-color: white;
}

input:focus-visible,
input:focus {
  outline: none;
}
textarea:focus-visible,
textarea:focus {
  outline: none;
}
button svg {
  margin-right: 16px;
}
.PhoneInputInput {
  border: none;
  height: 42px;
}

.PhoneInput {
}

.ReactPhoneNumInput .PhoneInputInput {
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .tabletEmployee {
    padding: 0px 12px 12px 12px !important;
  }
}

.ReactPhoneNumInputt select.PhoneInputCountrySelect option:hover,
option:checked {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

.ReactPhoneNumInputt select.PhoneInputCountrySelect option:hover,
option:checked {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 100px #1882a8 inset;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.taxExemption-modal {
  align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.carousel-status {
  display: none;
}
.under {
  text-decoration: underline;
  user-select: unset !important;
  color: #ee2a28;
  cursor: pointer;
}
.under:hover{
  transition: 900ms;
  color: black;
}
.headingShop {
  padding: 20px 48px !important;
}

.sideMEnuShop {
  width: 100% !important;
  height: 100%;
}

.caps {
  text-transform: uppercase;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  background-color: black;
}

/* Track */
::-webkit-scrollbar-track {
  background: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
} */
::-webkit-scrollbar:horizontal {
  /* border-radius: 20px; */
  background-color: grey;
  height: 12px;
}

@-moz-document url-prefix() {
  html {
    /* For Firefox */
    /* overflow-y: scroll; */
    scrollbar-color: grey black;
    scrollbar-width: thin;
    -moz-scrollbar-arrow-color: blue;
    /* scrollbar-width: 0px; */
    /* scrollbar-width: none; */
    -moz-appearance: none !important;
  }

  :-webkit-scrollbar-track {
    background: transparent;
  }
}

.fillGreen path {
  fill: #40a900;
}
svg.fillGreen {
  margin-right: 10px;
}

svg.fillRedNonCompatible {
  margin-right: 10px;
  scale: 0.8;
}

.curPoint {
  cursor: pointer;
}

.cartEmpty {
  flex-direction: column;
}

.ilmorButon.big {
  width: 100%;
}
.marbot {
  margin-bottom: 25px;
  margin-top: 25px;
}
.noOrdemessage {
  font-weight: 500;
  margin: 18px 10px;
}
.signinCancel {
  color: black !important;
}
.signinCancel:hover {
  color: var(--ilmorRed) !important;
}

.signinFP {
  color: black !important;
}
.signinFP:hover {
  color: var(--ilmorRed) !important;
}
.dot.selected {
  background-color: black !important;
}
.carousel .control-dots .dot {
  opacity: 1 !important;
  box-shadow: none !important;
  border: 1px solid black !important;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .carousel .control-next.control-arrow {
    right: 5px !important;
  }
}
.opacityCard {
  opacity: 0;
  padding: 10px;
}

.opacityCarddDis{
  opacity: 0;
  padding: 10px;
  /* margin-bottom: 20px ; */


}

.cardDesktop:hover .opacityCarddDis {
  opacity: 1;
  /* height: 100%; */
}


.cardDesktop:hover .opacityCard {
  opacity: 1;
  /* height: 100%; */
}

.shoppingListTableBodyDesktop {
  border-bottom: 2px solid black;
  /* border-top: 2px solid black; getting norder on top in quick iorder page*/
}

.addtocart {
  border-bottom: none;
}
.disableButtonCart {
  cursor: not-allowed !important;
}
.cardWidth {
  width: 58px;
}

tr:last-child {
  /* border-bottom: 0 !important; */
}
.btnViewCart:disabled,
.btnViewCart:disabled:hover {
  background-color: #eaeaea;
  cursor: not-allowed;
}

button[status="Completed"],
button[status="Completed"]:hover,
button[status="completed"],
button[status="completed"]:hover,
button[status="Shipped"],
button[status="Shipped"]:hover,
button[status="shipped"],
button[status="shipped"]:hover {
  background: #40a900 !important;
}

button[status="Returned"],
button[status="Returned"]:hover,
button[status="returned"],
button[status="returned"]:hover,
button[status="Cancelled"],
button[status="Cancelled"]:hover,
button[status="cancelled"],
button[status="cancelled"]:hover {
  background: #a19d9d !important;
}

button[status="Pending"],
button[status="Pending"]:hover,
button[status="pending"],
button[status="pending"]:hover {
  background: #ce0e2d !important;
}

button[status="Processing"],
button[status="Processing"]:hover button[status="processing"],
button[status="processing"]:hover,
button[status="IntiatedReturn"],
button[status="IntiatedReturn"]:hover button[status="intiatedReturn"],
button[status="intiatedReturn"]:hover {
  background: #ee9209 !important;
}

button[status="Completed"],
button[status="Completed"]:hover,
button[status="completed"],
button[status="completed"]:hover,
button[status="Shipped"],
button[status="Shipped"]:hover,
button[status="shipped"],
button[status="shipped"]:hover {
  background: #40a900 !important;
}
.sdfsrfgtdth {
  display: flex;
  align-items: center;
  gap: 3px;
}
.sfdgebry {
  color: #07080c;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 500;
}

button[status="Returned"],
button[status="Returned"]:hover,
button[status="returned"],
button[status="returned"]:hover,
button[status="Cancelled"],
button[status="Cancelled"]:hover,
button[status="cancelled"],
button[status="cancelled"]:hover {
  background: #a19d9d !important;
}

button[status="Pending"],
button[status="Pending"]:hover,
button[status="pending"],
button[status="pending"]:hover {
  background: #ce0e2d !important;
}

button[status="Processing"],
button[status="Processing"]:hover button[status="processing"],
button[status="processing"]:hover,
button[status="IntiatedReturn"],
button[status="IntiatedReturn"]:hover button[status="intiatedReturn"],
button[status="intiatedReturn"]:hover {
  background: #ee9209 !important;
}

.minCard {
  min-width: 64px;
}
.headerSelectSortBy {
  border: 1px solid rgba(97, 94, 92, 0.15);
  min-width: 265px;
  text-align: left !important;
}
.headerSelectApply {
  border: 1px solid rgba(97, 94, 92, 0.15);
  min-width: 290px;
  text-align: left !important;
}

.disableBut {
  opacity: 0.2;
  cursor: not-allowed;
}
.erfree{
  border-top: none !important;
}

.disableDiv {
  pointer-events: none;
  /* cursor: not-allowed !important; */
}
/* .disableDiv:hover {
  pointer-events: auto !important;
} */
.svgDiv {
  /* cursor: pointer; */
  /* scale: 1.2; */
  /* height: 100%; */
}
.svgDiv svg {
  cursor: pointer;
  /* scale: 1.2; */
  height: 100%;
}

.dfgdfrgegr {
  margin-left: 6px;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .svgDiv {
    /* scale: 1.5; */
  }
  .gridBlackContainer > div {
    max-width: 100% !important;
  }
  .taxexmp_main{
    padding: 24px;
  }
  .dfgdfrgegr {
    margin-left: 12px;
  }

  .accountMenuOpenMobile  {
    box-shadow: 1px 6px 6px 0px rgba(0, 0, 0, 0.5) !important;
  }

  .opp {
    margin-left: 3px !important;
  }
  .mobileWidth {
    display: flex !important;
    justify-content: space-between !important;
    padding: 0px 8px !important;
  }
  .orderSearch {
    padding: 0px 8px 16px 10px;
  }
  .searchFieldOrder {
    width: 100%;
  }
  .widthforSearch {
    width: 100%;
  }
  .orderHisTableButtonBar {
    padding: 0px 5px;
  }
  .partimagesidepad {
    margin-top: 4px;
    margin-left: auto !important;
    order: 1;
  }
  .numberSeq {
    gap: 57px !important;
  }

  .updateCartMobileButton {
    width: fit-content;
    justify-content: flex-end;
    /* margin-right: 10px; */
  }
  .sknfvjdjksfb {
    margin-top: 6px !important;
  }
  .pyamnetMOdifyBlackLine {
    border-bottom: 2px solid black;
  }
  .sdewsdfsdsdfsdfsdfsd {
    margin-top: -69px !important;
  }
  .greyHeadingContactIlmor {
    font-size: 30px !important;
  }
  .sdkjbfbkj {    
    /* padding: 28px 15px 28px 58px !important; */
    margin-top: -40px !important;
  }
  .mobilePaddingContactUsilmor {
    padding: 0px 0px 0px 16px !important;
  }
  .fgebdgrbd {
    margin-top: 33px;
  }
}
/* tablet */
.cardWidthameruc{
  width: 61px;
  height: auto;
  object-fit: contain;
}
.sdf35866{
  align-items: center;
}
.americad{
  background-color: white;
  height: 100%;
  padding: 3px;
  border-radius: 4px;
}
.orderPricesMobileRight {
  padding: 16px 14px 16px 0px !important;
}
.accordianOrderCoupan {
  padding-right: 12px;
}
.toppos {
  top: 60px;
}
.actionMobileBulk > div > div > div {
  font-style: unset !important;
  font-weight: 500;
}

.addpaymentcardwidth > div > div > div[class$="singleValue"] {
  color: #06070a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-left: 6px;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .svgDiv {
    /* scale: 1.5; */
  }
  .padBotCart {
    margin-bottom: 26px !important;
  }
  .orderSearch,
  .searchFieldOrder,
  .orderhistor {
    width: 100%;
  }
  .dfgrtertrt6 {
    margin-bottom: 24px !important;
  }
  .gridBlackContainer1 > div {
    max-width: 79% !important;
  }
  .paginationRightSide {
    flex-wrap: wrap;
  }
  .appliedcouapn {
    margin-bottom: 14px !important;
  }
  .gridBlackContainer > div {
    max-width: 36% !important;
  }

  .toppos {
    top: 95px;
  }

  .tabletPaddingCancelEmail {
    margin-top: 10px !important;
  }

  .tabletChooseanewPassword {
    margin-top: 20px !important;
  }

  .paraTabletSignInlast {
    padding: 0px 14px 0px 14px;
  }

  .inputQuickOrderTabletPart {
    margin-left: 4px;
  }
  .inputInnerPAddngQuickOrderTabletPart {
    padding-left: 13px !important;
  }

  .tabletInputPAddingQuantity span input {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .quickOrderBottomButtonsTablet {
    padding-bottom: 6px !important;
  }
  .thWalaPartShoppingListTablet {
    padding-left: 10px !important;
  }
  .tabletImageShoppingListContainerLeft {
    margin-right: 33px;
  }

  .tabeltLineSpacingUpShopping {
    padding-top: 50px;
  }
  .cutToCutTabletBulkActionBottom > div > div > div {
    color: #06070a;

    /* Text md/Medium */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    /* Your styles for the targeted child div of a child div */
  }
}
.headerSelectApplyTablet {
  border: 1px solid rgba(97, 94, 92, 0.15);
  min-width: 200px;
  text-align: left !important;
}
.headerSelectApplyTablet > div > div > div {
  color: #06070a;

  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  /* Your styles for the targeted child div of a child div */
}

.headerSelectApply > div > div > div {
  color: #06070a;

  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  /* Your styles for the targeted child div of a child div */
}
.headerSelectSortBy > div > div > div {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.headerSortByMobile > div > div > div {
  /* color: #06070a; */

  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.headerSelectSortByTablet > div > div > div {
  color: #06070a;

  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  /* Your styles for the targeted child div of a child div */
}
.headerSelectSortByTablet {
  border: 1px solid rgba(97, 94, 92, 0.15);

  text-align: left !important;
}

.headerSelectBottomTablet {
  border: 1px solid rgba(97, 94, 92, 0.15);
  min-width: 194px;
  text-align: left !important;
}

.headerSortByMobile {
  border: 1px solid rgba(97, 94, 92, 0.15);
  min-width: 250px;
  text-align: left !important;
}
.red-border {
  border: 1px solid red !important;
}
.headerBottomMobile {
  border: 1px solid rgba(97, 94, 92, 0.15);
  width: 100%;
  /* min-width:150px; */
  text-align: left !important;
}

.shoppingListTableRightButtonsDesktop .ilmorButon {
  width: 100%;
}
.asdsasdee1 {
  width: unset !important;
  padding-top: unset !important;
}
.lowpaddingformob {
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  margin-top: 0px !important;
}
.dpC {
  display: flex;
  flex-direction: column;
}

.MuiCheckbox-root svg {
  /* background-color: #FFFFFF; */
  /* fill:green; */
  background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill="white" stroke="%23615E5C"/></svg>');
  background-size: cover;
  width: 18px;
  height: 18px;
}
.MuiCheckbox-root svg path {
  display: none !important;
}
.MuiCheckbox-root.Mui-checked svg {
  background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill="white" stroke="%23615E5C" /><path d="M13.8945 4.54688C14.0312 4.41016 14.25 4.41016 14.3594 4.54688L15.1523 5.3125C15.2617 5.44922 15.2617 5.66797 15.1523 5.77734L6.94922 13.9805C6.8125 14.1172 6.62109 14.1172 6.48438 13.9805L2.82031 10.3438C2.71094 10.207 2.71094 9.98828 2.82031 9.87891L3.61328 9.08594C3.72266 8.97656 3.94141 8.97656 4.07812 9.08594L6.70312 11.7383L13.8945 4.54688Z" fill="%2307080C" /></svg> ');
}
.red {
  color: var(--ilmorRed);
}
.reDenter {
  display: flex;
  align-items: center;
}
.dpBlock {
  display: flex !important;
}
.trackNumberText {
  color: var(--brand-colors-black, #07080c);
  /* margin-left: 18px; */
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}
.trckDetail {
  margin: 23px 0px 5px 0px;
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
}
.trackDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.trackNumberDate {
  color: var(--brand-colors-black, #07080c);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.margingwholepart {
  margin: 10px;
}

.parorder {
  border-top: 2px solid black;
  margin-top: 24px;
  padding-top: 24px;
}
.noLine {
  border-top: none;
}
.line {
  border-top: 2px solid black;
  padding-top: 28px;
}
.divSku {
  margin-left: 7px;
  display: flex;
  width: 143px;
  flex-wrap: wrap;
}
.divSkuTile {
  display: flex;
  flex-wrap: wrap;
}
.maxwidSku {
  max-width: 226px;
}
.topImg {
  width: 39px;
  border-radius: 7px;
  cursor: pointer;
  margin-left: 90%;
  margin-top: 10px;
}

.header__white__section > [class*="-container"] {
  width: 200px;
}

.imgLoad {
  width: 50px !important;
  min-width: 50px !important;
}

.loaderDiv {
  width: 150px;
  min-width: 150px;
}

.shoppingListTableaddedBy {
  color: black !important;
}
.blurPage {
  opacity: 0.1;
  pointer-events: none;
  cursor: not-allowed;
}

.gridcss {
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.carous {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 600px; */
  object-fit: contain;
  /* padding: 0 40px; */
}
.orderLoader {
  width: 100%;
  display: flex;
  padding: 97px;
  align-items: center;
  justify-content: center;
}

@keyframes wave-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.cart-summary {
  position: relative;
  width: 200px;
  height: 100px;
}

.total {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  color: #000;
}

.waves {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #fff 0%, #fff 50%, transparent 100%);
  animation: wave-animation 0.5s linear infinite;
}

.rotate {
  transform: rotate(180deg);
}
.mgright {
  margin-left: 16px;
}

.just1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.curp {
  cursor: pointer;
}
.accpart {
  background-color: unset !important;
  border-top: 1px solid rgba(128, 128, 128, 0.5);
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}

.fontForForm {
  /* width: 600px; */
  height: 42px;
  border: none;
  /* margin-top: .5%; */
  font-size: 15px;
  padding: 0px 16px;
  /* border: none; */
}

/* @media screen and (min-width: 1024px) {
  .shoppingListdateTotaldiv {
    min-width: 170px !important;
  }
} */

.blurQuant {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}
.fontHead{
  font-weight: 700;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .mobi {
    width: unset !important;
  }
  .werwer23 {
    /* width: 176px; */
    padding-right: 10px;
    width: 100%;
    /* margin-left: 5px; */
  }
  .dfgdf44fw43 {

  }

  .borderForGrey{
    border-top: 2px solid #E8E7E7;
    border-bottom: 1px solid #E8E7E7;
  }
  .borderForGreyNo{
    border: 1px solid #E8E7E7 !important;
    border-left: none !important;
    border-right: none !important;
  }
}

.loadcoup {
  display: flex;
  align-items: center;
  padding: 0px 28px;
  justify-content: center;
}
.noborderShop {
  border-top: 2px solid #07080c;
}
.noborderShop1 {
  border-top: unset !important;
}
.minwidst {
  width: 200px;
}

/* .carous img{
  width: 350px !important;
} */

.minwidsort {
  width: 168px;
}
.minwidSelOpt {
  min-width: 155px !important;
}
.minwidSelOpt :hover {
  background: var(--ilmorRedHover) !important;
}
.ferltjngrtujik {
  font-weight: 700;
}
.MuiIconButton-label {
  color: black !important;
}

.black {
  color: black !important;
}
.dpcar {
  display: none;
}
.bar {
  width: 100%;
  display: flex;
  height: 81px;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
}
.barthumb {
  width: 63px;
  margin-top: 18px;
  height: 59px;
  border: 2px solid #ce0e2d;
}
.sdffgdf34336{
  margin-left: -12px;
}
.sdf23e2{
  
  margin-bottom: 10px;
}

.carous > img {
  max-height: 638px;
}
.selopt {
}
.seloptdiv {
  margin: 10px 0px;
  font-weight: 500;
  /* color: var(--ilmorRed); */
}
.nomarline {
  margin: 8px !important;
}

.taxexmp_dateHeading,
.taxexmp_uploadDoc {
  margin: 8px 0px;
  min-width: 166px;
}
.divtax {
  display: flex;
  gap: 55px;
  margin-left: 9px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .divtax {
    gap: 15px;
  }
  .trackNumberText {
    color: var(--brand-colors-black, #07080c);
    margin-left: 0px !important;
    /* Text sm/Bold */
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 138.462% */
  }
  .carous {
    padding: unset !important;
  }
  .trackDiv {
    margin-left: 4px;
  }
  .lineOrder {
    width: 100%;
  }
}
.lineOrder {
  box-shadow: none !important;
}
.martopActiveCard {
  margin-top: 16px;
}
.paddiv {
  margin: 11px 0px !important;
}
.currSvgLis {
  background: url(../src/Images/currentEngineSVGLis.png) !important;
}

#rc-anchor-alert,
.rc-anchor-alert {
  display: none;
}
.fontwetstile {
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
.fon {
  font-size: 13px !important;
}
.marbotcon {
  margin-bottom: 23px;
}

.margincaptcha {
  margin: 20px 0px;
}
.col {
  color: var(--ilmorRed) !important;
}
.bortop {
  border-top: 1px solid rgba(97, 94, 92, 0.15);
  padding-top: 24px;
}

.fontcontfomr {
  color: #000;
  text-align: center;

  /* Display sm/Bold */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.orderDetailMessage {
  margin-bottom: 23px;
}

.marbortbor {
  padding-bottom: 14px;
  border-bottom: 2px solid var(--Brand-Colors-Black, #07080c);
}
.lipat {
  max-width: 200px;
  margin-right: 26px;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .accountMenuOpenMobile {
    width: 100vw;
    top: 74px;
    min-width: auto;
  }
  .Pagination {
    padding: 13px 15px !important;
  }
  .paginationRightSide {
    margin-left: 2px !important;
    width: 100%;
    justify-content: space-around;
  }

  .paginationNumberBoxWhole {
    /* margin-left: 20px !important; */
  }
  .paginationNumberBoxWhole {
    margin-left: 0px !important;
  }
  .leftMarginAuto {
    flex-wrap: wrap !important;
  }
  .paginationNumber {
    margin: 5px 0px;
  }
  /* need below padding in case of start return */

  .orderDetailMessage {
    padding-bottom: unset;
    padding-bottom: 10px;
    padding: 40px 0px;
  }

  .partsCat__findpartsCollapseExp {
    font-weight: 700;
  }
  .partsCatBtndisabled {
    /* opacity: 0.25; */
    margin-bottom: 23px;
  }
  .findPartsBoxdisabled {
    /* opacity: 0.25; */
  }
  .nopaddingImportant > div {
    padding: 0 !important;
  }
  .dfgrtertrt6 {
    padding-left: 24px !important;
  }
}
.opbg {
  opacity: 0.1;
}
.padtopformyprofe {
  color: #000;
  /* Display sm/Bold */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  padding-bottom: 6px;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.outdiv {
  background-image: linear-gradient(
    270deg,
    hsl(0deg 0% 92%) 0%,
    hsl(137deg 0% 92%) 1%,
    hsl(137deg 0% 93%) 2%,
    hsl(137deg 0% 93%) 3%,
    hsl(137deg 0% 93%) 4%,
    hsl(137deg 0% 94%) 6%,
    hsl(137deg 0% 94%) 7%,
    hsl(137deg 0% 94%) 9%,
    hsl(137deg 0% 95%) 12%,
    hsl(137deg 0% 95%) 15%,
    hsl(137deg 0% 96%) 19%,
    hsl(137deg 0% 96%) 24%,
    hsl(137deg 0% 96%) 31%,
    hsl(137deg 0% 97%) 43%,
    hsl(137deg 0% 97%) 63%,
    hsl(137deg 0% 98%) 78%,
    hsl(137deg 0% 98%) 86%,
    hsl(137deg 0% 98%) 91%,
    hsl(137deg 0% 99%) 94%,
    hsl(137deg 0% 99%) 97%,
    hsl(137deg 0% 100%) 99%,
    hsl(0deg 0% 100%) 100%
  );
  border-radius: 30px;
  padding: 8px 9px;
  display: flex;

  font-size: 12px;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  align-items: center;
}
.outrit {
  background: rgb(234, 234, 234);
  background: linear-gradient(
    90deg,
    rgba(234, 234, 234, 1) 5%,
    rgba(255, 255, 255, 1) 50%,
    rgba(234, 234, 234, 1) 95%
  );
}
.indiv {
  border-radius: 20px;
  background: var(--Brand-Colors-Ilmor-Red, #ce0e2d);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  color: var(--White, #fff);
  text-align: center;
  /* Display xs/Bold */
  font-family: Montserrat;
  font-size: 12px;
  padding: 9px 12px;
  font-style: normal;
  font-weight: 700;
  width: 200px;
  line-height: 150%; /* 18px */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.fnt {
  color: #07080c !important;
  font-family: Montserrat !important;
  font-size: 13px !important;
  word-break: break-word;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}
.sdsds {
  font-weight: 600;
}
.normaordersummary {
  width: 100%;
}
.popo {
  background: rgb(234, 234, 234);
  background: linear-gradient(
    90deg,
    rgba(234, 234, 234, 1) 0%,
    rgba(255, 255, 255, 1) 39%
  );
}
.noborderquant {
  border-top: unset !important;
}
.mesalig {
  align-items: baseline !important;
}
.nominWidth {
  min-width: unset !important;
}
.leftAlignMsg {
  text-align: left !important;
}
.centerqunt {
  display: flex;
  flex-direction: column;
}
.leftAliquick {
  text-align: left !important;
}
.topbordrno {
  border-top: none !important;
}
.texcen {
  text-align: center !important;
}
.dpflexqty {
  display: flex !important;
}
.btoporderdetail {
  border-top: 2px solid #07080c;
}
.rightforReturn {
  margin-left: 64px;
}
.fuima {
  object-fit: contain;
}
.adbook {
  display: flex;
  gap: 20px;
}
.adbook > div {
  margin-left: 2px;
}
.partsDe_container4_engineNameDesktop {
  margin-left: 12px;
}
.nutpadchoosefile {
  margin-top: 10px;
}
.width100 {
  width: 100%;
}
.drrktyh {
  margin: 2px 14px 8px 6px;
}

.addPayfont {
  color: var(--Brand-Colors-Black, #07080c);
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.evenBackgroundEmployee {
  background: var(--Light-Gray, #f7f7f7) !important;
  border-bottom: 1px solid rgba(97, 94, 92, 0.15) !important;
}

.oddBackgroundEmployee {
  border-bottom: 1px solid rgba(97, 94, 92, 0.15) !important;
  background: var(--White, #fff) !important;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .currEngineName {
    width: unset !important;
  }
  .orderpad {
    padding-top: 15px;
  }
  .sdfdsfsdf {
    margin-top: -13px !important;
  }
  .gridBlackContainer1 > div {
    max-width: 100% !important;
  }
  .padtopformyprofe {
    /* padding-top: 14px !important; */
  }
  .payemntDept {
    min-width: 95px;
  }
  .dfklgnmojer {
    margin-top: -10px !important;
  }
  .paymeth {
    padding: 40px 20px 16px 20px;
  }
  .justidyForOrder {
    justify-content: space-between;
  }
  .headingCssOrder {
    padding-left: 24px !important;
  }
  .shopEngineTitle {
    padding-left: 0px !important;
  }
  .tablastilne > div:last-child {
    border-bottom: 2px solid black;
    margin-bottom: -17px;
  }
  .noborderShop {
    border-top: none;
  }
  .shopPrice {
    margin-right: 22px;
  }
  .addpay {
    padding: 40px 0px 0px 0px;
  }
  .orderDetailMessage {
    margin-bottom: unset;
  }
  .topbottommarging {
    margin-bottom: 16px !important;
    padding-right: 12px;
  }
  .shopListDetailTablet {
    align-items: baseline;
  }
  /* start a return bot margin */
  .billTtile {
    margin-bottom: 12px;
  }
  .partOrderMob {
    margin: 24px 0px 24px 3px;
  }
  .padMobEmC {
  }
  .taxformpad {
    padding: 30px 0px 0px 0px;
  }
  .quickOrderTableDesktop td {
    padding: 7px 6px 7px 7px;
  }
  .mobSpave {
    /* margin-bottom: 8px; */
    min-width: 125px;
  }
  .quickOrderBottomButtonsDesktop {
    padding: 16px 1px 1px 1px !important;
  }
  .quickOrder__buttonDesktop :hover {
    background: #ce0e2d !important;
  }
  .orderpadMod {
    padding: 4px 5px 0px 10px;
  }
  .normaordersummary {
    /* padding: 0px 0px 0px 0px; */

    width: 100%;
  }
  .padingForReviewOrder {
    /* padding: 0px 0px 0px 8px !important; */
    margin-top: 28px !important;
    padding: 0px 0px 0px 0px !important;
  }
  .srstjkhgjktr {
    margin-top: 0px !important;
  }
  .dfglkjbhedrfjh {
    margin-top: -24px !important;
  }
  .noCardPadding {
    margin-top: 24px !important;
  }
  .mobilebot {
    margin-bottom: 0px;
  }
  .swgrghty43 {
    max-width: 235px;
  }
  .styuytu {
    margin-bottom: 4px !important;
  }
  .sdfsduyjyu {
    max-width: 250px;
  }
  .nppadisvh {
    margin-top: -69px;
  }
  .headingShop {
    padding: 20px 20px !important;
  }
  .shopEngineTitle {
    /* margin-top: 10px !important; */
  }
  .asdflkhserjklfh {
    /* margin-top: 16px !important; */
  }
  .fdngl434{

    margin-bottom: 15px !important;
  }
  .sdkfnmcsdl {
    margin-bottom: -7px;
    margin-top: 26px !important;
  }
  .notoppad {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /* border-bottom: 1px solid rgba(97, 94, 92, 0.15); */
  }
  .padbotaccout {
    margin: 16px 0px 30px 0px;
  }
  .nopaddingImportant {
    padding: 0 !important;
  }
  .sdfsdrwewererw {
    border-radius: 0px !important;
  }
  .ordertopPadding {
    margin-top: 40px;
  }
  .padbtomog {
    /* margin-left: 8px; */
    border-top: 2px solid black !important;
    margin: 0px 5px 0px 8px !important;
    padding-top: 16px !important;
    padding: 18px 0px 0px 90px !important;
  }
  .paginationNumberBox:hover {
    background-color: #ce0e2d !important;
  }
  .jdfhgv {
    padding: 0px 0 !important;
  }
  .bordertopForReview {
    border-top: 2px solid black !important;
  }
  .tabOrdeDetaRightMob {
    gap: 16px !important;
  }
  .shoppingListoutDesktop {
    /* margin: 0px 0px 0px 15px; */
  }
  .marginCorrectionForReview {
    margin: 0px 4px 0px 7px;
  }
  .heightShipAdress {
    height: 220px;
    padding: 24px 24px 8px 24px;
  }
  .buttonGroup {
    bottom: 16px !important;
    left: 16px !important;
  }
  .paymentforpay {
    border-bottom: 2px solid black;
  }
  .noTopLine {
    border-bottom: none !important;
  }
  .flexFoPartname {
    display: flex;
  }
  .leftSide {
    display: none;
  }
  .navigation {
    justify-content: center;
  }
  .CheckoutComponent {
    /* padding: 32px 20px; */
    padding: 32px 20px 106px 20px;
    /* height: 747px; */
  }
  .editEmailPasswordClass {
    padding: 25px 0px 0px 0px;
  }
  .heghtforcard {
    height: 218px !important;
  }
  .dfgkfgjhdfiyj112 {
    right: 21px !important;
    bottom: 24px !important;
  }
  .marginforaccontover {
    margin-top: -21px !important;
  }
  .marginbott {
    margin: 0px 14px !important;
  }
  .werf {
    margin-left: -11px !important;
  }
  .paddinadjusment {
    padding: 24px 8px 0px 8px !important;
  }
}
.nopaddinforMobTab {
  padding: 0px !important;
}
.classPORadioMobile {
  padding: 9px 9px 9px 0px !important;
}
.headerSelectApplyTablet .shopTableLabel {
  margin-bottom: 8px;
}
.labelDesktopAddShippingRequiredBefore::before {
  content: " *";
  color: red;
  margin-right: 3px;
}

.dateHeading {
  min-width: 138px;
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .addpay {
    margin-top: -11px;
  }
  .notoppad {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /* border-bottom: 1px solid rgba(97, 94, 92, 0.15); */
  }
  .headerSelectApplyTablet > div > div > div[class*="placeholder"] {
    margin-left: 10px;
  }
  .headerSelectBottomTablet > div > div > div[class*="placeholder"] {
    margin-left: 10px;
  }
  .inputshoplist3 {
    /* margin-left: 2px; */
  }
  .dgfhfg4545 {
    margin: 0px 5px 0px 7px !important;
  }
  .srrflihj {
    padding: 9px 9px 9px 18px !important;
  }
  .SmalllHeading {
    min-width: 309px;
  }
  .asdflkhserjklfh {
    margin-top: 16px !important;
  }
  .fontforSHOp {
    font-size: 24px !important;
    padding: 6px 0px !important;
  }
  .tabletadd {
    justify-content: flex-start !important;
    margin-left: 39px !important;
    margin-bottom: 60px !important;
  }
  .tabletdownpadding {
    margin-bottom: 60px !important;
  }
  .tabletpaddinsigh {
    margin-top: 26px;
  }
  .paddingSighInfoTab {
    margin-top: -25px;
    margin-left: -12px;
    margin-bottom: 13px;
  }
  .headingCssOrder {
    font-size: 40px !important;
  }
  .tabletadd {
    justify-content: flex-start;
    margin-left: 40px;
    margin-bottom: 60px;
  }
  .justifyContentRight {
    justify-content: right !important;
  }
  .sdfsdrwewererw {
    border-radius: 0px !important;
  }
  .justifyGrid {
    justify-content: flex-start !important;
  }
  .padbtomog {
    /* margin-left: 8px; */
    border-top: 2px solid black !important;
    margin: 0px 5px 36px  8px !important;
    padding-top: 16px !important;
    padding: 18px 0px 0px 90px !important;
  }
  .padbotaccout {
    margin-bottom: 28px;
  }
  .tabeltBootomEmailInputPadding {
    margin-bottom: 21px !important;
  }
  .tablastilne > div:last-child {
    border-bottom: 2px solid black;
  }
  .minwidsort {
    width: 239px;
  }
  .shoppingListoutDesktop {
    /* margin: 0px 4px 0px 13px; issue in order detail tab*/
    margin: 0px 4px 0px 0px;
  }
  .pageSort {
    margin-left: 9%;
  }
  .tabletPersonalInfoEmpAccount {
    margin-bottom: -15px !important;
  }
  .quickOrderCheckAvailTablet {
    margin-left: 13px;
  }
  /* .quickOrderFormComponent{
    padding: 40px 40px;
  } */
  .quickOrderInputFormTabletTd {
    padding: 7px 0px 7px 15px !important;
  }
  .shoppingListTableInputDivDesktop {
    padding: 12px 0px;
  }
  .shopListNewPartnameFlex {
    display: flex;
    gap: 4px;
  }
  .xcvg {
    margin-right: 4px;
  }
  .headingShop {
    word-break: break-word;
    flex-wrap: nowrap !important;
    padding: 20px 28px !important;
  }
  .orderDate {
    gap: 48px;
  }

  /* .dateTab{
    
    max-width: 48px;
  } */
  .newwimin input {
    min-width: 171px !important;
  }
  .newordertab {
    padding: 24px 28px 0px 28px !important;
  }
  .orderpad {
    margin-bottom: 24px !important;
  }
  .shoppingListTablepriceDiscounted {
    /* creatign issue in order histtory tablet */
    /* margin-left: 4px !important ; */
  }
  .taxformpad {
    margin: 0px 0px 0px -30px !important;
  }
  .tabpadorder {
    padding: 14px 40px 14px 42px !important;
  }
  .paymenthodtab {
    min-width: 113px;
  }
  .padfortabtac {
    padding: 16px 0px 1px 0px;
  }
  .btop1 {
    padding-top: 11px;
  }
  .oidjrgoild {
    min-height: 124px !important;
  }
  .padingforshop {
    padding-right: 16px !important;
  }
  .dingolf {
    text-align: left !important;
  }
  .paginationNumberBoxWhole {
    margin-left: 10px !important;
  }
  .noBotomObrt {
    border-bottom: none !important;
    border-top: 2px solid black !important;
  }
  .heightShipAdress {
    height: 228px;
    padding: 26px 24px 8px 24px;
  }
  .marginbott {
    margin-top: 36px;
    margin-bottom: 50px;
  }
  .justidyForOrder {
    gap: 26px;
  }
  .leftSideOrder {
    gap: 8px;
  }
  .tabOrderNumber {
    margin-right: 8px;
  }
  .orderDate {
    padding: 10px 1px !important;
    padding-bottom: 9px !important;
  }
  .ordertopPadding {
    margin-top: 46px;
  }
  .paymentforpay {
    border-bottom: 2px solid black;
  }
  .paginationNumber {
    /* margin-right: 20px; */
  }
}
.sdfddsfsdfsd {
  font-weight: 500;
}
/* .Toastify__toast {
  border: 10x solid #ce0e2d !important;
  border-width: 1px 0px 1px 0px !important;
} */

.dflkjgfdk232 {
  position: relative;
  width: 135px;
}


@media screen and (min-width: 901px) {
  /* .sdfsefsee445454 > tr:last-child {
    border-bottom: 2px solid black !important;
  } */

  [class^="partsDe_container3_inputBoxPriceCart"] .react-numeric-input input {
    width: 33% !important;
  }
  .sdfds232323de{
    height: calc(100vh -367px) !important;
  }
  .cardDesktop {
    /* height: unset !important; */
  }
  .currEngineName{
    min-width: 484px;
  }
  .maxWidthbut {
    max-width: 187px !important;
    margin-right: 40px !important;
  }
  .partsDe_nameDesktop{
    margin-bottom: 6px;
  }
  .dsfsdf987{
    margin-top: -12px !important;
  }
  .spcingShip {
    margin-right: 20px !important;
  }

  .ftgrert33 {
    padding: 2px 0px !important;
  }
  .quickOrderTableDesktop td {
    padding: 7px 5px 7px 5px;
  }
  .quickOrderBottomButtonsDesktop {
    padding: 7px 6px !important;
  }
  .shoppingListTableHeadRowDesktop th.part {
    padding: 8px 8px 16px 8px;
  }
  .minWid {
    margin-right: 0px !important;
  }
  /* .kfghjktf3343{
    padding: 0 !important;
  } */
  .df343gdfg {
    margin-left: 10px;
  }
  .fontforTile {
    font-weight: 500 !important;
    font-size: 20px !important;
  }
  .inputBoxDisable {
    min-width: 131px !important;
  }
  .imageReltive {
    bottom: -1px;
  }
  .addpaymentcardwidth [class*="IndicatorsContainer"] {
    margin-right: 6px;
  }
  .kngkjhr656 {
    margin-top: -13px !important;
    margin-bottom: -12px !important;
  }
  .orderhispagpad {
    margin-top: -28px;
    margin-left: -3px;
  }
  .dktnjg567 div {
    scale: 0.9 !important;
  }
  .dktnjg567 path {
    color: #615e5c !important;
  }
  .fgnghnfhng67 {
    padding-top: 35px !important;
  }
  .rtduiogjhr {
    margin-right: 8px !important;
  }
  .cardListParentDesktop {
    padding: 0px 24px 1px 5px !important;
  }
  .forgotPasswordLabelDesktop {
    margin-top: -6px;
  }
  .asdflkhserjklfh {
    margin-top: -10px !important;
  }
  .Txtarea {
    margin-bottom: -20px;
  }
  .dateHeading {
    margin-bottom: 5px;
  }
  .btn_cancel {
    padding-top: 35px;
  }
  .orderhispagpad {
    padding: 8px !important;
  }
  .viewPartsActive:hover {
    background-color: var(--ilmorRedHover);
  }
  .gridAlignContactUs {
    /* padding-top: 7px !important; */
  }
  .gridAlignContactUs h2{
    margin: 10px 0 !important;
  }
  .orderhistor {
    min-width: 322px !important;
  }
  .dividerclass {
    margin: 44px 0px !important;
  }
  .tabpadorder {
    padding: 14px 48px !important;
  }

  .heightShipAdress {
    height: 224px;
    padding: 24px 24px 8px 24px;
  }
  .paginationNumberBox {
    padding: 5px 10px !important;
  }
  .drftgfet422 {
    margin-bottom: 60px;
  }
  .headingShop {
    word-break: break-word;
    flex-wrap: nowrap !important;
  }
  .orderDetailNotification {
    padding: 0px 40px 0px 60px !important;
  }
  .shoppingListOrderDetail {
    padding: 33px 40px 60px 60px !important;
  }
  .textOrderDetail {
    margin: 20px 8px 20px 0px !important;
  }
  .onceYouCheckTABLETbOOTOMPAdding {
    margin-bottom: 42px !important;
  }
  .noBotomObrt {
    border-bottom: none !important;
  }
  .nopaddinfgissue {
    padding: 0px 14px 0px 0px !important;
  }
  .padfororderdeatal {
    padding-bottom: 16px;
  }
  .ResetPwBtnBoxDesktop {
    max-width: 373px;
  }
  .fklgyhnkfyhj343 {
    width: 371px !important;
  }
  .tabletdownpadding {
    margin-bottom: 60px !important;
  }
  .newwimin input {
    min-width: 171px;
  }
  .shopListRow1Image {
    margin: 7px 0px;
  }
  .botpadipay {
    padding-bottom: 76px;
    padding-top: 16px;
    /* padding-left: 11px; */
  }

  .shippingForm {
    margin-top: 14px;
  }

  .paginationNumberBoxWhole {
    margin-left: 10px !important;
  }

  .taxformpad {
    padding-top: 23px;
    min-width: 520px;
  }

  .partsDe_container4_engineNamesListDesktop {
    margin-bottom: 13px;
  }

  .partsDe_container5MsgDesktop {
    /* margin-bottom: 90px; */
  }

  .subHeadDesktop {
    padding: 25px 18px 25px 50px !important;
  }

  .partOnlyDesktopYourCart {
    padding-left: 11px !important;
  }

  .marginLeftImageOurCart {
    margin-left: -6px;
  }

  .header__image {
    margin-right: 12px;
  }

  .borderbottomReviewShippingYourCart {
    border-bottom: 2px solid black;
    margin-bottom: 30px;
  }

  .borderBottomCheckoutShipping {
    border-bottom: 2px solid black;
  }

  .marginBottomCheckoutShipping {
    margin-bottom: 43px;
  }
  .subHeadPaymentMainFirst {
    margin-top: -15px;
    margin-bottom: -17px;
  }
  .paymenTableFirstWhite {
    background: white;
    height: 43px;
  }
  .sdkjbfbkj {
    padding: 28px !important;
  }

  .DesktopVisaAddPayment {
    margin-bottom: 6px !important;
  }
  .cardStatusDesktopAddAPyment {
    margin-left: -11px;
  }
  .shamoonDesktopAddPAymentBottom {
    margin-bottom: 15px !important;
  }

  .gumaanDesktopTalhaAnjumBottomPadding {
    margin-bottom: -12px !important;
  }

  .PhirMDesktopTopHeading {
    margin-top: -22px !important;
    margin-bottom: -13px !important;
  }
  .borderChakTalhATrDesktop {
  }
  .tabletPersonalInfoEmpAccount {
    margin-bottom: -30px !important;
  }
  .cancelButonCancelEmployeeRightDesktop {
    margin-right: 0px !important;
  }
  .cancelButonBottomDesktop {
    margin-bottom: -10px !important;
  }
  .RightButtonDeleteDesktop {
    margin-right: -5px !important;
  }
  .shoppingListTableQuantityDesktop {
    border: 1px solid #e8e7e7 !important;
  }
  .desktopMArginLEftPadding {
    margin-left: 4px !important;
  }

  .aachakMArginTopConTActDesktop {
    margin-top: -12px !important;
  }
  .carousel-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .partsDetailCorousalComponent .carousel:nth-child(2) {
    max-width: 730px;
  }
  .partsDetailCorousalComponent .carousel:nth-child(2) .thumbs-wrapper {
    margin: 0px;
  }
  .paymetnOptions > div {
    gap: 21px !important;
  }
}
.btop {
  border-top: 1px solid rgba(97, 94, 92, 0.15);
}

.bbtop {
  border-top: 2px solid black;
}
.bbbot {
  border-bottom: 2px solid black;
}
.bbot {
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
}
.dpfleengin {
  align-items: center;
  display: flex;
  gap: 6px;
}
.inenlab {
  color: #fff !important;
  font-feature-settings: "clig" off, "liga" off !important;
  /* forms/label/Bold */
  font-family: Montserrat !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
}
.smCss {
  display: flex;
  align-items: center;
  gap: 4px;
}
.notopmargin {
  margin-top: -6px !important;
}
.orderhispagpad {
  padding: 35px 16px 22px 16px !important;
}
.sepOrder {
  font-weight: 500;
  margin-right: -21px;
  margin-left: 3px;
}
.PhoneInput > input {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.InputFieldShippingAddress {
  color: #06070a;

  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.seldivrad {
  height: 43px !important;
  border-radius: 0 !important;
}

.mobsortright {
  align-items: baseline;
}

.partacoror {
  background-color: #eaeaea !important;
}
.normamarginshop {
  margin-left: 18px;
}
.sjkhbdskhj {
  margin-left: 8px;
}
.lineOrder {
  border-top: 2px solid black !important;
  border-radius: 0px !important;
}

.lineOrder1 {
  padding: 1px 2px 1px 8px;
  width: 100%;
}

.seldivrad svg {
  top: 19px !important;
  right: 12px;
}
.addpaymentcardwidth {
  width: 135px !important;
}

.padselect > div > div {
  padding-left: 16px;
}

.padselect > div > div > div {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.jkdhs > div > div > div {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.PhoneInputInput {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.react-datepicker-wrapper input {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.TxtareaDesktop {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.myProfile_firstNameInput,
.myProfile_lastNameInput,
.myProfile_signInInput,
.myProfile_passwordInput,
.myProfile_firstNameInput {
  color: #06070a;
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.coupandiscounfont {
  color: #07080c !important;
  text-align: right !important;

  /* Text sm/Medium */
  font-family: Montserrat !;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 138.462% */
}

.smallMenuItem {
  font-size: 12px !important;
  margin-left: 5px !important;
}

.ilmorButonGrey:hover {
  background: black;
  transition-duration: 500ms;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .mediaqueryEstmatedTotalMob {
    color: #07080c !important;
    text-align: right !important;
    /* Text sm/Medium */
    font-family: Montserrat !;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important; /* 138.462% */
  }
  .nopaddinfgissue {
    padding: 0px 14px 0px 0px !important;
  }
  .marginFormobile {
    margin: 12px 6px !important;
  }
  .fgdfgrg4523 {
    width: 100%;
  }
  .rightSide {
    padding: 8px 0px !important;
  }
  .selReason > div {
    margin-right: 4px;
  }
  .mobileQtyReturnParTReturn{
margin-left: -62px;
gap:22px
  }

  .mediaqueryEstmatedTotalMob {
    color: #07080c !important;
    text-align: right !important;

    /* Text sm/Medium */
    font-family: Montserrat !;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important; /* 138.462% */
  }
}

.strike{
  text-decoration: line-through;
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .backEngineDiv {
    padding-left: 28px;
    margin-top: 28px;
  }
  .sdfds232323de{
    height: calc(100vh -491px) !important;
  }
  .borderBottomCheckoutShipping1 {
    border-bottom: 2px solid black;
  }
  .drftgfet422{
    margin-bottom: 75px !important;
  }
  .cancelButonBottomDesktop{
    margin-bottom: 24px !important;
  }
  .jtgygjty {
    display: none !important;
  }
  .desktopCancelOrder {
    position: relative;
  }
  .desktopInnerDivBtnTest {
    position: absolute;
    right: 0;
  }
  .findParts__btnTablet svg{
    margin-right: 10px !important;
  }
  .carousel {
    width: 100vw !important;
  }
  .orderSummary_yourCart {
    margin-top: -50px !important;
  }
  .orderDetailButoon {
    justify-content: end !important;
  }
  .orderDetailTitle {
    margin-bottom: 30px;
    padding-top: 36px;
  }
  .shopPricQuick {
    margin-left: 4px !important;
  }
  .shopEngineTitle {
    /* max-width: 332px !important; */
  }
  .mediaqueryEstmatedTotalMob {
    color: #07080c !important;
    text-align: right !important;

    /* Text sm/Medium */
    font-family: Montserrat !;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    /* 138.462% */
  }
  .lipat {
    max-width: 189px;
  }
  .modifyOrderCancelOrderTabletUpPadding {
    margin-top: 11px;
  }

  .tabletLeftPaddingOrderNumb {
    padding: 20px 20px 20px 20px !important;
  }

  .borderTopOrderGreyTabletLeftPadding {
    padding: 20px 20px 20px 23px !important;
  }

  .onceYouCheckTABLETbOOTOMPAdding {
    padding-bottom: 18px;
  }

  .tabletReturmOrderTopPadding {
    margin-top: 10px !important;
  }

  .tabletReturmOrderBottomPadding {
    margin-bottom: 38px !important;
  }
  .parstsDe_PARTinfoDesktop {
    gap: 6px !important;
  }

  .tabletBtncancelAddTaxBottom {
    margin-bottom: 60px !important;
  }

  .dateHeadingTabletsigned {
    margin-bottom: 4px !important;
  }
  .sdkfnmcsdl {
    margin-bottom: 17px !important;
    padding: 8px 0px !important;
    margin : 8px 0px 28px  0px!important
  }
  .tabeltBootomEmailInputPadding {
    margin-bottom: 21px !important;
  }
  .imageRelativeTabEdit {
    position: absolute;

    height: 25px;
    width: 18px;

    margin: auto;
  }

  .taxformpadTabletSet {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .taxformpadTabletSetgridContainer {
    padding-left: 28px !important;
    padding-right: 25px !important;
  }
  .taxformpadTabletSetGreyTAblet {
    padding-left: 30px !important;
  }
  .cancelTaxGreyTabletAddCancel {
    margin-right: -5px !important;
  }
}
.addpaymentcardwidth > div > div > div[class$="placeholder"] {
  margin-left: 8px !important;
}
@media screen and (min-width: 901px) {
  .orderDetailMessage {
    margin-bottom: 34px;
    /* modifu order padding */
  }
  .sdkfnmcsdl {
    padding: 0px !important;
    margin: 0px !important;
  }

  .findParts__btnDesktop {
    gap: 8px;
  }
  .findParts__btnDesktop {
    width: 155px;
  }
  .findParts__btnDesktop svg {
    margin-right: 0px !important;
  }
  .backEngineDiv {
    margin-left: 22px;
  }
  .tabpadorder {
    border-bottom: none !important;
  }

  .quickOrderoutDesktop {
    margin-top: 18px !important;
  }
  .quickOrderoutSpan1Desktop {
    margin-top: 8px !important;
  }
  .gkrtj454 {
    margin-bottom: 30px;
  }
  .TxtareaDesktop {
    margin-bottom: -4px;
  }
  .greyHeadingContainerContactIlmor {
    /* margin-bottom: -29px !important; */
    /* padding-left: 35px !important; */
  }
  .ngkhjredkj456 {
    border-bottom: 2px solid black;
    margin-bottom: 37px;
  }
  .paymentforpay23 {
    padding-left: 8px;
  }
  .jhkjhrek343 {
    margin-top: 10px;
  }
  .dfgm2343 {
    padding: 8px 11px !important;
  }
  .posDesktopShip {
    top: 173px !important;
  }
  .textOrderDetail {
    margin-right: 8px;
  }
  .adbook {
    gap: 0 !important;
    min-width: 281px;
  }
  .paddinadjusment {
    padding-left: 16px !important;
  }
  .drlkfgjeriu34 > button:hover {
    background-color: var(--ilmorRedHover) !important;
  }
  .minwidSelOpt :hover {
    background: var(--ilmorRedHover) !important;
  }
  .parstsDe_PARTinfoDesktop {
    gap: 6px !important;
  }
  .orderDetailButoon {
    justify-content: end !important;
  }
  .paddingLeftForReturn {
    padding-left: 50px !important;
  }
  .paymentforpayBoy {
    padding-left: 8px;
  }
  .headerSelectApply {
    margin-left: 10px;
    min-width: 211px;
  }
  .heghtforcard {
    height: 213px !important;
    padding: 20px 24px !important;
  }
  .dfgkfgjhdfiyj112 {
    bottom: 20px !important;

    right: 22px !important;
  }
  .df343gdfg {
    margin-right: 8px;
  }
  .dfgrtertrt6 {
    padding-left: 16px !important;
  }
  .headerSelectApply svg {
    fill: var(--ilmorRedHover) !important;
    scale: 0.9;
  }
  .headerSelectSortBy svg {
    fill: var(--ilmorRedHover) !important;
    scale: 0.9 !important;
  }
  .padtopformyprofe {
    margin-top: 20px !important;
  }
  .nkerfgj5654m {
    margin-bottom: -25px !important;
    margin-top: -23px !important;
  }
  .tyhmkl56 {
    margin-top: -23px !important;
  }
  .myProfSubHeading {
    margin-top: -16px !important;
  }
  .paddingfforshipadrestitle {
    margin-top: -14px !important;
    margin-bottom: 20px !important;
  }
  .marginbott {
    margin-left: -8px !important;
    margin-top: 31px !important;
  }
  .rhtmk567867 {
    margin-top: -12px !important;
    margin-bottom: -16px !important;
  }
  .marginBottomShippingButtonsTablet {
    gap: 30px !important;
    padding-top: 9px;
  }
  .dateHeading {
    margin-bottom: 5px !important;
  }
  .cancelTaxGreyTabletAddCancel {
    margin-bottom: 60px;
  }
  .erflmk3hjkerfj777 {
    margin-top: -22px !important;
  }
  .erfghknj {
    margin-left: 5px !important;
  }
  .EmpAcc_outTable td {
    height: 65px !important;
  }
  .orderhispagpad {
    padding: 8px 12px 22px 11px !important;
  }
  .shoppingListdateTotaldiv {
    min-width: 115px !important;
  }
  .ilmorButon:hover {
    background: #ce0e2d;
    transition-duration: 500ms;

    background: #900a1f;
  }
  .quickOrder__buttonWhiteDesktop:hover {
    color: var(--ilmorRed);
  }
  .numericInputOrderDetailDiv {
    min-width: 58px;
  }
  .orderDetailButoon {
    padding: 16px 8px 16px 0px !important;
  }
  .desktopCancelOrder {
    position: relative;
  }
  .desktopInnerDivBtnTest {
    position: absolute;
    right: 0;
  }
  .TxtareaDesktop {
    margin-bottom: -6px;
  }
  .addpaymentcardwidthDesktopLeft {
  }
  .labelDesktopAddShippingBottomDesktopPadding {
    margin-bottom: 24px;
  }
}

.dkjgbnkfghjbhnkjt {
  position: relative;
}
.header__input__base5 {
  line-height: 15px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0.05em !important;
}

.datebg input {
  padding: 0px 0px 0px 16px;
}

.justifyGridDesktopHover {
  background-color: #ee9209 !important;
}
.justifyGridDesktopHover:hover {
  background-color: #ee9209 !important;
}

.justifyHoverDetailPage {
  background-color: #ee9209 !important;
}

.justifyHoverDetailPage:hover {
  background-color: #ee9209 !important;
}

.uploadAttachment {
  display: flex;
  align-items: center;
}


.withoutDealerId{
  background: #EB1648 !important;
    color: white;
    padding: 4px;
    width: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    font-size: 21px;
}
.withoutDealerId svg{
  margin-right: 8px;
  width: 40px;
  height: 40px;
}