.TaxCert_main{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27%;
  height: 335px;
  margin-top: 45px;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
  background: #F7F7F7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: left;
  box-sizing: content-box;
}

.TaxCert_Container{
  width: 550px;
  height: 632px;
  box-sizing: content-box;
}
.TaxCert_logo{
  background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
    align-items: center;
  }
  .TaxCert_title-imageDesktop{
    height:auto;
    width: 30%;
    padding: 10px;
  }
  
  .TaxCert_info__mainDesktop{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 96px;
    gap: 40px;
    
    width: 358px;
    height: 335px;
    
    background: #F7F7F7;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  
  }
  
  .TaxCert_headingDesktop{
    width: 375px;
      height: 25px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      text-transform: uppercase;
      color: #07080C;
  }
  
  .passwordRecoveryMsgDesktop{
    width: 374px;
  height: 32px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  
  color: #000000;
  
  }
  .TaxCert_lineBreak__mainDesktop{
  
    width: 374px;
    height: 0px;
    
    /* transparent gray
    
    form field outlines and separator lines
    */
    border: 1px solid rgba(97, 94, 92, 0.15);
  
  }
  .ForgetPw_formDesktop{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: -30px;
      width: 100%;
  }
  
  .ForgetPw_labelDesktop{
    width: 155px;
    height: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    margin-left: 1px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 6%;
    color: #07080C;
  }
  .ForgetPw_usernameInputDesktop{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 12px;
    width: 374px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid rgba(97, 94, 92, 0.15);
  }
  
  .ForgetPw_usernameInputDesktop:focus-visible{
    /* border: none; */
    outline: none;
  
  }
  
  .ResetPwBtnBoxDesktop {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 16px;
    width: 138px;
    height: 18px;
    background: #CE0E2D;
    border: none;
    color: white;
    margin-top: 22px; */
  }
  
  /* .ResetPwDesktop{
    width: 128px;
    height: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: #CE0E2D;
    border: none;
  } */
  
  /*=====================================> Mobile Css <====================================================*/
  
  .TaxCert_mainMobile{
    /* display: flex;
      flex-direction: column;
      align-items: center;
    
      width: 374px;
      height: 335px;
      margin-top: 200px;
      margin-bottom: 284px;
      margin-left: 715px;
      margin-right: 437px;
      background: #F7F7F7;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px; */
  }
  
  .TaxCert_ContainerMobile{
    margin-top: 150px;
    margin-left: 20px;
  }
  .TaxCert_logoMobile{
    background-color: white;
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
  }
  .TaxCert_title-imageMobile{
    height:auto;
    width: 30%;
    padding: 10px;
  }
  .TaxCert_info__mainMobile{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; 
  display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px 24px;
      gap: 40px;
      width: 302px;
      height: 330px;
      background: #F7F7F7;
  }
  
  .TaxCert_headingMobile{
    width: 378px;
    height: 29px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #07080C;
  }
  
  .passwordRecoveryMsgMobile{
    /* width: 350px; */
    height: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    /* margin-left: 12px; */
  
  }
  
  .TaxCert_lineBreak__mainMobile{
    width: 302px;
  height: 0px;
  
  /* transparent gray
  
  form field outlines and separator lines
  */
  border: 1px solid rgba(97, 94, 92, 0.15);
  }
  .ForgetPw_formMobile{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: -30px;
      width: 100%;
  }
  .ForgetPw_emailLabelMobile{
  
  }
  .ForgetPw_labelMobile{
    width: 155px;
    height: 12px;
    
    /* forms/label/Bold */
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    
    /* Brand Colors/Black */
    
    color: #07080C;
  }
  .ForgetPw_usernameInputMobile{
    box-sizing: border-box;
    border-radius: 0px !important;
    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 12px;
    
    width: 300px;
    height: 42px;
    margin-top: -4%;
    
    /* White */
    
    background: #FFFFFF;
    border: 1px solid rgba(97, 94, 92, 0.15);
  }
  .ForgetPw_usernameInputMobile:focus-visible{
    /* border: none; */
    outline: none;
  
  }
  
  .ResetPwBtnBoxMobile{
    /* margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 20px;
    gap: 16px;
    width: 138px;
    height: 18px;
    background: #CE0E2D;
    border: none;
    color: white; */
  }
  
  .ResetPwMobile{
    /* width: 128px;
    height: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: #CE0E2D;
    border: none; */
  }
  
  /*====================================> Tablet CSS <=================================================*/
  .TaxCert_mainTablet{
    display: flex;
      flex-direction: column;
      align-items: center;
      /* gap: 40px; */
      width: 374px;
      height: 335px;
      margin-top: 45px;
      margin-bottom: 45px;
      margin-left: 212px;
      margin-right: 437px;
      background: #F7F7F7;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
  }
  
  .TaxCert_ContainerTablet{
  width: 566px;
  height:632px;;
  }
  .TaxCert_logoTablet{
    background-color: white;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  height: 162px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  /* border: 1px solid rgba(97, 94, 92, 0.15); */
  background: var(--white, #FFF);
}

.btn_cancel{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40%;
  margin-top: 40px;
}

.TE_button{
  margin: 20px;
  border: none;
  font-size: medium;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  
  color: #000000;
  
  }
  .TaxCert_lineBreak__mainTablet{
  
    width: 374px;
    height: 0px;
    
    /* transparent gray
    
    form field outlines and separator lines
    */
    border: 1px solid rgba(97, 94, 92, 0.15);
  
  }
  .ForgetPw_formTablet{
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: -30px;
      width: 100%; */
  }
  .ForgetPw_emailLabelTablet{
  
  }
  .ForgetPw_labelTablet{
    /* width: 155px;
    height: 12px; */
    
    /* forms/label/Bold */
    
    /* font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase; */
    
    /* Brand Colors/Black */
    
    color: #07080C;
  }
  .ForgetPw_usernameInputTablet{
    /* box-sizing: border-box; */
  
    /* Auto layout */
    
    /* display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 12px;
    
    width: 374px;
    height: 42px; */
    
    /* White */
    
    /* background: #FFFFFF;
    border: 1px solid rgba(97, 94, 92, 0.15);
    margin-top: -4%; */
  }
  
  .ForgetPw_usernameInputTablet:focus-visible{
    /* border: none; */
    outline: none;
  
  }
  .ResetPwBtnBoxTablet {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 20px;
    gap: 16px;
    width: 138px;
    height: 18px;
    background: #CE0E2D;
    border: none;
    color: white;
    margin-top: 12px; */
  }
  
  .ResetPwTablet{
    /* width: 128px;
    height: 18px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: #CE0E2D;
    border: none; */
  }
  
  .submitSkip {
    display: flex;
    justify-content: space-between;
  }
  .onlySkip {
    margin-top: 30px;
  }