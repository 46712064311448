.cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: fit-content;
  width: 100%;
  height: 100%;
  /* background: #bbb; */
}

.cardNopart {
  width: 100%;
  height: 53vh;
  color: #07080C;
  font-family: Montserrat;
  margin-bottom: 26%;
  font-size: 20px;
  font-style: normal;
  /* height: 100%; */
  /* background-color: white; */
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px; 
  padding-top: 7%;
  align-items: unset !important;
}

.currSvgDiv{
  background: url(../Images/currentEngineSVG1.png);
  /* background-color: var(--ilmorRed); */
  background-color:rgb(153 0 0) !important;  /* Replace 0.5 with your desired opacity value (0 to 1) */
  /* Additional background properties */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: #FFF;
  justify-content: center;
  cursor: pointer;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
min-height: 335px;
font-weight: 700;
line-height: normal;
letter-spacing: 2.43px;
text-transform: uppercase;
}

.currImagesvgSap{
  width: 200px;
  text-align: left;
  padding-left: 40px;
}
.currImagesvgMob{
  width: 300px;
}
.currSvgLis{
  background-position: bottom !important;
  background-size: cover !important;
  display: flex;
  min-height: 157px;
  align-items: center;
  justify-content: end !important;
}

@media screen and (min-width:0px) and (max-width:600px) {
  .currSvgLis{
    background-position: center center !important;
  }
  .currSvgDiv{
    width: 100% !important;
    overflow: hidden;
    padding: 10px ;
  }
}
.cardView{
  
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  background: #bbb;
}

.listView{
  
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  /* height: 100%; */
  /* background: #bbb; */
}