.subHeadDesktop {
  background-color: rgb(228, 228, 228);
  padding: 18px;
  display: flex;
  width: 100%;
}

.subHeadMobile {
  background-color: rgb(228, 228, 228);
  padding: 18px;
  display: flex;
  flex-direction: column;
}
.displayFlex{
  display: flex;
}
.subHeadTextDesktop {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  display: flex;
  align-items: center;
  /* padding-left: 3%; */
  text-transform: uppercase;
  color: #07080C;
}

.subHeadTextDesktop2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  display: flex;
  align-items: center;
  padding-left: 3%;
  /* text-transform: uppercase; */
  color: #07080C;
}

.subHeadTextMobile {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.SmalllHeading {
  font-size: 24px ;
  line-height: 231% !important;
}

@media screen and (min-width:0px) and (max-width:600px) {
  .subHeadTextDesktop2{
    font-size: 25px;
    font-weight: 500;
    text-transform:none;
    margin-top:-18px
  }
}