.cardListParentDesktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 0px 11px 1px 5px;
  max-height: 183px;
  justify-content: space-between;
  border-top: 0.5px solid rgba(97, 94, 92, 0.15);
}

.cardList__imageDivDesktop {
  padding: 10px 20px;
  max-width: 211px;
}
.dpHidden {
  height: 10px;
  visibility: hidden;
}

.cardList__imageDesktop {
  /* background: transparent url('../Images/load.gif') center no-repeat; */
  width: 150px;
  height: auto;
  max-height: 21px;
  min-width: 150px;
  object-fit: contain;
  min-height: 145px;
}

.cardListLesftSide,
.cardListRightSide,
.cardListbottomBoxDesktop {
  display: flex;
  margin-left: 20px;
  align-items: center;
  flex-direction: row;
}
.cardListRightSide{
  position: relative;

}
.cardListbottomBoxDesktop .inputBoxNUmber .react-numeric-input {
  width: 105px;
}
.cardListLesftSide{
  cursor: pointer;
}
.nomar{
  
  margin-left: unset;
}
.cardList__newTextDesktop {
  background-color: #ce0e2d;
  font-size: 10px;
  color: white;
  padding: 2px 6px;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 600;
  background-color: var(--ilmorOrange);
  border-radius: 12px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  color: var(--white, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  max-width: 40px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 2px 6px;
}

.cardListheadingText {
  color: #615e5c;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
}

.cardListheadigSubText {
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.cardListsubheadingText {
  color: #07080c;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
}

.cardListbackordered {
  color: #ce0e2d;
  text-align: center;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
}

.inputBoxNUmber {
  /* margin-bottom: 4px; */
}

.cardListprice {
  color: #07080c;
  text-align: right;
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
}

.cardListdiscountedPrice {
  color: #000;
  text-align: right;
  font-size: 16px;
  margin-left: 10px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 120%;
  text-decoration: line-through;
}

.csrdListDetail {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  /* align-self: center; */
  justify-content: center;
}

.cardListpriceDetail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardListquickOrderTableQuantityDesktop {
  border: none !important;
  /* max-width: 84px; */
  max-width: 100px;
  outline: none;
  margin-left: 10px;
  text-align: center;
  padding: 10px 11px;
  border-radius: 0px !important;
  padding: 11px;
  border: 1px solid #e8e7e7 !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 36px;
  /* identical to box height, or 257% */

  text-align: center;
  text-transform: uppercase;
}

.activeCard {
  cursor: pointer;
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .cardList__imageDesktop {
    width: 166px;
    height: auto;
  }

  .cardListRightSide {
    display: flex;
    flex-direction: column;
  }

  .cardListpriceDetail {
    margin-bottom: 14px;
  }
}


.lightBorder{
  border: 1px solid #e8e7e7 !important; 
}

.noShift{
  margin-bottom: unset;
}