.searchFiledTExt {
  color: var(--brand-colors-black, #07080c);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.searchFieldOrder {
  display: flex;
  align-items: center;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--White, #FFF);
  background: white;
}

.orderSearch {
  display: flex;
  align-items: center;
  gap: 8px;
  /* padding: 7px 33px; */
  /* background: var(--light-gray, #F7F7F7); */
}
.orderHistoryMainDiv {
  background-color: white;
  width: 100%;
}
.orderHisTable {
  width: 100%;
  /* margin-bottom: 54px; */
}

.nocard {
  padding: 28px;
}
.orderHisTableRow {
  color: #000;
  text-align: center;
  /* Text xs/Bold */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.orderHisTableData {
  color: var(--brand-colors-black, #07080c);
  /* Text md/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.orderHisTableDataDiv {
  text-align: left;
  padding: 16px 0px;
  cursor: pointer;
}
.paymethodCard {
  gap: 8px;
  display: flex;
  align-items: center;
}
.orderHisTableButton {
  color: var(--brand-colors-ilmor-red, #ce0e2d);
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: underline;
}
.orderHisTableButtonBar {
  color: black;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration-line: none;
}
.ordeTabDetail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px;
  padding-top: 32px;
  padding-bottom: 24px;
}

.leftSideOrder {
  display: flex !important;
  gap: 14px !important;
  align-items: center !important;
}

.ordeTabDetailTab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px;
  padding-top: 32px;
  width: 100%;
  padding-bottom: 24px;
}
.tabOrdeDetaLeft,
.tabOrdeDetaRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentethodStatus {
  flex-direction: row;
  gap: 8px;
  margin-right: 20px;
  margin-bottom: 13px;
  margin-left: 0;
}
.tabOrdeDetaLeft {
  gap: 10px;
}
.tabOrdeDetaRight {
  gap: 8px;
}
.tabOrdeDetaRightMob {
  gap: 8px;
  display: flex;
  padding: 8px 0px;
}

.ordeDetailTitle {
  color: #000;
  text-align: left;

  /* Text xs/Bold */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
}

.tabOrderNumber {
  color: #07080c;
  text-align: center;
  /* Text sm/Medium */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}
.dateTab {
  display: flex;
  /* margin-right: 17%; */
  gap: 10px;
  width: 100%;
  flex-direction: column;
}

.dateTab.ref {
  width: 200px;
}
.orderDate {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-around; */
  padding: 17px 1px;
  padding-bottom: 32px;
}
.orderMob {
  align-items: flex-start;
  /* justify-content: space-around; */
  padding: 5px 1px;
  padding-bottom: 0px;
}
.TabletOrderDetail {
  border-bottom: 1px solid rgba(97, 94, 92, 0.15);
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.evenRow {
  background: var(--light-gray, #f7f7f7);
}

.orderHisTableRow th {
  /* padding-left: 5% !important; */
}

.paymentTableDiv {
  padding: 20px 54px;
}
.activeInactive {
  font-weight: 500;
}
.thisCanBe {
  color: var(--Medium-Gray, #615e5c);

  /* Text xs/Medium */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  margin-top: 16px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.hrLineBreak {
  display: flex;
  margin-top: 30px;
  border-color: #d1d1d033;
}
input.inputBoxDisable[type="number"]::-webkit-inner-spin-button,
input.inputBoxDisable[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputBoxDisable {
  min-width: 126px;
}
.imageReltive {

  position: absolute;
  top: 0;
  right: 15px;
  height: 25px;
  width: 18px;
  bottom: 0;
  margin: auto;

  /* position: absolute;
  top: 12.5px;
  right: 13px;
  height: 25px;
  width: 18px; */
}

.padright {
  margin-right: 12px;
}

[dataId="payment"] th,
[dataId="payment"] td {
  padding: 10px !important;
}

[dataId="payment"] th:first-child,
[dataId="payment"] td:first-child {
  padding-left: 48px !important;
}
.Tablet_full {
  padding: 50px;
}
