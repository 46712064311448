.footerDesktop {
  background-color: black;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.footer__topboxDesktop {
  display: flex;
  width: 100%;
  padding: 61px 48px;
  flex-wrap: wrap;
}

.footer__bottomboxDesktop {
  color: white;
  margin-bottom: 22px;
  margin-top: 37px;
  margin-left: 51px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.4;
  line-height: 15px;
  letter-spacing: 0.08em;
}

.footer__TextDesktop {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-wrap: wrap;
  align-items: flex-start;
}

.footer__h6Desktop {
  margin-top: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}

.footer__h6Desktop:hover {
  color: #ce0e2d;
}

.footer__ImageDesktop {
  display: flex;
  flex-wrap: wrap;
  /* padding: 50px;  */
  align-items: baseline;
  margin-top: 13px;
  padding-right: 8%;
}

.footer__imageDesktop {
  /* width: 190px; */
  /* height: auto; */
  height:41px
}

.footerTablet {
  background-color: black;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 100%;
}

.footer__topboxTablet {
  display: flex;
  padding: 48px 30px;
  /* flex-wrap: wrap; */
  flex-direction: row;
}

.footer__bottomboxTablet {
  color: white;
  margin-bottom: 22px;
  margin-top: 0px;
  margin-left: 11px;
  padding-left: 39px;
  letter-spacing: 0.08em;
  text-decoration-line: ;
  font-family: "Montserrat";
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  opacity: 0.4;
}
.underLine {
  /* margin-left: 6px; */
  text-decoration: underline;
  cursor: pointer;
  margin-right: 6px;
}

.footer__TextTablet {
  flex-wrap: wrap;
  align-items: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: 72px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  align-items: flex-start;
}

.footer__h6Tablet {
  margin-top: 13px;
  cursor: pointer;
}

.footer__h6Tablet:hover {
  color: #ce0e2d;
}

.footer__ImageTablet {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  object-fit: contain;
  margin-top: 6px;
  height: auto;

  /* padding: 19px; */
  /* padding: 50px; */
}
.footer__ImageTablet  > img {
  width:157px
}

.footerMobile {
  background-color: black;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footer__topboxMobile {
  display: flex;
  /* padding: 48px 20px; */ 
    padding: 40px 20px 48px 2px;
  /* flex-wrap: wrap; */
  flex-direction: column;
}

.footer__bottomboxMobile {
  font-size: 11px;
  color: white;
  letter-spacing: 1px;
  padding: 0px 20px 48px 20px;
  margin-bottom: 22px;
  /* margin-top: 37px; */
  /* margin-left: 4px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  color: #ffffff;
  opacity: 0.4;
}

.footer__TextMobile {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 100px;   */
  padding-left: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.footer__h6Mobile {
  margin-top: 13px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.footer__h6Mobile:hover {
  color: #ce0e2d;
}

.footer__ImageMobile {
  display: flex;
  flex-wrap: wrap;
  width: 161px;
  margin-right: 69px;
  padding: 11px;
  height: auto;
  object-fit: contain !important;
}

.footer__ImageMobile > img {
  object-fit: contain !important;
  margin-bottom: 10px;
  width:152px;
}
