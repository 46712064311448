/*=============> DESKTOP CSS<=========================*/
.sidemenu__maincontainerDesktop {
  width: 284px;
  /* height: 2041px; */
  flex-shrink: 0;
  /* max-width: 279px; */
  background-color: black;
  padding: 0px 12px;
}

.sidemenu__categoryDesktop {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.sidemenu__categoryTitleBoxDesktop {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}


.sidemenu__categoryLabelDesktop {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.sidemenu__categoryListDesktop {
  /* min-height: 445px; */
  /* letter-spacing: 0px; */
  /* border: 1px solid pink; */
  /* padding: 0px 15px; */
  width: 100%;
  margin-top: -25px;
}


.sidemenu__listDesktop {
  font-size: 18px;
  color: var(--white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* padding: 4px 10px; */
}

.sidemenu__list2Desktop {
  /* margin-bottom: 20px;
        text-align: left; */
}

.sideMenuBorder {
  opacity: 0.25;
  background: #fff;
  height: 1px;
  margin-top: 29px;
}

.highlighted {
  border-radius: 50px !important;
  background: var(--brand-colors-ilmor-red, #ce0e2d) !important;
}

.shopEngineHover:hover {
  /* border-radius: 50px !important;
  background: var(--brand-colors-ilmor-red, #ce0e2d) !important; */
  color: var(--brand-colors-ilmor-red, #ce0e2d) !important;
}


.highlighted:hover {
  color: white !important;
}
.highlightedSub {
  border-left: 2px solid red;
  color: #ce0e2d;
}

.sidemenu__subcategoryDesktop {
  color: white;
  /* margin-left: 7%; */
  font-size: 13px;
  display: flex;
  /* padding: 0px 45px; */
  /* margin : -10% 0%; */
  /* border-bottom: 1px solid rgb(234 226 226); */
  cursor: pointer;
  /* padding: 2px 25px; */
  /* justify-content: center; */
  align-items: center;
  justify-content: left;
  margin: -12px 27px;
}

.sidemenu__subcategoryList {
  padding-left: 10px;
}

.sidemenu__subcategoryList:hover {
  color: red;
  /* margin-left: 5% */
  /* border-left : 2px solid red; */
  /* padding-left: 1px; */
  cursor: pointer;
}

.categoryLabelDesktop:hover {
  /* color: red; */
}

.categoryLabelDesktop {
  color: white;
  letter-spacing: 1px;
  /* min-width: max-content; */
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
  /* height: 30px; */
  padding: 4px 25px;
  text-align: left;
}
.categoryLabelDesktop:hover {
  color: red;
  cursor: pointer;
}

.categoryLabelDesktop.selected {
  background-color: red;
}
.categoryLabelDesktop.highlighted:hover {
  color: white !important;
}

.sidemenu__signOutBtnDivDesktop {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 20px;
}
.sidemenu__signOutBtnDesktop {
  background: #ce0e2d;
  border: none;
  /* color: #FFF; */
  /* padding: 12px 20px; */
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.signOutLabel {
  color: #fff;
  text-align: center;

  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}
.sidemenu__signOutBtnDesktop:hover {
  background: darkred;
}
/* =============>TABLET CSS <===================*/

.sidemenu__maincontainerTablet {
  background-color: black;
  border: 1px solid black;
  width: 25vw;
  height: 100vh;
  font-family: Montserrat;
  display: block;
}

.sidemenu__categoryTablet {
  color: rgb(248, 242, 248);
  width: 25vw;
  /* border: 1px solid red; */
  margin-top: 10%;
  letter-spacing: 6px;
  cursor: pointer;
}

.sidemenu__categoryLabelTablet {
  margin-left: -40px;
}

.sidemenu__categoryListTablet {
  letter-spacing: 0px;
  /* border: 1px solid pink; */
  padding: 8%;
}

.sidemenu__listTablet {
  font-size: 80%;
  padding: 10px;
}

.sidemenu__list2Tablet {
  margin-bottom: 20px;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .sidemenu__maincontainerTablet {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .sidemenu__maincontainerMobile {
    display: none;
  }
}
