.ShippingouterContainerMyProfile{
  height: auto;
  width: 70%;
  /* border: 1px solid; */

}

.myProfile_backGround {
  /* background-color: black;
  border: 1px solid violet;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center; */
  /* display: -webkit-box; */
  display: flex;
    /* justify-content: flex-end; */
    width: 100%;
}

.myProfile_outerContainer {
  /* border: 1px solid green; */
  height: auto;
  width: 100%;
  background: #eaeaea;
}

.myProfile_mainContainer {
  display: flex;
  width: 700px;
  padding: 0px 0px 60px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  /* border: 1px solid yellow; */
  /* padding-left: 33px; */
  /* padding-top: 30px; */
  margin-top: 40px;
  margin-left: 40px;
}

.myProfile_titleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.myProfile_titleLabel {
  color: #000;
  /* Display lg/Bold */
  font-size: 40px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.myProfile_titleMsg1 {
  color: var(--brand-colors-black, #07080c);
  /* Text lg/medium */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 24px;
}
.myProfile_titleMsg2 {
  display: flex;
  flex-direction: column;
  /* align-self: stretch;  */
  color: #ce0e2d;
  /* Text sm/Medium */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 18px;
}

.myProfile_lineBreak {
  height: 1px;
  background: #d1d1d0;
  width: 100%;
}

.myProfile_userRoleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.myProfile_userRoleLabel {
  color: var(--brand-colors-black, #07080c);
  /* Display sm/Bold */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.myProfile_userRoleMsg {
  color: var(--brand-colors-black, #07080c);
  /* Text lg/medium */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 24px;
}

.myProfileForm{
  gap: 22px;
  display: flex;
  /* width: 800px; */
  flex-direction: column;
}

.myProfile_infoBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.myProfile_infoLabel {
  display: flex;
  flex-direction: column;
  /* align-self: stretch;  */
  color: var(--brand-colors-black, #07080c);
  /* Display sm/Bold */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.myProfile_nameInputFieldBox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.myProfile_firstNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.myProfile_firstNameLabel {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: #07080c;
  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.myProfile_firstNameInput {
  display: flex;
  height: 42px;
  padding: 0px 15px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
  /* width: 340px; */
  width: 97%;
  margin-top: 2px;
}
.myProfile_lastNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 2px;
}
.myProfile_lastNameLabel {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: #07080c;

  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.myProfile_lastNameInput {
  display: flex;
  height: 42px;
  padding: 0px 15px;
  align-items: center;
  gap: 12px;
  margin-top: 2px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
  /* width: 340px; */
}

.myProfile_primaryPhoneBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.myProfile_phoneLabelAndField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 2px;
}
.myProfileWhiteField {
  display: flex;
  background: white;
  width: 100%;
}
.myProfile_primaryPhoneLabel {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.countrySelectBox {
  min-width: 72px;
  font-size: 11px;
  border: none;
  display: flex;
  margin-right: 5px;
}

.countrySelectBox [class*="-control"] {
  border-radius: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  display: contents;
}

.countrySelectBox [class*="-menu"] {
  background: rgba(97, 94, 92, 0.8) !important;
}
/* 
.countrySelectBox{
  width: 1px;
  height: 20px;
  background-color: #615e5c;
} */

.countrySelectBox [class*="-menu"] [class*="option"] {
  background-color: transparent;
  /* margin-top: -8px; */
  text-align: left;
  cursor: pointer;
}

.countrySelectBox [class*="-menu"] [class*="-MenuList"] {
  overflow: hidden;
  max-height: fit-content;
}

.countrySelectBox [class*="-menu"] [class*="-qr46ko"] {
  overflow: hidden;
  max-height: fit-content;
}

.countrySelectBox [class*="-control"] [class*="-indicatorSeparator"] {
  display: none !important;
}

.countrySelectBox [class*="-control"] [class*="-ValueContainer"] {
  /* min-width: 108px; */
  font-size: 13px;
  text-align: center;
  font-weight: 600;
}

.countrySelectBox [class*="-control"] [class*="css-hlgwow"] {
  /* min-width: 108px; */
  font-size: 13px;
  text-align: center;
  font-weight: 600;
}

.countrySelectBox [class*="-control"]
  [class*="-ValueContainer"]
  [class*="-placeholder"] {
  color: black;
}

.countrySelectBox [class*="-indicatorContainer"] {
  color: #ce0e2d;
}

.countrySelectBox [class*="-menu"] [class*="option"]:hover {
  background-color: transparent;
  /* transform: scale(1.05); */
  overflow: hidden;
}

/* .header__input__base {
  min-width: 20vw !important;
  line-height: 15px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0.05em !important;
} */
.myProfile_primaryPhoneInput {
  display: flex;
  height: 42px;
  /* padding: 16px; */
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
  width: 611px;
}

.myProfile_sendNotiFcn {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 4px;
  /* align-self: stretch; */
  /* margin-left: -2%; */
  padding: 16px 0px;

}
.myProfile_sendNotiFcncheckBox {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid var(--medium-gray, #615e5c);
  background: var(--white, #fff);
}
.myProfile_sendNotiFcnLabe {
  color: var(--brand-colors-black, #07080c);
  /* Text md/Medium */
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 18px;
}

.myProfile_textNotiFcnBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.myProfile_textNotiFcnLabelAndField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 2px;
}
.myProfile_textNotiFcnLabel {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.myprofile_TextNotiFcnWhiteField2 {
  display: flex;
  background: white;
  width: -webkit-fill-available;
}

.myProfile_textNotiFcnInput {
  display: flex;
  height: 42px;
  /* padding: 16px; */
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  background: var(--white, #fff);
}
.myProfile_btnBox {
  display: flex;
  padding: 8px 0px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.myProfile_cancelBtn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
}
.myProfile_cancelLabel {
  color: var(--brand-colors-black, #07080c);
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}
.myProfile_updateBtn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
}

.myProfile_cancelLabel:hover{
  color: darkred;
}
.myProfile_updateBtn:hover{
  background-color: darkred;
  transition: 0.5s;
}
.myProfile_updateLabel {
  color: #fff;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}

.myProfile_signInInfoBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.myProfile_signInInfoLabel {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--brand-colors-black, #07080c);
  /* Display sm/Bold */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}
.myProfile_signInAndLabelBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 2px;
}
.myProfile_signInLabel {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.myProfile_inputWithIcon{
  position: relative;
  width: 100%;
}
.myProfile_inputIcon{
  position: absolute;
  left: 1%;
  top: 18%;
  color: var(--medium-gray, #615E5C);
}
.myProfile_signInInput {
  
  display: flex;
  height: 42px;
  padding: 0px 50px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  opacity: 0.3499999940395355;
  background: var(--white, #fff);
  width: 100%;
  color: var(--medium-gray, #615E5C);
  
  
}


.myProfile_passwordAndLabelBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 2px;
}
.myProfile_passwordLabel {
  display: flex;
  /* width: 155px; */
  flex-direction: column;
  justify-content: center;
  color: var(--brand-colors-black, #07080c);

  /* forms/label/Bold */
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.myProfile_passwordInput {
  display: flex;
  height: 42px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid rgba(97, 94, 92, 0.15);
  opacity: 0.3499999940395355;
  background: var(--white, #fff);
  width: 100%;

}
.myProfile_sendNotiFcnLabel{
  /* margin-left: -8px; */
}
.myProfile_btnBox2 {
  display: flex;
  padding: 25px 0px 69px    0px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.myProfile_editBtn {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
  border: none;
  cursor: pointer;
}
.myProfile_editBtn:hover{
  background-color: darkred;
  transition: 0.5s;
}


.myProfile_editLabel {
  color: #fff;
  text-align: center;

  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
}

.myProfile_blackContainer{
  display: none;
}


/*FOR TABLET VIEW */
@media screen and (min-width:601px) and (max-width:900px) {

  .ShippingouterContainerMyProfile{
    width: 100%;
  }
  .myProfile_blackContainer{
    display: flex;
    /* width: 112% !important; */
padding: 18px 28px;
justify-content: flex-end;
align-items: flex-start;
gap: 35px;
background: #07080C;
    width: 112% !important;
    margin-left: -40px;
  }
}

.myProfile_signOutBtn{
  display: flex;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 16px;
background: var(--brand-colors-ilmor-red, #CE0E2D);
}
.myProfile_signOutBtnLabel{
  color: #FFF;
text-align: center;

/* Text sm/Bold */
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: 18px;
}

/*FOR MOBILE VIEW */
@media screen and (min-width:0px) and (max-width:600px) {
  /* .myProfile_lineBreak{
    width: 45%;
  }
  .myProfile_mainContainer{
    padding: 5px 10px;
    width: auto;
    margin: 0px;

  }
  .myProfile_titleLabel{
    font-size: 24px;
    padding: 15px 0px;
  }
  .myProfile_firstNameInput{
    display: flex;
    height: 42px;
    padding: 0px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border: 1px solid rgba(97, 94, 92, 0.15);
    background: var(--white, #fff);
    width: auto;
  }
  .myProfile_lastNameInput{
    display: flex;
    height: 42px;
    padding: 0px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border: 1px solid rgba(97, 94, 92, 0.15);
    background: var(--white, #fff);
    width: auto;
  } */

  .ShippingouterContainerMyProfile{
    width: 100%;
  }

  .myProfile_blackContainer{
    display: flex;
    width: 117% !important;
    padding: 18px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    background: #07080C;
    margin-left: -40px;
  }
} 

.myProfile_signOutBtn{
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
background: var(--brand-colors-ilmor-red, #CE0E2D);
}
.myProfile_signOutBtnLabel{
  color: #FFF;
  text-align: center;
  
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.labelrequired1::before{
  content: "*";
  color: red;
  margin-right: 6px;
}
.labelrequired::after{
  content: "*";
  color: red;
  margin-left: 2.1px;
}
.myProfileLineBreak{
  display: flex;
  margin-top: 30px;
  border-color: #d1d1d033;
}
