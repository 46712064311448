.catalog__mainbody {
    display: flex;
    width: 100%;
}
.cardsMain{
    display: flex;
    flex-direction: column;
    width: 100%;
}
@media screen and (min-width:601px) and (max-width:900px) {
    .catalogMainDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
@media screen and (min-width: 0px) and (max-width: 600px){
.accountPopMenuSearch {
    position: absolute;
    top: -25px;
    right: 50px;
    }
}