.banner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.bannerMain {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  position: relative;
}

.banner__imageFull {
  width: inherit !important;
}

.headingCaraousal {
  position: absolute;
}

.bannerCarousal {
  position: relative;
  top: 0;
}

.banner__h6Desktop {
  display: block;
  color: #ce0e2d;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
}

.banner__h6Tablet {
  display: block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2.28571px;
  text-transform: uppercase;
  color: #ce0e2d;
}

.banner__h6Mobile {
  display: block;
  color: #ce0e2d;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2.28571px;
  text-transform: uppercase;
}

.banner__h1Desktop {
  display: block;
  font-size: 54px;
  color: white;
  font-weight: 700;
  /* max-width: 92%; */
  text-align: left;
  text-transform: uppercase;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
  opacity: 1 !important;
  color: white !important;
}

.banner__imageFullDesktop {
  width: inherit !important;
}

.slick-prev {
  left: 26px !important;
  z-index: 99 !important;
}

.slick-next {
  right: 20px !important;
  z-index: 99 !important;
}

/* .slick-dots li button::before {
} */
.slick-dots {
  bottom: 9px !important;
}

/* .slick-dots li.slick-active button {
    opacity: 1!important;
    color: white!important;
} */

.banner__h1Tablet {
  display: block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  color: white;
  line-height: 120%;
  text-transform: uppercase;
}

.banner__h1Mobile {
  display: block;
  font-style: normal;
  text-align: left;
  color: #fff;
  font-size: 36px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}

.banner__text__sectionDesktop {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-right: 5%;
  max-width: 52%;
}

.banner__text__sectionTablet {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 52%;
  align-items: baseline;
}

.banner__text__sectionMobile {
  display: flex;
  flex-direction: column;
  padding: 19px;
  align-items: baseline;
}

.slick-list {
  /* height: 70vh; */
  height: 100%;
  display: flex !important;
  flex-flow: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 34px !important;
}

.banner__imageDesktop {
  margin-right: 41px;
  width: 370px;
  height: auto;
  margin-top: 5%;
  /* mix-blend-mode: multiply; */
}

.banner__imageTablet {
  margin-right: 41px;
  width: 300px;
  height: auto;
}

.banner__imageMobile {
  width: 200px;
  height: auto;
}

.banner__buttonDesktop {
  margin-top: 15px;
  color: white;
  font-size: 13px;
  font-weight: 700;
  background-color: #ce0e2d;
  letter-spacing: 1px;
  padding: 14px 20px;
  border: none;
  cursor: pointer;
}

.banner__buttonDesktop:hover {
  transition-duration: 500ms;
  background-color: #900a1f !important;
}

.banner__buttonTablet {
  margin-top: 15px;
  color: white;
  font-size: 13px;
  font-weight: 700;
  background-color: #ce0e2d;
  letter-spacing: 1px;
  padding: 14px 20px;
  border: none;
}

.banner__buttonMobile {
  margin-top: 19px;
  color: white;
  letter-spacing: 0.8px;
  background-color: #ce0e2d;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 12px 20px;
}

.slick-slider {
  height: 500px;
}
.slick-next,
.slick-prev {
  top: 0 !important;
  bottom: 0;
  margin: auto;
  width: 30px !important;
  height: 30px !important;
  /* border: 1px solid red !important; */
}

.slick-prev {
  left: 5 !important;
}
.slick-next {
  right: 5 !important;
}

.slick-prev:after,
.slick-next:after {
  /* border:1px solid pink; */
  top: 0 !important;
  position: absolute !important;
  /* width:20px !important;
    height:20px !important; */
  background-position: center !important;
}
.bannerImageDiv{
    margin-top: 3%;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .bannerMain {
    justify-content: flex-start !important;
  }

  .bannerImageDiv {
    width: 100%;
    flex: 100%;
    max-width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  .slick-slider {
    min-height: 500px;
    height: auto;
  }

  .banner {
    /* margin-top: 20%; */
    position: static;
    /* height: 100vh !important; */
    flex-direction: column;
    min-height: 600px;
    padding-top: 25%;
    display: block !important;
    text-align: center;
  }
  .slick-next {
    /* top: 163px !important; */
    /* right: 10px !important; */
  }
  .slick-prev {
    /* top: 153px !important; */
    /* left: 4px !important; */
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .banner__imageTablet {
    margin-right: 9px;
    width: 236px;
    height: auto;
  }

  .bannerMain {
    justify-content: flex-start !important;
  }

  .slick-list {
    /* height: 42vh; */
  }

  .slick-dots {
    bottom: 16px !important;
  }

  .banner {
    /* margin-bottom: 29%; */
  }

  .banner__text__sectionTablet {
    max-width: 48%;
  }

  .slick-slider {
    height: 424px;
  }
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.slick-prev:after,
.slick-next:after {
  content: "";
  color: #fff;
  width: 32px;
  height: 32px;
  display: block;
  font-size: 20px;
}

.slick-prev:after {
  background: url(../Images/prev.svg);
}

.slick-next:after {
  background-repeat: no-repeat !important;
  background: url(../Images/next.svg);
}

.slick-dots li button:before {
  color: white;
}

li.slick-active button:before {
  font-size: 10px !important;
}

.slick-dots li button {
}

.slick-dots li button:before {
  color: white !important;
}
