.notice__text__section {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 35px 40px 35px 0px;
    flex: 1;
    text-align: initial;
}

.notice__text__sectionMobile {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 7px;
    text-align: initial;
}

.notice__heading {
    color: #FFF;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
}

.notice__image {
    color: white;
    font-size: '84px' !important;
    margin-left: '49px' !important;
}

.notice__imageMobile {
    color: white;
    font-size: '47px';
    margin-left: '49px';
}

.notice__heading__txt {
    font-weight: 600;
    font-size: 12px;
}

.notice__heading__txtMobile {
    font-size: 12px;
}

.notice__headingMobile {
    font-size: 13px;
}

.notice {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CE0E2D;
}

.noticeMobile {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    background-color: #CE0E2D;
}

.notice__image {
    display: flex;
    /* width: 1440px;
    height: 120px; */
    padding: 10px 40px;
    align-items: center;
    gap: 40px;
    flex-shrink: 0;
}