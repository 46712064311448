.orderTitile {
  color: #000;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  border-bottom: 2px solid black;
  display: flex;
  width: 100%;
  padding: 11px 0px;
  align-items: center;
  /* margin-top: 20px; */
  /* gap: 8px; */
}
.bagroundgrey {
  background-color: #eaeaea !important;
}
.orderSummary {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  /* margin-top: 15px; */
  /* gap: 16px; */
}
.orderDetailCoupan {
  background: #eaeaea !important;
}

.orderPrices,
.orderTotal,
.orderCoupan {
  padding: 16px 0;
  width: 100%;
}
.orderTotal {
  border-bottom: 2px solid black;
  border-top: 1px solid rgba(97, 94, 92, 0.15);
}
.coupanAccordian {
  display: flex;
  padding: 0px 0px 16px 0px !important;
  /* done for remove after space issue */
}

.borderColor {
  border: 1px solid #e8e7e7 !important;
  padding: 10px;
}

.orderSub {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: #07080c;
  /* Text md/Medium */
  margin-top: 4px;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.orderSubRed {
  color: #ce0e2d;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.applyCoupan {
  border: 1px solid var(--brand-colors-black, #07080c);
  background: var(--white, #fff);
  display: flex;
  padding: 12px 30px;
  color: var(--brand-colors-black, #07080c);
  text-align: center;
  cursor: pointer;
  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  /* 138.462% */
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.applyCoupan:hover {
  color: white;
  background: CE0E2D;
  transition-duration: 500ms;
  background: #900a1f;
}
.accordianText {
  color: #07080c;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  padding: 0px !important;
  font-weight: 700;
  width: 99%;
  /* 138.462% */
}
.payemntDept{
  display: flex;
  flex-direction: column;
  align-items: baseline !important;
  gap: 0px !important;
}
.accordianText  svg{
  fill: var(--ilmorRed);
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px !important;
}

.orderSubTitle {
  color: #07080c;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
}

.orderSubPrice {
  color: #07080c;
  text-align: right;
  /* Text xl/Bold */
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
}

.accordianOrderCoupan {
  width: 100%;
  box-shadow: none !important;
}

.orderCoupan {
  width: 100%;
  border-bottom: 2px solid black;
  display: flex;
  padding: 0px 0px 3px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* gap: 16px; */
  align-self: stretch;
}

.ilmorButon {
  cursor: pointer;
}
.ilmorButon.setPassword{
  margin-top: 15px;
}
.topMargin {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.paymentbutton {
  width: 100%;
}

.paymentbutton form button {
  border: none;
  width: 100%;
}

.orderSubTitleDiv {
  display: flex;
  gap: 6px;
  flex-direction: column;
}

.removeOrderPage {
  color: var(--brand-colors-ilmor-red, #ce0e2d);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  /* 133.333% */
  text-decoration-line: underline;
}

.orderDetailCoupanDetail {
  display: flex;
  padding: 10px 16px;
  background-color: white;
}

.notWidth {
  min-width: unset !important;
  width: 100%;
}

.shipMethodSel {
  margin-top: 8px;
}
.coupad{
  padding-bottom: 13px;
}