/* =================>Desktop CSS<================================*/

.updateloginPage__mainDesktop {
  display: flex;
  font-family: Montserrat;
  /* height: 100vh; */
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.updateloginContainerDesktop {
  padding: 2%;
  width: 34vw;
}
.updatelogoDesktop {
  background-color: white;
  width: 100%;
  padding: 20px;
  height: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
}
.updatetitle-imageDesktop {
  height: auto;
  width: 30%;
  margin-top: 6%;
}
.updateinfo__mainDesktop {
  background-color: rgb(247, 247, 247);
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.updateheadingBoxDesktop {
  border: 1px solid white;
  border-bottom-color: rgb(247, 247, 247);
}
.updateheadingDesktop {
  margin-top: 10%;
  letter-spacing: 2px;
  font-size: 200%;
}
.updateformDesktop {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -30px;
}
.updateemailLabelDesktop {
  margin-left: 9%;

  width: 20%;
}

.updatelabelDesktop {
  margin-bottom: 0%;
  letter-spacing: 2px;
  font-size: 80%;
  min-width: 226px;
  margin-bottom: 8px;
}
.usernameWarningContainerDesktop {
  color: red;
  display: flex;
  flex-wrap: wrap;
}
.updatewarningDesktop {
  display: flex;
  flex-wrap: wrap;
  font-size: 80%;
  margin-left: 45%;
}
.updatepasswordLabelDesktop {
  margin-left: 12%;

  width: 20%;
}
.updatepasswordWarningDesktop {
  margin-top: 2%;
  color: red;
}
.updateusernameInputDesktop {
  width: 70%;
  height: 60px;
  margin-left: 15%;
  padding: 0px 15px;
}

.updatecheckBoxDesktop {
  display: flex;

  margin-left: 14%;
}
.updaterememberMeDesktop {
  margin-top: 2%;
  margin-left: 2%;
  letter-spacing: 0.5;
}

.updatebtnFrgtPwDesktop {
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
  height: 60%;
  margin-top: 3%;
}
.updatesignInBtnDesktop {
  height: 50px;
  padding: 2%;
  font-size: 100%;
  margin-left: 15%;
  background-color: rgb(231, 81, 85);
  color: rgb(247, 247, 247);
  letter-spacing: 1px;
  border: none;
}
.updateforgotPasswordLabelDesktop {
  margin-left: 35%;
}
.updateforgetPwDesktop {
  text-decoration: none;
  color: black;
}

.updatelineBreak__mainDesktop {
  width: 70%;
}
.updatefooterInfo_Desktop {
  padding-bottom: 40px;
}
.purchase_line{
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  opacity: 0.3;
  background: rgba(97, 94, 92, 0.15);
  color:#615E5C26
}

/*===========> Mobile Css===================*/

.updateloginPage__mainMobile {
  display: flex;
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
  font-family: Montserrat;
  align-items: center;
  justify-content: center;
}
.updateloginContainerMobile {
  padding: 20%;
  border-radius: 5%;
}
.updatelogoMobile {
  background-color: white;
  width: 115%;
  padding: 25px;
  height: auto;
  border-radius: 10px;
  margin-left: -18%;
}
.updatetitle-imageMobile {
  height: 35%;
  width: 35%;
  margin-top: 6%;
}
.updateinfo__mainMobile {
  background-color: rgb(247, 247, 247);
  width: 140%;
  height: auto;
  margin-top: -5%;
  margin-left: -18%;
  border-radius: 10px;
}
.updateheadingBoxMobile {
  border: 1px solid white;
  border-bottom-color: rgb(247, 247, 247);
}
.updateheadingMobile {
  margin-top: 10%;
  letter-spacing: 2px;
  font-size: 120%;
}
.updateformMobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.updateemailLabelMobile {
  margin-left: 4%;
  margin-top: -10%;

  width: 20%;
}
.updatelabelMobile {
  margin-bottom: 0%;
}
.updateusernameWarningContainerMobile {
  color: red;
  display: flex;
  flex-wrap: wrap;
}
.updatewarningMobile {
  display: flex;
  flex-wrap: wrap;
  font-size: 80%;
  margin-left: 25%;
}
.updateemailMobile {
}
.updatepasswordLabelMobile {
  margin-left: 6%;

  width: 20%;
}
.updateusernameInputMobile {
  width: 85%;
  padding: 0px 15px;
  height: 42px;
  margin-left: 6%;
}

.updatecheckBoxMobile {
  display: flex;

  margin-left: 5%;
  margin-top: 1%;
}
.updaterememberMeMobile {
}

.updatebtnFrgtPwMobile {
  display: flex;
  flex-direction: row;

  height: 60%;
  margin-top: 8%;
}
.updatesignInBtnMobile {
  height: 40px;

  margin-left: 5%;
  padding: 2%;
  font-size: 100%;
  margin-left: 6%;
  background-color: rgb(231, 81, 85);
  color: rgb(247, 247, 247);
  letter-spacing: 1px;
  border: none;
}

.updateforgotPasswordLabelMobile {
  margin-left: 20%;
  margin-top: -2%;
}
.updateforgetPwMobile {
  text-decoration: none;
  color: black;
}

.updatelineBreak__mainMobile {
  width: 70%;
}
.updatefooterInfo_Mobile {
  width: 90%;
}
.updateinfoMobile {
  font-size: 100%;
  color: black;
  margin-left: 15px;
  padding-bottom: 40px;
}

/*=============> TABLET CSS <=======================*/

.updateloginPage__mainTablet {
  display: flex;
  background-image: linear-gradient(
    to right,
    rgba(16, 23, 39, 1) 0%,
    22.77657240629196%,
    rgba(0, 57, 77, 1) 45.55314481258392%,
    72.77657240629196%,
    rgba(0, 79, 104, 1) 100%
  );
  font-family: Montserrat;
  height: 100vh;
}
.updateloginContainerTablet {
  /* border: 1px solid green; */
  margin-top: -8%;
  margin-left: 10%;
  padding: 20%;
  border-radius: 5%;
}
.updatelogoTablet {
  /* border: 1px solid red; */
  background-color: white;
  width: 150%;
  height: 20%;
  border-radius: 15px;
  margin-left: -28%;
}
.updatetitle-imageTablet {
  height: 35%;
  width: 35%;
  margin-top: 6%;
  /* border: 1px solid pink; */
}
.updateinfo__mainTablet {
  /* border: 1px solid black; */
  background-color: rgb(247, 247, 247);
  width: 150%;
  height: 90%;
  margin-top: -5%;
  border-radius: 15px;
  margin-left: -28%;
}
.updateheadingBoxTablet {
  border: 1px solid white;
  border-bottom-color: rgb(247, 247, 247);
  /* height: 28%; */
}
.updateheadingTablet {
  /* border: 1px solid sienna; */
  margin-top: 10%;
  letter-spacing: 2px;
  font-size: 200%;
}
.updateformTablet {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* margin-top: -15%; */
}
.updateemailLabelTablet {
  margin-left: 9%;
  /* margin-top: 1%; */
  width: 20%;
  /* border: 1px solid brown; */
}
.updateemailTablet {
  /* margin-top: 5%; */
}
.updatepasswordLabelTablet {
  margin-left: 13%;
  /* margin-top: 1%; */
  width: 20%;
  /* border: 1px solid brown; */
}
.updateusernameInputTablet {
  width: 70%;
  height: 5vh;
  margin-left: 15%;
  padding: 0px 15px;
}

.updatecheckBoxTablet {
  display: flex;

  margin-left: 13%;
  margin-top: 1%;
}
.updaterememberMeTablet {
  margin-top: 2%;
}

.updatebtnFrgtPwTablet {
  display: flex;
  flex-direction: row;

  /* border: 1px solid goldenrod; */
  height: 60%;
  margin-top: 3%;
}
.updatesignInBtnTablet {
  width: 20%;
  height: 80%;
  padding: 1.5%;
  font-size: 130%;
  margin-left: 15%;
  background-color: rgb(231, 81, 85);
  color: white;
  letter-spacing: 1px;
}
.updateforgotPasswordLabelTablet {
  margin-left: 30%;
}
.updateforgetPwTablet {
  text-decoration: none;
  /* color: black */
}

.updatelineBreak__mainTablet {
  width: 70%;
}
