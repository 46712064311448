.cancelButonAddShipping {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #07080c;
  font-size: 13px;
  font-weight: 700;
}
.cancelButonAddShipping:hover {
  background: CE0E2D;
  transition-duration: 500ms;

  color: #900a1f;
}
.shippingForm {
  /* display: flex;
    flex-direction: column; */
  width: 100%;
}
.InputFieldShippingAddress {
  /* width: 600px; */
  height: 42px;
  border: none;
  /* margin-top: .5%; */
  font-size: 15px;
  padding: 0px 16px;
  /* border: none; */
}
input:disabled {
  background-color: white;
  /* width: 600px; */
}
.labelDesktopAddShipping {
  margin-bottom: 5px;
  letter-spacing: 2px;
  font-size: 80%;
  display: flex;
  text-transform: uppercase !important;
  color: var(--Brand-Colors-Black, #07080c);
  font-feature-settings: "clig" off, "liga" off;

  /* forms/label/Bold */
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
  .labelDesktopAddShipping {
    font-size: 10px !important;
  }
  .labelDesktopAddShippingRequired {
    font-size: 10px !important;
  }
}
.labelCardHolderStatus {
  font-size: 10px;
  margin-top: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.labelDesktopAddShippingRequired {
  margin-bottom: 5px;
  letter-spacing: 2px;
  font-size: 10px;
  display: flex;
  text-transform: uppercase !important;
}

.labelDesktopAddShippingRequired::after {
  content: "*";
  color: red;
  margin-left: 2.1px;
}

.cardExpiry {
  display: flex;
  gap: 8px;
  align-items: center;
}

.cardStatus {
  display: flex;
  gap: 0px;
  align-items: center;
}

.shippingForm input[type="text"] {
  width: 100%;
}
.underline {
  text-decoration-line: underline;
}
