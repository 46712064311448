.ShippingPaymentComponentTop {
  display: flex;
  justify-content: space-between;
}

.ShippingPaymentComponent {
}

.orderDetailAccordian {
  background: #eaeaea !important;
  border-bottom: 2px solid black;
  padding: 6px 0px 6px 8px;
  border-top: 2px solid black;
}

.modifytext {
  color: var(--brand-colors-black, #07080c);

  /* Wf.Text md/Regular */
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 213% ; /* 150% */
  padding: 40px 0px;
}



.orderDetailTitle{
  margin-bottom: 24px;
  padding-top: 24px;
}