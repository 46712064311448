.PasswordRecovery__mainDesktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient( to right, rgba(16, 23, 39, 1) 0%, 22.77657240629196%, rgba(0, 57, 77, 1) 45.55314481258392%, 72.77657240629196%, rgba(0, 79, 104, 1) 100% );
  height: 100vh;
}
.PasswordRecovery_ContainerDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
}
.PasswordRecovery_logoDesktop {
  background-color: white;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100px;

  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.PasswordRecovery_title-imageDesktop {
  height: auto;
 
}
.PasswordRecovery_info__mainDesktop {

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
    align-items: center;
    width: 550px;
}
.PasswordRecovery_headingBoxDesktop {
  display: flex;
  padding: 25px 25px;
  flex-direction: column;
  align-items: center;
  /* gap: 25px; */
}
.PasswordRecovery_headingDesktop {

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
  margin-top: 20px;
  margin-bottom: 0px;
}
.PasswordRecovery_MsgDesktop {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  width: 415px;
}
.loginContactUs{
  text-decoration: underline;
  cursor: pointer;
  color: #000;
}
.pwRecoverymsg1{
  padding: 0px 0px;
}
.PasswordRecovery_lineBreak__mainDesktop {
  width: 374px;
  height: 0px;
  border: 1px solid rgba(97, 94, 92, 0.15);
}
.PasswordRecoveryBtnBoxDesktop {

}
.PasswordRecoveryBtnBoxDesktop:hover{
  background-color: darkred;
  transition: 0.5s;
}
.PasswordRecovery_BtnDesktop {
  display: flex;
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  background: #CE0E2D;
  /* width: 90%; */
  border: none;
  padding: 12px 12px;
  /* margin-bottom: 20px; */
}
.PasswordRecovery_BtnDesktop:hover{
  background-color: darkred;
}

/* .PasswordRecoveryLinkDesktop{
  text-decoration: none;
    color: rgb(247, 247, 247);
} */

.pwRecoveryFooter{
  display: flex;
  margin: 20px 20px 40px 20px;
}

.PasswordRecoveryLinkDesktop{
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
/*================================> Mobile Css<========================================*/

.PasswordRecovery__mainMobile {
 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient( to right, rgba(16, 23, 39, 1) 0%, 22.77657240629196%, rgba(0, 57, 77, 1) 45.55314481258392%, 72.77657240629196%, rgba(0, 79, 104, 1) 100% );
  height: 100vh;
  display: flex;
}
.PasswordRecovery_ContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 550px; */
}
.PasswordRecovery_logoMobile {
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100px;

  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.PasswordRecovery_title-imageMobile {
  height: auto;
}
.PasswordRecovery_info__mainMobile {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
    align-items: center;
    width: 350px;
}
.PasswordRecovery_headingBoxMobile {
  display: flex;
  padding: 25px 25px;
  flex-direction: column;
  align-items: center;
}
.PasswordRecovery_headingMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
}
.PasswordRecovery_MsgMobile {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  margin-top: -21px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* width: 340px;
    text-align: left; */
}
.PasswordRecovery_lineBreak__mainMobile {
  width: 300px;
  height: 0px;
  border: 1px solid rgba(97, 94, 92, 0.15);

}
.PasswordRecoveryBtnBoxMobile {
 
}
.PasswordRecoveryBtnBoxMobile:hover{
  background-color: darkred;
  transition: 0.5s;
}
.PasswordRecovery_BtnMobile {
  display: flex;
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  background: #CE0E2D;
  /* width: 90%; */
  border: none;
  padding: 12px 12px;
}
.PasswordRecoveryLinkMobile{
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

/*=========================> Tablet Css <========================================*/

.PasswordRecovery__mainTablet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient( to right, rgba(16, 23, 39, 1) 0%, 22.77657240629196%, rgba(0, 57, 77, 1) 45.55314481258392%, 72.77657240629196%, rgba(0, 79, 104, 1) 100% );
  height: 100vh;
}
.PasswordRecovery_ContainerTablet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;}
.PasswordRecovery_logoTablet {
  background-color: white;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100px;

  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.PasswordRecovery_title-imageTablet {
  height: auto;

}
.PasswordRecovery_info__mainTablet {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
    align-items: center;
    width: 550px;
}
.PasswordRecovery_headingBoxTablet {
  display: flex;
  padding: 25px 25px;
  flex-direction: column;
  align-items: center;
}
.PasswordRecovery_headingTablet {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #07080c;
}
.PasswordRecovery_MsgTablet {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  width: 415px;
}
.PasswordRecovery_lineBreak__mainTablet {
  width: 374px;
  height: 0px;
  border: 1px solid rgba(97, 94, 92, 0.15);

}
.PasswordRecoveryBtnBoxTablet {
  margin: 18px 0px 27px 0px;

}
.PasswordRecoveryBtnBoxTablet:hover{
  background-color: darkred;
  transition: 0.5s;
}
.PasswordRecovery_BtnTablet {
  display: flex;
  /* flex-direction: column; */
  /* flex-wrap: wrap; */
  background: #CE0E2D;
  /* width: 90%; */
  border: none;
  padding: 12px 12px;
}
.PasswordRecoveryLinkTablet{
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

