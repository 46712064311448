body{
    background-image: none;
}
.OutContainer{
    display: flex;
    justify-content: center;
    background: gray;
}
.EmpAcc_out {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width:100%
    /* min-width: 53%; */
}
.EmpAcc_card_container{
    border: white solid 32px ;
    padding: 5px;
    text-align: left;
    width: 316px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.EmpAcc_outTable {
    margin-top: 3px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #07080C;
    padding-left: 30px;
    word-break: break-word;
    width: -webkit-fill-available;
}
.EmpAcc_TableHeading {
    text-align: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: left;
    padding: 16px 8px 16px 7px;
    /* padding: 20px 0 0 40px; */
}

/* .EmpAcc_TableHeading.addDate{
    padding-left: 35px;
}
.EmpAcc_TableHeading.Role{
    padding-left: 55px;
} */
.EmpAcc_TableData {
    /* padding: 16px 8px 16px 25px; */
    color:  #07080C;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* padding-left: 20px; */
}
.EmpAcc_TableDataEvenRow {
    background-color: #F7F7F7;
    line-height: 25px;
}
.RowEdit {
    background-color: var(--success, #40A900);;
    padding: 10px;
    border-radius: 10px;
}
@media screen and (max-width:767px) {
    .AddDateRole{
        display:flex;
        align-items: center;
    }
    .AddDateRole > *{
        margin:5px 10px 5px 0;

    }
    .AddDateRole h5{
        margin:0;
        padding: 15px 0;
    }
    .EmpAcc_card_container h2{
        font-weight: 700;
    }
    .EmpAcc_TableData{
        padding: 5px;
        margin: 0;
    }
}
.RowEdit {
    background-color: #40A900;
    padding: 3px 20px;
    border-radius: 10px;
}
.EmpAcc_button{
    margin: 20px;
}
.editBtn{
    text-decoration: underline;
    border: none;
    color: red;
}

@media screen and (max-width:600px) {
    .employeeData {
        max-width: 100% !important;
        flex-basis: 100% !important
    }
    }