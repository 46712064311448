.rightSideOrderDetail {
  color: var(--brand-colors-black, #07080c);
  text-align: center;

  /* Text sm/Bold */
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.padLeft {
  padding-left: 40px;
  cursor: pointer;
}
.shopEngineTitle {
  /* padding-left: 72px; */
}

.orderDetailLabel {
  padding-left: 8px;
}
.paymentMethodCards {
  flex-direction: column;
}
.ilmorButonOnly {
  color: white;
  font-size: 13px;
  background-color: #ce0e2d;
  letter-spacing: 1px;
  /* padding: 12px 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
  /* Text sm/Bold */
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
}

.ilmorButonOnly:hover {
  /* transition-duration: 500ms !important; */
  background: #ce0e2d;
  transition-duration: 500ms;
  background: #900a1f;
}

.orderDetail {
  border-bottom: 2px solid black;
  display: flex;

  background: #eaeaea;
  padding: 20px 60px;

  width: 100%;
}
.orderDetailNotification {
  padding: 20px 60px;
}
.ilmorStatusRed {
  color: var(--white, #fff);
  text-align: center;
  /* Display xs/Bold */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--brand-colors-ilmor-red, #ce0e2d);
}

.textOrderDetail {
  color: #07080c;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.shoppingListOrderDetail {
  padding: 33px 40px 60px 40px;
  background: var(--light-gray-2, #eaeaea);
}

.justifyGrid {
  justify-content: flex-end;
}

@media screen and (min-width: 391px) and (max-width: 768px) {
  .justifyGrid {
    justify-content: unset;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .ordeTabDetail {
    display: flex;
    flex-direction: column;
  }
  .headingCssOrder {
    font-size: 22px !important;
  }
  .sdfdsr44e{
    padding-left: 0px !important;
  }
  .orderhispagpad {
    padding: 15px 16px 22px 10px !important;
  }
  .orderDetail {
    padding: 10px 20px;
  }
  .orderDetailNotification {
    padding: 14px 24px !important;
  }
  .textOrderDetail {
    padding: 0px 28px 0px 0px;
  }
  .justifyGrid {
    justify-content: unset;
  }
  .shoppingListOrderDetail {
    padding: 0px 20px 0px 20px;
  }
  .orderDetailLabel {
    padding: 12px 4px;
  }
  /* .paymentMethodCards {
    flex-direction: unset;
  } */
}
