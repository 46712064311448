.maodalTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.messageModal {
  color: var(--brand-colors-black, #07080c);
  text-align: center;

  /* Display md/Bold */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  text-transform: uppercase;
}
.modalBut{
	display: flex;
	margin-top: 40px;
	justify-content: space-around;
}

@media screen and (min-width:0px) and (max-width:600px) {
	.css-1i1ibm6 {
		width: 355px !important;
	}
}